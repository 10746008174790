import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import {
    getListRequestsReguest,
    getItemRequestsReguest,
    updateItemRequestsReguest,
    addItemRequestsReguest
} from './api'

import {
    getListRequests,
    getListRequestsSuccess,
    getListRequestsFailure,
    getListRequestsIdle,
    getItemRequests,
    getItemRequestsSuccess,
    getItemRequestsFailure,
    getItemRequestsIdle,
    addItemRequests,
    addItemRequestsSuccess,
    addItemRequestsFailure,
    addItemRequestsIdle,
    updateItemRequests,
    updateItemRequestsSuccess,
    updateItemRequestsFailure,
    updateItemRequestsIdle,
    
} from './actions'

function* getListRequestsSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(getListRequestsReguest, payload);

        yield put(getListRequestsSuccess(response));

    } catch (error) {

        yield put(getListRequestsFailure(error));

    }
}

function* getItemRequestsSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(getItemRequestsReguest, payload);

        yield put(getItemRequestsSuccess(response));

    } catch (error) {

        yield put(getItemRequestsFailure(error));

    }
}

function* updateItemRequestsSaga(action) {
    try {

        const { payload } = action;

        let response;
        if(payload.updatesItems){
            response = yield all(payload.updatesItems.map(item => call(updateItemRequestsReguest, { props: item })))
        } else {
            response = yield call(updateItemRequestsReguest, payload);            
        }

        yield put(updateItemRequestsSuccess(response));

    } catch (error) {

        yield put(updateItemRequestsFailure(error));

    }
}

function* addItemRequestsSaga(action) {
    try {

        const { payload } = action;

        let response = yield call(addItemRequestsReguest, payload);            

        yield put(addItemRequestsSuccess(response));

    } catch (error) {

        yield put(addItemRequestsFailure(error));

    }
}

export function* requestsWatcher() {
    yield takeLatest(getListRequests.toString(), getListRequestsSaga);
    yield takeLatest(getItemRequests.toString(), getItemRequestsSaga);
    yield takeLatest(updateItemRequests.toString(), updateItemRequestsSaga);
    yield takeLatest(addItemRequests.toString(), addItemRequestsSaga);
}