import { fork } from "redux-saga/effects";
import { handleAuthAppWatcher } from "./modules/authApp/saga";
import { getInitDataWatcher } from './modules/initData/saga';
import { bookingWatcher } from "./modules/booking/saga";
import { codWatcher } from "./modules/cod/saga";
import { poWatcher } from "./modules/po/saga";
import { exportFormWatcher } from './modules/export/saga';
import { handleFixDateWatcher } from './modules/fixDate/saga';
import { chatWatcher } from "./modules/chat/saga";
import { requestsWatcher } from "./modules/requests/saga";

export default function* rootSaga() {
  yield fork(handleAuthAppWatcher);
  yield fork(bookingWatcher);
  yield fork(codWatcher);
  yield fork(poWatcher);
  yield fork(getInitDataWatcher);
  yield fork(exportFormWatcher);
  yield fork(handleFixDateWatcher);
  yield fork(chatWatcher);
  yield fork(requestsWatcher);
}
