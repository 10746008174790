import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getListCod,
    getListCodSuccess,
    getListCodFailure,
    getListCodIdle,
    getListCodClear,
    searchFilter,
    searchFilterSuccess,
    searchFilterFailure,
    searchFilterIdle,
    addCodInfo,
    addCodInfoSuccess,
    addCodInfoFailure,
    addCodInfoIdle,
    addLog,
    addLogSuccess,
    addLogFailure,
    addLogIdle,
    addLogDataSuccess
} from './actions'

const addLogStatus = handleActions(
    {
        [addLogSuccess]: () => "SUCCESS",
        [addLog]: () => "LOADING",
        [addLogFailure]: () => "FAILURE",
        [addLogIdle]: () => "IDLE",
    },
    'IDLE'
);

const addCodInfoStatus = handleActions(
    {
        [addCodInfoSuccess]: () => "SUCCESS",
        [addCodInfo]: () => "LOADING",
        [addCodInfoFailure]: () => "FAILURE",
        [addCodInfoIdle]: () => "IDLE",
    },
    'IDLE'
);

const status = handleActions(
    {
        [getListCodSuccess]: () => "SUCCESS",
        [getListCod]: () => "LOADING",
        [getListCodFailure]: () => "FAILURE",
        [getListCodIdle]: () => "IDLE",
    },
    'IDLE'
);

const count = handleActions(
    {
        [getListCodSuccess]: (state, action) => action.payload.count,
        [getListCodFailure]: (state, action) => 0,
        [getListCodClear]: () => 0
    },
    0
);

const data = handleActions(
    {
        [getListCodSuccess]: (state, action) => {
            return [
                ...state,
                ...action.payload.result
            ]
        },
        [addLogDataSuccess]: (state, action) => {
            const newList = [];


            if (state && state.length > 0) {
                state.forEach(item => {
                    if (item.bxDeliveryId === action.payload.bxID) {
                        let newItem = { ...item, ...action.payload.props };
                        newList.push(newItem);
                    } else {
                        newList.push(item);
                    }
                });
            }

            return newList;
        },
        [getListCodFailure]: (state, action) => {
            return []
        },
        [getListCodClear]: () => []
    },
    []
);

const searchFilterStatus = handleActions(
    {
        [searchFilterSuccess]: () => "SUCCESS",
        [searchFilter]: () => "LOADING",
        [searchFilterFailure]: () => "FAILURE",
        [searchFilterIdle]: () => "IDLE",
    },
    'IDLE'
);

const searchFilterData = handleActions(
    {
        [searchFilterSuccess]: (state, action) => action.payload,
        [searchFilterFailure]: (state, action) => action.payload,
    },
    []
);

const cod = combineReducers({
    status,
    data,
    count,
    searchFilterStatus,
    searchFilterData,
    addCodInfoStatus,
    addLogStatus
})

export { cod };