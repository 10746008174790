import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { uploadFilesAllSuccess, uploadFiles, addLinksFiles, addLinksFilesRequest } from "../../store/ducks/filesUpload/actions";
// import { addMessageIdle } from "../../store/ducks/chat/actions";
import Tooltip from "rc-tooltip";
import 'rc-tooltip/assets/bootstrap_white.css';
import iconFile from '../../../assets/img/icons/file.svg';
import iconMark from '../../../assets/img/icons/mark.svg';

const rus_to_latin = (str) => {
    const ru = {
        'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
        'е': 'e', 'ё': 'e', 'ж': 'j', 'з': 'z', 'и': 'i',
        'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n', 'о': 'o',
        'п': 'p', 'р': 'r', 'с': 's', 'т': 't', 'у': 'u',
        'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch', 'ш': 'sh',
        'щ': 'shch', 'ы': 'y', 'э': 'e', 'ю': 'u', 'я': 'ya'
    }, n_str = [];

    str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

    for ( let i = 0; i < str.length; ++i ) {
       n_str.push(
              ru[ str[i] ]
           || ru[ str[i].toLowerCase() ] == undefined && str[i]
           || ru[ str[i].toLowerCase() ].replace(/^(.)/, function ( match ) { return match.toUpperCase() })
       );
    }
    return n_str.join('').replace(/\W/g, '_');
}

const FilesUpload = ({ path, multi, request, id, name }) => {

    const [fileList, setFileList] = React.useState([]);
    const [otherFiles, setOtherFiles] = React.useState([]);
    const [sendFiles, setSendFiles] = React.useState([]);
    // const files = useSelector((state) => state.files.items);
    // const statusAddMessage = useSelector((state) => state.messages.statusAddMessage);
    const files = [];
    const statusAddMessage = "IDLE"
    const dispatch = useDispatch();

    const renderFiles = () => {
        let tempFiles = [];
        fileList.map(file => {
            tempFiles = [...tempFiles, file.name];
        });
        setOtherFiles(tempFiles);
    };

    React.useEffect(() => {
        if (statusAddMessage === 'SUCCESS') {
            setOtherFiles([]);
            setSendFiles([]);
            setFileList([]);
            // dispatch(addMessageIdle());
        }
    }, [statusAddMessage])

    React.useEffect(() => {
        if (Object.keys(files).length > 0) {
          let check = true;
          Object.keys(files).map(key => {
            const file = files[key];
            const status = file.status;
            if (status !== "SUCCESS") check = false;
          });
          if (check) {
            // dispatch(uploadFilesAllSuccess());
          }
        }
    }, [files]);

    React.useEffect(() => {
        let tempFiles = [];
        if (fileList.length > 0) {
          fileList.map(file => {
            if (Array.isArray(path)) {
                path.map(item => {
                    const urlImg = `/${item}/${file.fullName}`;
                    tempFiles = [...tempFiles, urlImg];
                })
            } else {
                const urlImg = `/${path}/${file.fullName}`;
                tempFiles = [...tempFiles, urlImg];
            }
          });
          setSendFiles(tempFiles);
        } else {
          setSendFiles([]);
        }
        renderFiles();
    }, [fileList]);

    const removeFile = (fileName) => {
        const newFileList = fileList.filter(file => file.name !== fileName);
        setFileList(newFileList);
    };

    const handleChange = (e) => {
        e.preventDefault();
        let tempFiles = [];
        Array.prototype.forEach.call(e.target.files, function(file) {
            file.newname = file.name
            .substring(0, file.name.lastIndexOf("."))
            .toLowerCase();
            if (request) {
                file.newname = rus_to_latin(`${file.newname}-request-id-${id}-${name}`);
            } else {
                file.newname = rus_to_latin(`${file.newname}-other-file`);
            }
            file.extension = file.name
            .substring(file.name.lastIndexOf("."))
            .toLowerCase();
            file.fullName = file.newname + file.extension;
            tempFiles = [...tempFiles, file];
            if (Array.isArray(path)) {
                for (let i = 0; i < path.length; i++) {
                    // dispatch(uploadFiles({ file, path: path[i] }));
                }
            } else {
                // dispatch(uploadFiles({ file, path }));
            }
        });
        setFileList(tempFiles);
    };

    React.useEffect(() => {
        if (request) {
            // dispatch(addLinksFilesRequest({ sendFiles: sendFiles, name: name }));
        } else {
            // dispatch(addLinksFiles(sendFiles));
        }
    }, [sendFiles])

    return <>
        {request && (
            <>
            {otherFiles.length == 0 && (
                <>
                <button style={{background: `url("${iconFile}") top/cover no-repeat`}} className="application-table__file"></button>
                <input
                style={{ opacity: 0 }}
                className="form__input-file"
                onChange={handleChange}
                multiple={multi}
                type="file"
                />
                </>
            )}
            </>
        ) || (
            <>
            <span className="form__icon-file"><img src={`${iconFile}`} alt="icon_file" /></span>
            <input
            style={{ opacity: 0 }}
            className="form__input-file"
            onChange={handleChange}
            multiple={multi}
            type="file"
            />
            </>
        )}
        
        {!request && (
        <>
        {otherFiles.length > 0 && (
        <div className="files-list">
            {otherFiles.map(file => (
                <div className="file-item" key={file}>
                    {files[file].status === "SUCCESS" && (
                        <div className="file-wrapper">
                            <div className="file-name">{file}</div>
                            <div className="remove-file">
                                <span style={{cursor: 'pointer'}} onClick={(e) => removeFile(file)}>&#10005;</span>
                            </div>
                        </div>
                    )}
                </div>
            ))}
        </div>
        )}
        </>
        ) || (
            <>
            {otherFiles.length > 0 && (
            <div className="files-list">
                {otherFiles.map(file => (
                    <div className="file-item" key={file}>
                        {files[file].status === "SUCCESS" && (
                            <Tooltip placement="top" trigger={['hover']} overlay={
                            <>
                            <span>{file}</span>&nbsp;
                            <span style={{cursor: 'pointer'}} onClick={(e) => removeFile(file)}>&#10005;</span>
                            </>
                            }>
                                
                                <button style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></button>
                            </Tooltip>
                        )}
                    </div>
                ))}
            </div>
            )}
            </>
        )}
    </>;
};

export default FilesUpload;