import { createSelector } from "reselect";

import dayjs from 'dayjs';

import { getValueProperty } from "../../constants/config";
const getState = (state) => state.cod.data;

const initData = (state) => state.initData;

export const codSelector = createSelector([getState, initData], (items, initData) => {
  let retData = [];
  if(items && items.length > 0 && initData){
    retData = items.map((item) => {

        let ActualArrivalDateTime = '';
        if( item['Cod Event Date'] && item['Cod Event Time'] ){
          if(item['Cod Event Date'].indexOf('-') >= 0){
            ActualArrivalDateTime = dayjs(item['Cod Event Date'] + ' ' + item['Cod Event Time']).format('DD.MM.YYYY HH:mm');
          } else {
            ActualArrivalDateTime = item['Cod Event Date'] + ' ' + item['Cod Event Time'];
          }
        }
        let ActualDepartureDateTime = '';
        if(item['Truck Departure Date'] && item['Truck Departure Time']){
          if(item['Truck Departure Date'].indexOf('-') >= 0){
            ActualDepartureDateTime = dayjs(item['Truck Departure Date'] + ' ' + item['Truck Departure Time']).format('DD.MM.YYYY HH:mm')
          } else {
            ActualDepartureDateTime = item['Truck Departure Date'] + ' ' + item['Truck Departure Time']
          }
        }


        return {
            ...item,
            // ActualArrivalDateTime: '15.09.2023 09:00',
            // ActualDepartureDateTime: '15.09.2023 09:00',
            // Comment: 'Комментарий'
            key: item.bxDeliveryId + '_' + item.Shipment,
            ShipmentId: item['Shipment'],
            SourceLocation: item['Wh'],
            LddDateTime: item['LDD date'] + ' ' + item['LDD time'],
            StopNumber: item['Stop Seq'],
            Location: item['Ship-to'],
            LocationName: item['Ship-to Name'],
            LocationCity: item['City'],
            LocationObl: item['Province'],
            LocationAddress: item['Address'],
            ActualArrivalDateTime: ActualArrivalDateTime,
            ActualDepartureDateTime: ActualDepartureDateTime,
            Comment: item['Comments'],
            DriverName: item['Driver Name'],
            CarrierCode: item['Serv Prov'],
            TransportTitle: item['Carrier Name'],
            CarNumber: item['Truck Number'],
            Email: item['Modified by'],
            LastUpdateDateTime: item['Modyfying date'],
            Cost: item['Ship Cost']

            // shipment_id: getValueProperty(item, '491'),
            // source_location: getValueProperty(item, '603'),
            // stop_num: '?',
            // location: getValueProperty(item, '495'),
            // location_name: getValueProperty(item, '513'),
            // city: getValueProperty(item, '517'),
            // province: getValueProperty(item, '515'),
            // customer_address: getValueProperty(item, '519'),
            // ldd_date: getValueProperty(item, '583') + ' ' + initData.time[getValueProperty(item, '585')].NAME,
            // cod: '',
            // actual_departure: '',
            // remark: getValueProperty(item, '569'),
            // carrier_code: getValueProperty(item, '527'),
            // email_user: '',
            // last_modified: '',
            // price_shipment: ''
        };
    });
    }
  return retData;
});

const getStateSearchFilterData = (state) => state.cod.searchFilterData.result;

export const searchFilterDataSelector = createSelector([getStateSearchFilterData], (items) => {
    let retData = [];
    if(items && items.length > 0){
      retData = items.map((item) => {
          return {
              value: item.ID,
              value: item.NAME,
          };
      });
      }
    return retData;
  });