import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getListBooking,
    getListBookingSuccess,
    getListBookingFailure,
    getListBookingIdle,
    setListWarehousesBookings,
    setBlockedCells,
    setReserveCells,
    addBlockedCell,
    addBlockedCellSuccess,
    addBlockedCellFailure,
    addBlockedCellIdle,
    updateBooking,
    updateBookingSuccess,
    updateBookingFailure,
    updateBookingIdle,
    setCheckedWarehouses,
    setAllBlockedTimeDock,
    setCheckedDirection,
    setActiveWarehouses,
    checkedBlockedCells,
    checkedBlockedCellsSuccess,
    checkedBlockedCellsFailure,
    checkedBlockedCellsIdle,
    updateBlocked,
    updateBlockedSuccess,
    updateBlockedFailure,
    updateBlockedIdle,
    getFreeCells,
    getFreeCellsSuccess,
    getFreeCellsFailure,
    getFreeCellsIdle,
    checkedBlockedCellsDate,
    checkedBlockedCellsDateSuccess,
    checkedBlockedCellsDateFailure,
    checkedBlockedCellsDateIdle,
    getFreeCellsClear,
    addBooking,
    addBookingSuccess,
    addBookingFailure,
    addBookingIdle,
    addBookingClear,
    getBookingShipment,
    getBookingShipmentSuccess,
    getBookingShipmentFailure,
    getBookingShipmentIdle,
    getBookingShipmentClear,
    deleteBooking,
    deleteBookingSuccess,
    deleteBookingFailure,
    deleteBookingIdle,
    searchBooking,
    searchBookingSuccess,
    searchBookingFailure,
    searchBookingIdle,
    searchBookingReset,
    searchTypeLocation,
    searchTypeLocationFailure,
    searchTypeLocationIdle,
    searchTypeLocationSuccess,
    searchUsersProvider,
    searchUsersProviderFailure,
    searchUsersProviderIdle,
    searchUsersProviderSuccess,
    getServprovNameSuccess
} from "./actions";
import { getValueProperty } from "../../constants/config";

const status = handleActions(
    {
        [getListBookingSuccess]: () => "SUCCESS",
        [getListBooking]: () => "LOADING",
        [getListBookingFailure]: () => "FAILURE",
        [getListBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const addBlockedCellStatus = handleActions(
    {
        [addBlockedCellSuccess]: () => "SUCCESS",
        [addBlockedCell]: () => "LOADING",
        [addBlockedCellFailure]: () => "FAILURE",
        [addBlockedCellIdle]: () => "IDLE",
    },
    'IDLE'
);

const updateBookingStatus = handleActions(
    {
        [updateBookingSuccess]: () => "SUCCESS",
        [updateBooking]: () => "LOADING",
        [updateBookingFailure]: () => "FAILURE",
        [updateBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const response = handleActions(
    {
        [getListBookingSuccess]: (state, action) => action.payload,
        [getServprovNameSuccess]: (state, action) => {
            let { payload } = action;
            let { booking, shortName } = payload;

            let dockId = getValueProperty(booking, 291);

            let newState = state;

            state[dockId].map(item => {
                let newItem = item;
                if(getValueProperty(item, 377) == getValueProperty(booking, 377)){
                    newItem['shortName'] = shortName;
                    // {
                    //     ...item,
                    //     shortName: shortName
                    // }
                }
                return newItem;
            });

            return {
                ...newState,
                [dockId]: state[dockId]
            }

            // console.log(state, action);
        },
        [getListBookingFailure]: (state, action) => action.payload,
    },
    null
)

const warehouses = handleActions(
    {
        [setListWarehousesBookings]: (state, action) => action.payload
    },
    []
)

const blockedCells = handleActions(
    {
        [setBlockedCells]: (state, action) => action.payload
    },
    {}
)

const reserved = handleActions(
    {
        [setReserveCells]: (state, action) => action.payload
    },
    {}
)

const allBlockedTimeDock = handleActions(
    {
        [setAllBlockedTimeDock]: (state, action) => action.payload
    },
    {}
)

const checkedWarehouses = handleActions(
    {
        [setCheckedWarehouses]: (state, action) => action.payload
    },
    []
)

const checkedDirectionDock = handleActions(
    {
        [setCheckedDirection]: (state, action) => action.payload
    },
    ''
)

const activeWarehouses = handleActions(
    {
        [setActiveWarehouses]: (state, action) => action.payload
    },
    []
)

const statusCheckedBlockedCells = handleActions(
    {
        [checkedBlockedCellsSuccess]: () => "SUCCESS",
        [checkedBlockedCells]: () => "LOADING",
        [checkedBlockedCellsFailure]: () => "FAILURE",
        [checkedBlockedCellsIdle]: () => "IDLE",
    },
    'IDLE'
);

const resultCheckedBlockedCells = handleActions(
    {
        [checkedBlockedCellsSuccess]: (state, action) => action.payload,
    },
    false
);

const statusCheckedBlockedCellsDate = handleActions(
    {
        [checkedBlockedCellsDateSuccess]: () => "SUCCESS",
        [checkedBlockedCellsDate]: () => "LOADING",
        [checkedBlockedCellsDateFailure]: () => "FAILURE",
        [checkedBlockedCellsDateIdle]: () => "IDLE",
    },
    'IDLE'
);

const resultCheckedBlockedCellsDate = handleActions(
    {
        [checkedBlockedCellsDateSuccess]: (state, action) => action.payload,
    },
    false
);

const statusUpdateBlocked = handleActions(
    {
        [updateBlockedSuccess]: () => "SUCCESS",
        [updateBlocked]: () => "LOADING",
        [updateBlockedFailure]: () => "FAILURE",
        [updateBlockedIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusGetFreeCells = handleActions(
    {
        [getFreeCellsSuccess]: () => "SUCCESS",
        [getFreeCells]: () => "LOADING",
        [getFreeCellsFailure]: () => "FAILURE",
        [getFreeCellsIdle]: () => "IDLE",
    },
    'IDLE'
);

const resultGetFreeCells = handleActions(
    {
        [getFreeCellsSuccess]: (state, action) => action.payload,
        [getFreeCellsClear]: (state, action) => null,
    },
    null
);

const addBookingStatus = handleActions(
    {
        [addBookingSuccess]: () => "SUCCESS",
        [addBooking]: () => "LOADING",
        [addBookingFailure]: () => "FAILURE",
        [addBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const addBookingResult = handleActions(
    {
        [addBookingSuccess]: (state, action) => action.payload,
        [addBookingClear]: () => {},
    },
    {}
);

const getBookingShipmentStatus = handleActions(
    {
        [getBookingShipmentSuccess]: () => "SUCCESS",
        [getBookingShipment]: () => "LOADING",
        [getBookingShipmentFailure]: () => "FAILURE",
        [getBookingShipmentIdle]: () => "IDLE",
    },
    'IDLE'
);

const getBookingShipmentResult = handleActions(
    {
        [getBookingShipmentSuccess]: (state, action) => action.payload,
        [getBookingShipmentClear]: () => {}
    },
    {}
);

const deleteBookingStatus = handleActions(
    {
        [deleteBookingSuccess]: () => "SUCCESS",
        [deleteBooking]: () => "LOADING",
        [deleteBookingFailure]: () => "FAILURE",
        [deleteBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const searchBookingStatus = handleActions(
    {
        [searchBookingSuccess]: () => "SUCCESS",
        [searchBooking]: () => "LOADING",
        [searchBookingFailure]: () => "FAILURE",
        [searchBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const searchBookingResult = handleActions(
    {
        [searchBookingSuccess]: (state, action) => action.payload,
        [searchBookingReset]: () => null,
    },
    null
);

const searchTypeLocationStatus = handleActions(
    {
        [searchTypeLocationSuccess]: () => "SUCCESS",
        [searchTypeLocation]: () => "LOADING",
        [searchTypeLocationFailure]: () => "FAILURE",
        [searchTypeLocationIdle]: () => "IDLE",
    },
    'IDLE'
);

const searchTypeLocationResult = handleActions(
    {
        [searchTypeLocationSuccess]: (state, action) => action.payload,
    },
    []
);

const searchUsersProviderStatus = handleActions(
    {
        [searchUsersProviderSuccess]: () => "SUCCESS",
        [searchUsersProvider]: () => "LOADING",
        [searchUsersProviderFailure]: () => "FAILURE",
        [searchUsersProviderIdle]: () => "IDLE",
    },
    'IDLE'
);

const searchUsersProviderResult = handleActions(
    {
        [searchUsersProviderSuccess]: (state, action) => action.payload,
    },
    []
);

const booking = combineReducers({
    status,
    response,
    // warehouses,
    blockedCells,
    reserved,
    addBlockedCellStatus,
    updateBookingStatus,
    checkedWarehouses,
    allBlockedTimeDock,
    checkedDirectionDock,
    activeWarehouses,
    statusCheckedBlockedCells,
    resultCheckedBlockedCells,
    statusCheckedBlockedCellsDate,
    resultCheckedBlockedCellsDate,
    statusUpdateBlocked,
    statusGetFreeCells,
    resultGetFreeCells,
    addBookingStatus,
    addBookingResult,
    getBookingShipmentStatus,
    getBookingShipmentResult,
    deleteBookingStatus,
    searchBookingStatus,
    searchBookingResult,
    searchTypeLocationStatus,
    searchTypeLocationResult,
    searchUsersProviderStatus,
    searchUsersProviderResult
});

export {booking};
