import {createActions} from "redux-actions";

const {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
} = createActions(
    "GET_INIT_DATA",
    "GET_INIT_DATA_SUCCESS",
    "GET_INIT_DATA_FAILURE",
    "GET_INIT_DATA_IDLE",
);


export {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
};
