import * as React from "react";
import { useSelector } from "react-redux";
import FilesUpload from "../../../../../../components/filesUpload";
import iconMark from '../../../../../../../assets/img/icons/mark.svg';


const ForwarderFiles = ({ requestId, setUpload, files }) => {

  const dataUser = useSelector(state => state.authApp.init);
  // const labour = useSelector((state) => state.files.labour);
  // const identity = useSelector((state) => state.files.identity);
  // const passport = useSelector((state) => state.files.passport);
  // const third_party = useSelector((state) => state.files.third_party);
  // const forwarding = useSelector((state) => state.files.forwarding);
  // const cont_bet_forw_and_car = useSelector((state) => state.files.cont_bet_forw_and_car);
  // const attorney = useSelector((state) => state.files.attorney);
  // const extract = useSelector((state) => state.files.extract);
  // const criminal = useSelector((state) => state.files.criminal);
  // const bankruptcy = useSelector((state) => state.files.bankruptcy);
  // const tax = useSelector((state) => state.files.tax);
  // const assets = useSelector((state) => state.files.assets);
  // const obligations = useSelector((state) => state.files.obligations);
  // const tractor = useSelector((state) => state.files.tractor);
  // const trailer = useSelector((state) => state.files.trailer);
  // const rent = useSelector((state) => state.files.rent);


  const labour = [];
  const identity = [];
  const passport = [];
  const third_party = [];
  const forwarding = [];
  const cont_bet_forw_and_car = [];
  const attorney = [];
  const extract = [];
  const criminal = [];
  const bankruptcy = [];
  const tax = [];
  const assets = [];
  const obligations = [];
  const tractor = [];
  const trailer = [];
  const rent = [];
  

  React.useEffect(() => {
    if (
      passport.length > 0 &&
      identity.length > 0 &&
      labour.length > 0 && 
      third_party.length > 0 &&
      forwarding.length > 0 &&
      cont_bet_forw_and_car.length > 0 &&
      attorney.length > 0 &&
      extract.length > 0 &&
      criminal.length > 0 &&
      bankruptcy.length > 0 &&
      tax.length > 0 &&
      assets.length > 0 &&
      obligations.length > 0 &&
      tractor.length > 0 &&
      trailer.length > 0 &&
      rent.length > 0
      ) {
      setUpload(true);
    } else {
      setUpload(false);
    }
  }, [passport, identity, labour, third_party, forwarding, cont_bet_forw_and_car, attorney, extract, criminal, bankruptcy, tax, assets, obligations, tractor, trailer, rent]);

  return <div className="application-table__wrap-tables">
  <div className="application-table__table">
    <div className="application-table__row">
      <div className="application-table__col">Наименование третьей стороны</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("third_party") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("third_party") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="third_party" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Двойное экспедирование отсутствует</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("forwarding") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("forwarding") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="forwarding" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Договор между Экспедитором и Перевозчиком</div>
      <div className="application-table__col">
      {files.some(item => item.indexOf("cont_bet_forw_and_car") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("cont_bet_forw_and_car") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="cont_bet_forw_and_car" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Доверенность на подписанта договора со стороны Перевозчика или Приказ о назначении Директора</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("attorney") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("attorney") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="attorney" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}  
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Выписка из ЕГРЮЛ/ЕГРИП</div>
      <div className="application-table__col"> 
        {files.some(item => item.indexOf("extract") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("extract") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="extract" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}    
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Проверка на уголовное преследование</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("criminal") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("criminal") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="criminal" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Проверка на банкротство</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("bankruptcy") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("bankruptcy") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="bankruptcy" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Проверка по базе налоговой</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("tax") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("tax") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="tax" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Наличие активов</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("assets") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("assets") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="assets" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Отсутствие неисполненных обязательств</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("obligations") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("obligations") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="obligations" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Свидетельство о регистрации на тягач</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("tractor") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("tractor") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="tractor" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}  
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Свидетельство о регистрации на прицеп</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("trailer") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("trailer") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="trailer" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)}  
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Договор аренды ТС</div>
      <div className="application-table__col"> 
        {files.some(item => item.indexOf("rent") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("rent") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="rent" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Договор трудовой или подряда на водителя</div>
      <div className="application-table__col"> 
        {files.some(item => item.indexOf("labour") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("labour") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="labour" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Водительское удостоверение</div>
      <div className="application-table__col"> 
        {files.some(item => item.indexOf("identity") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("identity") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="identity" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Паспорт водителя</div>
      <div className="application-table__col"> 
        {files.some(item => item.indexOf("passport") > 0) && (
          <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("passport") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="passport" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
  </div>
</div>
};
export default ForwarderFiles;
