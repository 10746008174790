import * as React from "react";
import { useSelector } from "react-redux";
import FilesUpload from "../../../../../../components/filesUpload";
import iconMark from '../../../../../../../assets/img/icons/mark.svg';

const FirstMover = ({ requestId, setUpload, files }) => {

  const dataUser = useSelector(state => state.authApp.init);
  // const pts = useSelector((state) => state.files.pts);
  // const labour = useSelector((state) => state.files.labour);
  // const identity = useSelector((state) => state.files.identity);
  // const passport = useSelector((state) => state.files.passport);


  const pts = [];
  const labour = [];
  const identity = [];
  const passport = [];

  React.useEffect(() => {
    if (passport.length > 0 && identity.length > 0 && labour.length > 0 && pts.length > 0) {
      setUpload(true);
    } else {
      setUpload(false);
    }
  }, [passport, identity, labour, pts]);

  return <div className="application-table__wrap-tables">
  <div className="application-table__table">
    <div className="application-table__row">
      <div className="application-table__col">ПТС/Договор аренды</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("pts") > 0) && (
          <a download={true} href={files.filter(item => item.indexOf("pts") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="pts" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Договор трудовой или подряда на водителя</div>
      <div className="application-table__col">
      {files.some(item => item.indexOf("labour") > 0) && (
          <a download={true} href={files.filter(item => item.indexOf("labour") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="labour" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Водительское удостоверение</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("identity") > 0) && (
          <a download={true} href={files.filter(item => item.indexOf("identity") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="identity" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
    <div className="application-table__row">
      <div className="application-table__col">Паспорт водителя</div>
      <div className="application-table__col">
        {files.some(item => item.indexOf("passport") > 0) && (
            <a target="_blank" rel="noreferrer" download={true} href={files.filter(item => item.indexOf("passport") > 0)[0]} style={{"background": `url("${iconMark}") top/cover no-repeat`}} className="application-table__mark"></a>
        ) || (dataUser && dataUser.group !== "user_ul" && <FilesUpload name="passport" id={requestId} path={dataUser && dataUser.filePath} request={true} multi={false} />) || (dataUser && dataUser.group === "user_ul" && <span />)} 
      </div>
    </div>
  </div>
</div>
};
export default FirstMover;
