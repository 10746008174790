import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { appID, domain, getWithExpiry, redirectAuth, setWithExpiry, timeSessionApp } from "../../constants/config";
import { getInitData } from "../initData/actions";
import { handleAuthApp, setAuthApp, handleAuthAppInit } from "./actions";

const AuthApp = function(props){

    const dispatch = useDispatch();

    const { status, response } = useSelector(state => state.authApp);
    const state = useSelector(state => state);

    // console.log(state);

    const statusInitData = useSelector(state => state.initData.status);
    const docksInitData = useSelector(state => state.initData.docks);
    const warehousesInitData = useSelector(state => state.initData.warehouses);
    const timeInitData = useSelector(state => state.initData.time);
    const fieldsWarehousesInitData = useSelector(state => state.initData.fieldsWarehouses);
    const fieldsDocksInitData = useSelector(state => state.initData.fieldsDocks);

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(status == 'IDLE'){
            // console.log(getWithExpiry("authApp3pl"));
            if(getWithExpiry("authApp3pl")){
                // console.log(1);
                if(JSON.parse(getWithExpiry("authApp3pl")).resp == 'Unauthorized' || JSON.parse(getWithExpiry("authApp3pl")).resp == 'Refreshed'){
                    dispatch(handleAuthApp({ code: null }));    
                    // console.log(2);
                } else if(JSON.parse(getWithExpiry("authApp3pl")).resp !== 'Unauthorized' && JSON.parse(getWithExpiry("authApp3pl")).resp !== 'Refreshed' ) {
                    // console.log(3, getWithExpiry("authApp3pl"));
                    dispatch(setAuthApp(JSON.parse(getWithExpiry("authApp3pl"))));
                    dispatch(handleAuthAppInit(JSON.parse(getWithExpiry("authApp3pl"))));
                }
            } else {
                // console.log(searchParams.get("code"));
                // console.log(4, searchParams, searchParams.get("code"), window.location.search);
                if(searchParams.get("code")){
                    // console.log(5)
                    dispatch(handleAuthApp({ code: searchParams.get("code") }));
                } else {
                    // console.log(6);
                    dispatch(handleAuthApp({ code: null }));
                }
            }
        }
    }, [])

    useEffect(() => {
        if(statusInitData == 'SUCCESS'){

            if(
                docksInitData.error && docksInitData.error == "expired_token" || 
                warehousesInitData.error && warehousesInitData.error == "expired_token" || 
                timeInitData.error && timeInitData.error == "expired_token" || 
                fieldsWarehousesInitData && fieldsWarehousesInitData.error && fieldsWarehousesInitData.error == "expired_token" || 
                fieldsDocksInitData && fieldsDocksInitData.error && fieldsDocksInitData.error == "expired_token"
            ){

                redirectAuth();

            }
        } else if(statusInitData == 'FAILURE'){
            redirectAuth();
        }
    }, [statusInitData])

    useEffect(() => {

        if(response){
            if(status == 'SUCCESS'){

                // console.log(response);

                if(response && ( response.resp == 'Unauthorized' || response.resp == 'Refreshed') ){
                    // console.log(5);
                    redirectAuth();
                } else {
                    if(!getWithExpiry("authApp3pl")){
                        let params = '';
                        setSearchParams(params);
                        // console.log(response, timeSessionApp);
                        setWithExpiry("authApp3pl", JSON.stringify(response), timeSessionApp);
                        dispatch(setAuthApp(response));
                        dispatch(handleAuthAppInit(response));
                    }
                    if(statusInitData == 'IDLE'){
                        dispatch(getInitData());
                    }
                }
                
            }
            if(status == 'FAILURE'){
                if(
                    response && ( response.resp == 'Unauthorized' || response.resp == 'Refreshed') 
                ){
                    // console.log(6);
                    redirectAuth();
                }
            }
        }

    }, [status])

    return (
        <></>    
    )
}

export default AuthApp;