import * as React from "react";

const Footer = () => {

  return <footer className="footer">
    <div className="footer__content container">
      <p>КОММЕРЧЕСКАЯ ТАЙНА. Правообладатель ООО «Юнилевер Русь» (РФ, г. Москва, ул. Сергея Макеева, дом 13). Правообладатель ООО «Инмарко-Трейд» (РФ, г. Омск, ул.&nbsp;10&nbsp;лет Октября, 205). Информация, содержащаяся в&nbsp;настоящей системе, составляет коммерческую тайну. Передача информации иным лицам запрещена.</p>
    </div>
  </footer>;
};
export default Footer;
