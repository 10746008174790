import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { addPaymentAll } from "../../../../store/ducks/po/actions";
import XlsExport from 'xlsexport';
import { useEffect } from "react";
import { updateItemPo, updateItemPoIdle } from "../../../../../modules/po/actions";

const Btns = (props) => {
  const { openNotificationWithIcon, itemsExport } = props;
  const dispatch = useDispatch();
  const ids = useSelector((state) => state.po.ids);
  // const ids = [];

  const exportArrHandle = (e) => {
    e.preventDefault();
    const xls = new XlsExport(itemsExport, 'poItemsExport');
    xls.exportToXLS('poItemsExport.xls');
  }

  const dataDefault = useSelector(state => state.po.data);
  const statusUpdate = useSelector(state => state.poItem.statusItemUpdate);
  
  useEffect(() => {
    if(statusUpdate == 'SUCCESS'){
      dispatch(updateItemPoIdle());
    }
  }, [statusUpdate])

  const addPaymentAllHandle = (e) => {
    e.preventDefault();
    if (ids.length > 0) {
      let updatesItems = [];
      ids.map((id) => {
        let findItem = dataDefault.filter(item => item.ID == id);
        updatesItems.push({
          ...findItem[0],
          'PROPERTY_185': '99'
        });
      });
      if(statusUpdate == 'IDLE'){
        dispatch(updateItemPo({
          updatesItems
        }));
      }
    } else {
      openNotificationWithIcon('error', 'Выберите PO из таблицы')
    }
  }

  return <div className="main__inner"> 
  <button onClick={addPaymentAllHandle} className="btn main__btn">Счет выставлен</button>
  {itemsExport.length > 0 && (
    <a target="_blank" rel="noreferrer" download={true} href="" onClick={(e) => exportArrHandle(e)} className="btn main__btn">Выгрузить в Excel</a>
  )}
</div>;
};
export default Btns;
