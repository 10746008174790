import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getContacts } from "../../store/ducks/contacts/actions";
import BreadCrumbs from "../../../modules/breadCrumbs";
import { useForm, Controller } from "react-hook-form";
import IconSearch from '../../../assets/img/icons/search.svg';

const Contacts = () => {
    const dispatch = useDispatch();
    // const contacts = useSelector((state) => state.contacts.items);
    const contacts = [
      {
        ID: 1,
        FullName: "Иван Иванов",
        Title: "Директор по связям",
        AttachmentFiles: {
          results: []
        },
        Phone: "+79990000000",
        Email: "test@mail.ru"
      },
      {
        ID: 1,
        FullName: "Федоро Федоров",
        Title: "Маркетинг",
        AttachmentFiles: {
          results: []
        },
        Phone: "+79990000000",
        Email: "test@mail.ru"
      }
    ];
    const [breadcrumb, setBreadcrumb] = React.useState([
      {name: 'Сервисы', link: `/`},
      {name: 'Контакты', link: `/contacts`}
    ]);
    const [searchText, setSearchText] = React.useState("");
    const { control, handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm();
    const [dataSearch, setDataSearch] = React.useState([]);

    const onSubmit = data => {
      setSearchText(data.searchText);
    };

    React.useEffect(() => {
      // dispatch(getContacts());
    }, [])

    React.useEffect(() => {
      if (searchText.length > 0) {
        // dispatch(getContacts(searchText))
      } else {
        // dispatch(getContacts());
      }
    }, [searchText])

    React.useEffect(() => {
      setDataSearch(contacts)
    }, [contacts]);

  return <main className="main">
  <div className="main__content container">
    <div className="main__wrap">
      <BreadCrumbs breadcrumb={breadcrumb} />
    </div>
    <div className="contacts__container">
        <h1 className="section-title main__section-title">Контакты</h1>
        <form onChange={handleSubmit(onSubmit)} className="form contacts__page" action="#">
          <div className="form__container"> 
            <div className="form__row">
              <div className="form__col">
                <div className="form__flex__input">
                  <input {...register('searchText')} className="input form__input"  type="text" placeholder="Введите имя или должность" />
                  <span><img src={IconSearch} /></span>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="contacts-list">
        {dataSearch.length > 0 && dataSearch.map((item) => (
          <div key={item.ID} className="contact-card">
            <div className="contact-item fullName">{item.FullName}</div>
            <div className="contact-item title">{item.Title}</div>
            {item.AttachmentFiles.results.length > 0 && (
              <div className="contact-item files">
                {item.AttachmentFiles.results.map((file, idx) => (
                  <a key={idx} target="_blank" rel="noreferrer" download={true} href={file.ServerRelativeUrl} className="file-link download">{file.FileName}</a>
                ))}
              </div>
            )}
            <div className="contact-item phone"><a href={"tel:" + item.Phone}>{item.Phone}</a></div>
            <div className="contact-item email"><a href={"mailto:" + item.Email}>{item.Email}</a></div>
          </div>
        ))}
        </div>
        {dataSearch.length === 0 && searchText.length > 0 && (
          <div className="section-title main__section-title">Нет совпаденй</div>
        )}
    </div>
</div></main>;
};
export default Contacts;
