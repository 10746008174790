import * as React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import BtnsForm from "../btnsForm";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";
registerLocale("ru", ru);

const customStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "60px",
  }),
};

const UnloadingForm = ({
  setSendData,
  requestItem,
  listFlightNumber,
  autocomplete,
}) => {
  const dispatch = useDispatch();
  // const defaultData: SelectedInitData[] | [] = useSelector(selectInitData);
  // const defaultData = [];
  const warehouses = useSelector(warehousesOptionsSelector);

  const {
    control,
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: requestItem,
  });

  const onSubmit = (data) => {
    setSendData({ ...data, ...{TypeRequest: "Простой на выгрузке"}});
  };

  // React.useEffect(() => {
  //   if (defaultData.length === 0) {
  //     // dispatch(initData());
  //   }
  // }, []);

  React.useEffect(() => {
    if (watch("FlightNumber") && watch("FlightNumber")["value"]) {
      const flightNumber = watch("FlightNumber")["value"];
      const obj = autocomplete.find((el) => el.FlightNumber === flightNumber);
      setValue("Warehouse", obj.Warehouse);
      setValue("TnNumber", obj.TnNumber);
      setValue("NameClient", obj.NameClient);
      setValue("DeliveryAddress", obj.DeliveryAddress);
      setValue("Driver", obj.Driver);
      setValue("CarNumber", obj.CarNumber);
      setValue("DateTimeDelivery", obj.DateTimeDelivery);
      setValue("ActualDateTime", obj.ActualDateTime);
    }
  }, [watch("FlightNumber")]);

  return (
    <div className="block-tabs__block active">
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form form--request form--request-doc block-tabs__block__form"
        action="#"
      >
        <div className="form__container">
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.FlightNumber && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Номер рейса {errors.FlightNumber && "*"}
              </label>
              {(listFlightNumber.length > 0 && (
                <Controller
                  name="FlightNumber"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      value={field.value || ""}
                      styles={customStyles}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Номер рейса"
                      options={listFlightNumber}
                    />
                  )}
                />
              )) || (
                <input
                  {...register("FlightNumber", { required: true })}
                  className="input form__input"
                  type="text"
                  placeholder="Номер рейса"
                />
              )}
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.Warehouse && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Склад отгрузки {errors.Warehouse && "*"}
              </label>
              <Controller
                name="Warehouse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    value={field.value}
                    styles={customStyles}
                    onChange={(value) => field.onChange(value)}
                    placeholder="Склад отгрузки"
                    options={warehouses}
                  />
                )}
              />
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.TnNumber && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Номер ТН {errors.TnNumber && "*"}
              </label>
              <input
                {...register("TnNumber", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Номер ТН"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.NameClient && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Наименование клиента {errors.NameClient && "*"}
              </label>
              <input
                {...register("NameClient", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Наименование клиента"
              />
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.DeliveryAddress && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Адрес доставки {errors.DeliveryAddress && "*"}
              </label>
              <input
                {...register("DeliveryAddress", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Адрес доставки"
              />
            </div>
          </div>
        </div>
        <div className="form__container">
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.Driver && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Водитель {errors.Driver && "*"}
              </label>
              <input
                {...register("Driver", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Водитель"
              />
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.PhoneDriver && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Номер телефона водителя {errors.PhoneDriver && "*"}
              </label>
              <input
                {...register("PhoneDriver", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Номер телефона водителя"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.CarNumber && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Номер авто {errors.CarNumber && "*"}
              </label>
              <input
                {...register("CarNumber", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Номер авто"
              />
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.CarBrand && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Марка машины {errors.CarBrand && "*"}
              </label>
              <input
                {...register("CarBrand", { required: true })}
                className="input form__input"
                type="text"
                placeholder="Марка машины"
              />
            </div>
          </div>
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.DateTimeDelivery && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Дата и время доставки из заявки {errors.DateTimeDelivery && "*"}
              </label>
              <Controller
                name="DateTimeDelivery"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="d MMMM yyyy HH:mm"
                    showTimeSelect
                    placeholderText="Дата и время доставки из заявки"
                    selected={field.value}
                    className="input form__input"
                    onChange={(date) => field.onChange(date)}
                    locale="ru"
                  />
                )}
              />
            </div>
            <div className="form__col">
              <label
                className={
                  (errors.ActualDateTime && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Фактическое дата и время постановки на выгрузку {errors.ActualDateTime && "*"}
              </label>
              <Controller
                name="ActualDateTime"
                control={control}
                defaultValue={null}
                rules={{ required: true }}
                render={({ field }) => (
                  <DatePicker
                    dateFormat="d MMMM yyyy HH:mm"
                    placeholderText="Фактическое дата и время постановки на выгрузку"
                    showTimeSelect
                    selected={field.value}
                    className="input form__input"
                    onChange={(date) => field.onChange(date)}
                    locale="ru"
                  />
                )}
              />
            </div>
            <div className="form__col">
              <label className="form__label">
                <input
                  {...register("CarArriveOnTime")}
                  name="CarArriveOnTime"
                  className="form__checkbox"
                  type="checkbox"
                />
                <span className="form__label-text">
                  Автомобиль приехал вовремя?
                </span>
              </label>
            </div>
          </div>
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.ReasonNonLoading && "label__form__input error") ||
                  "label__form__input"
                }
              >
                Причина невыгрузки (со слов Клиента){" "}
                {errors.ReasonNonLoading && "*"}
              </label>
              <textarea
                {...register("ReasonNonLoading", { required: true })}
                className="input form__textarea"
                placeholder="Причина невыгрузки (со слов Клиента)"
              ></textarea>
            </div>
          </div>
          <div className="form__row">
            <div className="form__col">
              <label
                className={
                  (errors.NumberInvoiceWhichLoadingRegiste &&
                    "label__form__input error") ||
                  "label__form__input"
                }
              >
                Номер накладной по которой регистрировались на выгрузку{" "}
                {errors.NumberInvoiceWhichLoadingRegiste && "*"}
              </label>
              <input
                {...register("NumberInvoiceWhichLoadingRegiste", {
                  required: true,
                })}
                className="input form__input"
                type="text"
                placeholder="Номер накладной по которой регистрировались на выгрузку"
              />
            </div>
          </div>
        </div>
        <div className="form__container">
          <BtnsForm id={requestItem && requestItem.ID} setValue={setValue} />
        </div>
      </form>
    </div>
  );
};
export default UnloadingForm;
