import * as React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import dayjs from 'dayjs';
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { getRequestsIdle } from "../../../../store/ducks/requests/actions";
// import { changeFilter } from "../../../../store/ducks/filter/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import { RequestsStatus, RequestsType, list } from "../../constants";
import moment from 'moment';
import { getListRequests, getListRequestsClear } from "../../../../../modules/requests/actions";
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";
registerLocale('ru', ru);

const selectStyles = {
  singleValue: styles => ({ ...styles, lineHeight: '18px' }),
  container: styles => ({...styles, maxWidth: '250px', width: '250px'}),
};

const Filter = (props) => {

  const dispatch = useDispatch();
  const defaultData = [];
  const dataUser = useSelector(state => state.authApp.init);
  const [startDateCreated, setStartDateCreated] = React.useState(null);
  const [endDateCreated, setEndDateCreated] = React.useState(null);
  const [startDate, setStartDate] = React.useState(null);
  const [endDate, setEndDate] = React.useState(null);
  const [localFilterData, setLocalFilterData] = React.useState({
    po: null,
    requests: null
  });
  const status = useSelector(state => state.requests.status);
  const localFilter = localStorage.getItem('filter');


  const { control, handleSubmit, register, reset, watch, setValue } = useForm();
  const { openNotificationWithIcon } = props;

  React.useEffect(() => {
    if (localFilter) {
      setLocalFilterData(JSON.parse(localFilter));
    } else {
      localStorage.setItem("filter", JSON.stringify({
        po: null,
        requests: null
      }));
    }
  }, [localFilter]);

  React.useEffect(() => {
    const { requests } = localFilterData;
    if (requests) {
      setStartDate(requests.startDate && moment(requests.startDate).toDate() || null);
      setEndDate(requests.endDate && moment(requests.endDate).toDate() || null);
      setValue('Warehouse', requests.Warehouse);
      setValue('FlightNumber', requests.FlightNumber);
      setValue('TypeRequest', requests.TypeRequest);
      setValue('Status', requests.Status);
      setValue('Author', requests.Author);
      if (dataUser && dataUser.group === "user_ul") {
        setValue('Partner', requests.Partner);
      }
    }
  }, [localFilterData, dataUser]);
  
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  
  const onSubmit = data => {
    const filterObj = {}, localObj = {};
    data['Created'] = startDate;
    let error = false;
    Object.keys(data).map(key => {
      if (data[key]) {
        localObj[key] = data[key];
        if (key === "Created") {
          if (endDate) {
            const start = moment(startDate).add(12, 'hours').toDate();
            start.setUTCHours(0,0,0,0);
            const end = moment(endDate).add(12, 'hours').toDate();
            end.setUTCHours(23,59,59,999);
            filterObj[key] = [start.toISOString(), end.toISOString()];
            localObj['startDate'] = startDate;
            localObj['endDate'] = endDate;
          } else {
            openNotificationWithIcon("error", "Закончите выбор даты в поле - Дата создания");
            error = true;
          }
        } else if (Array.isArray(data[key])) {
          let arrValues = [];
          data[key].map(item => {
            arrValues = [...arrValues, item.value];
          });
          filterObj[key] = arrValues;
        } else if (typeof data[key] === "object" && !Array.isArray(data[key])) {
          if (key === 'Author') {
            filterObj[key] = data[key];
          } else if (key === 'Partner') {
            filterObj[key] = data[key].label;
          } else {
            filterObj[key] = data[key].value;
          }
        } else {
          filterObj[key] = data[key];
        }
      }
    });
    localStorage.setItem("filter", JSON.stringify({...localFilterData, requests: localObj}));
    if (!error) {
      if(status == 'IDLE'){
        let filterProperty = {};

        if(filterObj.Warehouse){
          filterProperty['PROPERTY_261'] = filterObj.Warehouse;
        }
        if(filterObj.TypeRequest){
          filterProperty['PROPERTY_197'] = filterObj.TypeRequest;
        }
        if(filterObj.Status){
          filterProperty['PROPERTY_255'] = filterObj.Status;
        }
        if(filterObj.Partner){
          filterProperty['PROPERTY_259'] = filterObj.Partner;
        }
        if(filterObj.Author){
          if(filterObj.Author.value == 1){ // Входящий
            filterProperty['!CREATED_BY'] = dataUser.id;
          } else { // Исходящий
            filterProperty['CREATED_BY'] = dataUser.id;
          }
          // filterProperty['PROPERTY_259'] = ;
        }
        if(filterObj.Created){
          filterProperty['>DATE_CREATE'] = dayjs(filterObj.Created[0]).format('DD.MM.YYYY');
          filterProperty['<DATE_CREATE'] = dayjs(filterObj.Created[1]).format('DD.MM.YYYY');
        }
        if(filterObj.FlightNumber){
          filterProperty['PROPERTY_195'] = filterObj.FlightNumber;
        }
        
        dispatch(getListRequestsClear());
        dispatch(getListRequests({
          filters: filterProperty
        }))
      }
      // dispatch(getPoIdle());
      // dispatch(changeFilter(filterObj));
      // console.log("requests_filterObj: ", filterObj);
      // dispatch(getRequestsIdle());
      // dispatch(changeFilter(filterObj));
    }
  };

  const clearFilterHandler = (e) => {
    e.preventDefault();
    // dispatch(getRequestsIdle());
    // dispatch(changeFilter({}));
    setStartDate(null);
    setEndDate(null);
    localStorage.setItem("filter", JSON.stringify({...localFilterData, requests: null}));
    reset();
    const form = document.querySelector("#filterRequests");
    if (form) {
      form.reset();
    }
  };

  React.useEffect(() => {
    // dispatch(initData());
  }, []);

  React.useEffect(() => {
    if (watch('Author') && watch('Author').value === 'Входящий') {
      setValue('Author', {label: 'Входящий', value: dataUser && String(dataUser.id)});
    }
    if (watch('Author') && watch('Author').value === 'Исходящий') {
      setValue('Author', {label: 'Исходящий', value: dataUser && String(dataUser.id)});
    }
  }, [watch('Author')]);

  const warehousesOptions = useSelector(warehousesOptionsSelector);

  return <form id="filterRequests" onSubmit={handleSubmit(onSubmit)} className="po-filters main__po-filters form">
  <div className="po-filters__row">
    <div className="po-filters__col"> 
      <Controller
        name="Warehouse"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          isMulti={true}
          styles={selectStyles}
          onChange={(value) => field.onChange(value)}
          placeholder="Склад" 
          options={warehousesOptions} 
        />}
      />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="TypeRequest"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          styles={selectStyles}
          onChange={(value) => field.onChange(value)}
          placeholder="Тип запроса" 
          options={RequestsType} 
        />}
      />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="Status"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          styles={selectStyles}
          onChange={(value) => field.onChange(value)}
          placeholder="Статус запроса"
          options={list[255]} 
        />}
      />
    </div>
    {dataUser && dataUser.group === "user_ul"  && (
      <div className="po-filters__col"> 
        <input className="input form__input" type="text" {...register('Partner')} placeholder="Партнер" />
      </div>
    )}
    {/* {dataUser && dataUser.group === "user_ul"  && (
      <div className="po-filters__col"> 
        <Controller
          name="Partner"
          control={control}
          render={({ field }) => <Select 
            value={field.value || ""}
            styles={selectStyles}
            onChange={(value) => field.onChange(value)}
            placeholder="Партнёр"
            options={[]} 
          />}
        />
      </div>
    )} */}
    <div className="po-filters__col"> 
      <Controller
        name="Author"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          styles={selectStyles}
          onChange={(value) => field.onChange(value)}
          placeholder="Направление"
          options={[
            {
              value: 1,
              label: 'Входящий'
            },
            {
              value: 2,
              label: 'Исходящий'
            }
          ]} 
        />}
      />
    </div>
    <div className="po-filters__col">		
      <Controller
        name="Created"
        control={control}
        defaultValue={null}
        render={({ field }) =>
        <DatePicker
          dateFormat="d MMMM yyyy"
          placeholderText="Дата создания"
          selected={startDate}
          className="input form__input"
          onChange={onChange}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          locale="ru"
        />
        }
        />
    </div>
    <div className="po-filters__col"> 
        <input className="input form__input" {...register('FlightNumber')} placeholder="Номер рейса" />
    </div>
    <div className="po-filters__col">
      <button type="submit" className="btn btn--blue po-filters__btn">Применить</button>
    </div>
    <div className="po-filters__col">
      <button onClick={clearFilterHandler} className="btn btn po-filters__btn">Сбросить фильтр</button>
    </div>
  </div>
</form>;
};
export default Filter;
