import { 
    appID, 
    dockBookingListId, 
    domain, 
    domainMain, 
    fields, 
    redirectAuth,
    isLocalhost, 
    paramsLocalhostApi
} from "../../constants/config";

export const getListDateBookingAllReguest = ({ start }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": '47',
        start,
        "FILTER": {
            '!PROPERTY_281' : '',
            '!PROPERTY_621' : ''
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}


export const updateBookingDateRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": '47'
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}