import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getPage } from "../../store/ducks/page/actions";
import BreadCrumbs from "../../../modules/breadCrumbs";

const Insctructions  = () => {
    const insctructionsPageUrl = `https://unilever.sharepoint.com/sites/3plCollaborationPortalRUB/SitePages/Insctructions.aspx`;
    const dispatch = useDispatch();
    // const page = useSelector((state) => state.page.content);
    const [breadcrumb] = React.useState([
        {name: 'Сервисы', link: `/`},
        {name: 'Инструкции', link: `/insctructions`}
      ]);

    React.useEffect(() => {
        // dispatch(getPage(insctructionsPageUrl))
    }, [])

    // React.useEffect(() => {
    //     console.log('insctructions: ', page);
    // }, [page])

  return <main className="main">
  <div className="main__content container">
    <div className="main__wrap">
      <BreadCrumbs breadcrumb={breadcrumb} />
    </div>
    {/* <div className="contacts__container"> */}
        {/* <h1>Инструкции</h1>
        <div dangerouslySetInnerHTML={{__html: page}} /> */}
        <br />
        <div className="container_kpi">
            <iframe src={insctructionsPageUrl} frameBorder="0" allowFullScreen={true} className="iframe_kpi">
                Ваш браузер не поддерживает плавающие фреймы!
            </iframe>
        </div>
    {/* </div> */}
</div></main>;
};
export default Insctructions;
