import * as React from "react";
import BreadCrumbs from "../../../modules/breadCrumbs";
import Btns from "./modules/btns";
import Filter from "./modules/filter";
import ListPO from "./modules/list";
import {
  notification
} from 'antd';
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getListPo, getListPoClear, getListPoIdle, updateItemPoIdle } from "../../../modules/po/actions";

const PO = () => {
  const [breadcrumb, setBreadcrumb] = React.useState([
    { name: 'Сервисы', link: "/" },
    { name: 'PO', link: "/po" }
  ]);
  const [itemsExport, setItemsExport] = React.useState([]);

  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, description, message) => {
    if (message) {
      api[type]({
        message: message,
        description
      });
    } else {
      api[type]({
        message: type == 'error' ? 'Ошибка' : 'Успешно',
        description
      });
    }
  };

  const status = useSelector(state => state.po.status);

  useEffect(() => {
    if(status == 'IDLE'){
      dispatch(getListPoClear());
      dispatch(getListPo({}));
    }
  }, [])

  useEffect(() => {
    if(status == 'SUCCESS'){
      dispatch(getListPoIdle());
    }
  }, [status])

  return<>
  {contextHolder}
  <main className="main">
    <div className="main__content container">
      <div className="main__wrap">
        <BreadCrumbs breadcrumb={breadcrumb} />
        <Btns openNotificationWithIcon={openNotificationWithIcon} itemsExport={itemsExport} />
      </div>
      <h1 className="section-title main__section-title">Payment orders (PO)</h1>
      <Filter openNotificationWithIcon={openNotificationWithIcon} />
      <ListPO openNotificationWithIcon={openNotificationWithIcon} setItemsExport={setItemsExport} itemsExport={itemsExport} />
    </div>
  </main></> ;
};
export default PO;
