import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

const ItemDocuments = ({ item }) => {

  const dataUser = useSelector(state => state.authApp.init);

  return <div className="application-table main__application-table">
  <div className="application-table__wrap-tables">
    <div className="application-table__table">
      <div className="application-table__row"> 
        <div className="application-table__col">Направление</div>
        <div className="application-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <>
                Входящий
              </>
            )}
            {dataUser && dataUser.id === item.Author && (
              <>
                Исходящий
              </>
            )}
        </div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Тип запроса</div>
        <div className="application-table__col">{item.TypeRequest}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Дата создания</div>
        <div className="application-table__col">{item.Created}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Статус</div>
        <div className="application-table__col">{item.Status}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер рейса</div>
        <div className="application-table__col">{item.FlightNumber}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Склад отгрузки</div>
        <div className="application-table__col">{item.Warehouse}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер ТН</div>
        <div className="application-table__col">{item.TnNumber}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Наименование клиента</div>
        <div className="application-table__col">{item.NameClient}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Адрес доставки</div>
        <div className="application-table__col">{item.DeliveryAddress}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Марка машины</div>
        <div className="application-table__col">{item.CarBrand}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер авто</div>
        <div className="application-table__col">{item.CarNumber}</div>
      </div>
    </div>
    <div className="application-table__table">
      <div className="application-table__row"> 
        <div className="application-table__col">Водитель</div>
        <div className="application-table__col">{item.Driver}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер тел. водителя</div>
        <div className="application-table__col">{item.PhoneDriver}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Дата и время доставки из заявки</div>
        <div className="application-table__col">{item.DateTimeDelivery}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Фактическое дата и время прибытия</div>
        <div className="application-table__col">{item.ActualDateTime}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Причина восстановления документов</div>
        <div className="application-table__col">{item.ReasonRestoringDocuments}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Cпособ передачи документов</div>
        <div className="application-table__col">{item.DocumentTransferMethod}</div>
      </div>
      {item.TransferDocuments && (
        <div className="application-table__row"> 
         <div className="application-table__col">Дата и номер рейса, ФИО водителя, марка автомобиля, номер автомобиля, паспортные данные</div>
         <div className="application-table__col">{item.TransferDocuments}</div>
       </div>
      )}
      <div className="application-table__row"> 
        <div className="application-table__col">Какие документы нужно восстановить</div>
        <div className="application-table__col">{item.DocumentsNeedRestore}</div>
      </div>
      {item.Other && (
        <div className="application-table__row"> 
         <div className="application-table__col">Список документов</div>
         <div className="application-table__col">{item.Other}</div>
       </div>
      )}
    </div>
  </div>
</div>;
};
export default ItemDocuments;
