import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  theme,
  TimePicker,
} from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { domainMain } from "../../../../constants/config";
import {
  exportFormSearchBooking,
  exportFormSearchBookingIdle,
  exportFormSearchDestination,
  exportFormSearchDestinationIdle,
  exportFormSearchWarehouse,
  exportFormSearchWarehouseIdle,
  getExcel,
  getExcelIdle,
} from "../../../../modules/export/actions";

const { TextArea } = Input;

let timeoutSearchWarehouse;
let timeoutSearchBooking;
let timeoutSearchDestination;

const ExportForm = (props) => {
  const { setView } = props;

  const [locations, setLocations] = useState("");
  const [shipments, setShipments] = useState("");
  const [destinations, setDestinations] = useState("");

  const dispatch = useDispatch();

  const [formExport] = Form.useForm();

  const [dataWarehouses, setDataWarehouses] = useState([]);
  const [dataBookings, setDataBookings] = useState([]);
  const [dataDestinations, setDataDestinations] = useState([]);

  const exportFormSearchWarehouseStatus = useSelector((state) =>
    state.exportForm.exportFormSearchWarehouseStatus
  );
  const exportFormSearchWarehouseResult = useSelector((state) =>
    state.exportForm.exportFormSearchWarehouseResult
  );

  const exportFormSearchBookingStatus = useSelector((state) =>
    state.exportForm.exportFormSearchBookingStatus
  );
  const exportFormSearchBookingResult = useSelector((state) =>
    state.exportForm.exportFormSearchBookingResult
  );

  const exportFormSearchDestinationStatus = useSelector((state) =>
    state.exportForm.exportFormSearchDestinationStatus
  );
  const exportFormSearchDestinationResult = useSelector((state) =>
    state.exportForm.exportFormSearchDestinationResult
  );

  const getExcelStatus = useSelector((state) =>
    state.exportForm.getExcelStatus
  );
  const getExcelResult = useSelector((state) =>
    state.exportForm.getExcelResult
  );

  useEffect(() => {
    if (getExcelStatus == "SUCCESS") {
      dispatch(getExcelIdle());
      formExport.resetFields();
    }
    if (getExcelStatus == "FAILURE") {
      dispatch(getExcelIdle());
    }
  }, [getExcelStatus]);

  useEffect(() => {
    if (exportFormSearchWarehouseStatus == "SUCCESS") {
      setDataWarehouses(exportFormSearchWarehouseResult);

      dispatch(exportFormSearchWarehouseIdle());
    }
    if (exportFormSearchWarehouseStatus == "FIND") {
      let result = [];

      if (exportFormSearchWarehouseResult) {
        result = [
          ...result,
          ...exportFormSearchWarehouseResult,
        ];
      }
      if (
        formExport.getFieldValue("LocationIDSelect") &&
        formExport.getFieldValue("LocationIDSelect").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("LocationIDSelect"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("LocationIDSelect", result);
      }

      dispatch(exportFormSearchWarehouseIdle());
    }
  }, [exportFormSearchWarehouseStatus]);

  useEffect(() => {
    if (exportFormSearchBookingStatus == "SUCCESS") {
      dispatch(exportFormSearchBookingIdle());

      setDataBookings(exportFormSearchBookingResult);
    }
    if (exportFormSearchBookingStatus == "FIND") {
      let result = [];

      if (exportFormSearchBookingResult) {
        result = [
          ...result,
          ...exportFormSearchBookingResult,
        ];
      }
      if (
        formExport.getFieldValue("ShipmentIDSelect") &&
        formExport.getFieldValue("ShipmentIDSelect").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("ShipmentIDSelect"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("ShipmentIDSelect", result);
      }

      dispatch(exportFormSearchBookingIdle());
    }
  }, [exportFormSearchBookingStatus]);

  useEffect(() => {
    if (exportFormSearchDestinationStatus == "SUCCESS") {
      dispatch(exportFormSearchDestinationIdle());

      setDataDestinations(exportFormSearchDestinationResult);
    }
    if (exportFormSearchDestinationStatus == "FIND") {
      let result = [];

      if (exportFormSearchDestinationResult) {
        result = [
          ...result,
          ...exportFormSearchDestinationResult,
        ];
      }
      if (
        formExport.getFieldValue("DestinationIDSelect") &&
        formExport.getFieldValue("DestinationIDSelect").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("DestinationIDSelect"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("DestinationIDSelect", result);
      }

      dispatch(exportFormSearchDestinationIdle());
    }
  }, [exportFormSearchDestinationStatus]);

  return (
    <Form
      name="basic"
      layout="vertical"
      form={formExport}
      style={{
        maxWidth: "600px",
        margin: "0 auto",
      }}
      onFinish={(e) => {
        if (!e.errorFields) {
          let LocationIDList = [];
          if (e.LocationIDSelect && e.LocationIDSelect.length > 0) {
            e.LocationIDSelect.map((item) => {
              let value = item;
              if (item.value) {
                value = item.value;
              }
              if (LocationIDList.indexOf(value) < 0) {
                LocationIDList.push(value);
              }
            });
          }
          let ShipmentIDList = [];
          if (e.ShipmentIDSelect && e.ShipmentIDSelect.length > 0) {
            e.ShipmentIDSelect.map((item) => {
              let value = item;
              if (item.value) {
                value = item.value;
              }
              if (ShipmentIDList.indexOf(value) < 0) {
                ShipmentIDList.push(value);
              }
            });
          }
          let DestinationIDList = [];
          if (e.DestinationIDSelect && e.DestinationIDSelect.length > 0) {
            e.DestinationIDSelect.map((item) => {
              let value = item;
              if (item.value) {
                value = item.value;
              }
              if (DestinationIDList.indexOf(value) < 0) {
                DestinationIDList.push(value);
              }
            });
          }
          let dataSend = {
            LocationID: LocationIDList,
            DestinationID: DestinationIDList,
            ShipmentID: ShipmentIDList,
            Activity: e.Activity,
            AppointmentTimeFrom: e.AppointmentTimeFrom,
            AppointmentTimeTo: e.AppointmentTimeTo,
            ShipmentEndDateFrom: e.ShipmentEndDateFrom,
            ShipmentEndDateTo: e.ShipmentEndDateTo,
            ShipmentStartDateFrom: e.ShipmentStartDateFrom,
            ShipmentStartDateTo: e.ShipmentStartDateTo,
          };
          if (getExcelStatus == "IDLE") {
            dispatch(getExcel(dataSend));
          }
        }
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Склад погрузки"
        name="LocationIDSelect"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchWarehouse) {
                clearTimeout(timeoutSearchWarehouse);
                timeoutSearchWarehouse = null;
              }

              timeoutSearchWarehouse = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchWarehouseStatus == "IDLE") {
                    dispatch(exportFormSearchWarehouse({ code: newValue }));
                  }
                } else {
                  setDataWarehouses([]);
                }
              }, 300);
            } else {
              setDataWarehouses([]);
            }
          }}
          onChange={(e) => {
            // console.log(e);
            setTimeout(function () {
              setDataWarehouses([]);
              dispatch(exportFormSearchWarehouseIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchWarehouseStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataWarehouses || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={locations}
          onChange={(e) => {
            setLocations(e.target.value);
          }}
          rows={4}
          placeholder="Введите код склада с новой строки"
        />
        <Button
          onClick={(e) => {
            let listLocations = [];

            if (
              locations && locations.length > 0 &&
              locations.split(/\r?\n|\r|\n/g).length > 0
            ) {
              locations.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  // listLocations.push(item.replace(' ', ''));
                  // listLocations.push({
                  //     '%NAME': item.replace(' ', '')
                  // });
                  listLocations.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchWarehouse({ list: listLocations }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>

      <Form.Item
        label="Номер рейса"
        name="ShipmentIDSelect"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchBooking) {
                clearTimeout(timeoutSearchBooking);
                timeoutSearchBooking = null;
              }

              timeoutSearchBooking = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchBookingStatus == "IDLE") {
                    dispatch(exportFormSearchBooking({ code: newValue }));
                  }
                } else {
                  setDataBookings([]);
                }
              }, 300);
            } else {
              setDataBookings([]);
            }
          }}
          onChange={(newValue) => {
            setTimeout(function () {
              setDataBookings([]);
              dispatch(exportFormSearchBookingIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchBookingStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataBookings || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={shipments}
          onChange={(e) => {
            setShipments(e.target.value);
          }}
          rows={4}
          placeholder="Введите код рейса с новой строки"
        />
        <Button
          onClick={(e) => {
            let listShipments = [];

            if (
              shipments && shipments.length > 0 &&
              shipments.split(/\r?\n|\r|\n/g).length > 0
            ) {
              shipments.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  listShipments.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchBooking({ list: listShipments }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>

      <Form.Item
        name="ShipmentStartDateFrom"
        hidden={true}
      />
      <Form.Item
        name="ShipmentStartDateTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Плановая дата погрузки С"
            name="PlanovayaDatePogruzkiS"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentStartDateFrom", dateString);
                // console.log(date, dateString)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Плановая дата погрузки ПО"
            name="PlanovayaDatePogruzkiPo"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentStartDateTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="AppointmentTimeFrom"
        hidden={true}
      />
      <Form.Item
        name="AppointmentTimeTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <>
                Дата погрузки в ДокБукинге С <br />(Appointment time FROM)
              </>
            }
            name="DatePogruzkiVDokBukingeS"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("AppointmentTimeFrom", dateString);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <>
                Дата погрузки в ДокБукинге ПО <br />(Appointment time TO)
              </>
            }
            name="DatePogruzkiVDokBukingePo"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("AppointmentTimeTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Погрузка/Выгрузка"
        name="Activity"
      >
        <Select
          allowClear
          mode="multiple"
          placeholder="P, D, PD"
          onChange={(e) => {
            // console.log(e);
          }}
          options={[
            { value: "1", label: "P" },
            { value: "2", label: "D" },
            { value: "3", label: "PD" },
          ]}
        />
      </Form.Item>
      <Form.Item
        name="ShipmentEndDateFrom"
        hidden={true}
      />
      <Form.Item
        name="ShipmentEndDateTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Дата доставки С"
            name="DateDostavkiS"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentEndDateFrom", dateString);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Дата доставки ПО"
            name="DateDostavkiPo"
          >
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentEndDateTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Код точки доставки"
        name="DestinationIDSelect"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchDestination) {
                clearTimeout(timeoutSearchDestination);
                timeoutSearchDestination = null;
              }

              timeoutSearchDestination = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchDestinationStatus == "IDLE") {
                    dispatch(exportFormSearchDestination({ code: newValue }));
                  }
                } else {
                  setDataDestinations([]);
                }
              }, 300);
            } else {
              setDataDestinations([]);
            }
          }}
          onChange={(newValue) => {
            setTimeout(function () {
              setDataDestinations([]);
              dispatch(exportFormSearchDestinationIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchDestinationStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataDestinations || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={destinations}
          onChange={(e) => {
            setDestinations(e.target.value);
          }}
          rows={4}
          placeholder="Введите код точки с новой строки"
        />
        <Button
          onClick={(e) => {
            let listDestinations = [];

            if (
              destinations && destinations.length > 0 &&
              destinations.split(/\r?\n|\r|\n/g).length > 0
            ) {
              destinations.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  listDestinations.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchDestination({ list: listDestinations }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>
      <Form.Item>
        <Space size={8}>
          <Button htmlType="submit" type="primary">Выгрузить</Button>
          <Button
            htmlType="button"
            onClick={(e) => {
              formExport.resetFields();
            }}
          >
            Очистить
          </Button>
          <Button
            htmlType="button"
            onClick={(e) => {
              setView("warehouses");
            }}
          >
            Закрыть
          </Button>
        </Space>
        {getExcelStatus == "LOADING" && <Spin />}
        {getExcelStatus !== "LOADING" && getExcelResult &&
            getExcelResult.result && getExcelResult.result == "ok" && (
              <a
                target="_blank"
                href={`https://${domainMain}/${getExcelResult.file_url}`}
              >
                ULU_DOCK_REPORT
              </a>
            ) ||
          getExcelResult && getExcelResult.error_message &&
            getExcelResult.error_message == "no data" && "Нет данных" ||
          ""}
      </Form.Item>
    </Form>
  );
};

export default ExportForm;
