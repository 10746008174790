import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    exportFormSearchWarehouse,
    exportFormSearchWarehouseSuccess,
    exportFormSearchWarehouseFailure,
    exportFormSearchWarehouseIdle,
    exportFormSearchWarehouseFind,
    exportFormSearchBooking,
    exportFormSearchBookingSuccess,
    exportFormSearchBookingFailure,
    exportFormSearchBookingIdle,
    exportFormSearchBookingFind,
    exportFormSearchDestination,
    exportFormSearchDestinationSuccess,
    exportFormSearchDestinationFailure,
    exportFormSearchDestinationIdle,
    exportFormSearchDestinationFind,
    getExcel,
    getExcelSuccess,
    getExcelFailure,
    getExcelIdle
} from "./actions";

const exportFormSearchWarehouseResult = handleActions(
    {
        [exportFormSearchWarehouseSuccess]: (state, action) => action.payload,
        [exportFormSearchWarehouseFind]: (state, action) => action.payload,
    },
    []
);

const exportFormSearchWarehouseStatus = handleActions(
    {
        [exportFormSearchWarehouseSuccess]: () => "SUCCESS",
        [exportFormSearchWarehouseFind]: () => "FIND",
        [exportFormSearchWarehouse]: () => "LOADING",
        [exportFormSearchWarehouseFailure]: () => "FAILURE",
        [exportFormSearchWarehouseIdle]: () => "IDLE",
    },
    'IDLE'
);

const exportFormSearchBookingResult = handleActions(
    {
        [exportFormSearchBookingSuccess]: (state, action) => action.payload,
        [exportFormSearchBookingFind]: (state, action) => action.payload,
    },
    []
);

const exportFormSearchBookingStatus = handleActions(
    {
        [exportFormSearchBookingSuccess]: () => "SUCCESS",
        [exportFormSearchBookingFind]: () => "FIND",
        [exportFormSearchBooking]: () => "LOADING",
        [exportFormSearchBookingFailure]: () => "FAILURE",
        [exportFormSearchBookingIdle]: () => "IDLE",
    },
    'IDLE'
);

const exportFormSearchDestinationResult = handleActions(
    {
        [exportFormSearchDestinationSuccess]: (state, action) => action.payload,
        [exportFormSearchDestinationFind]: (state, action) => action.payload,
    },
    []
);

const exportFormSearchDestinationStatus = handleActions(
    {
        [exportFormSearchDestinationSuccess]: () => "SUCCESS",
        [exportFormSearchDestinationFind]: () => "FIND",
        [exportFormSearchDestination]: () => "LOADING",
        [exportFormSearchDestinationFailure]: () => "FAILURE",
        [exportFormSearchDestinationIdle]: () => "IDLE",
    },
    'IDLE'
);

const getExcelStatus = handleActions(
    {
        [getExcelSuccess]: () => "SUCCESS",
        [getExcel]: () => "LOADING",
        [getExcelFailure]: () => "FAILURE",
        [getExcelIdle]: () => "IDLE",
    },
    'IDLE'
);

const getExcelResult = handleActions(
    {
        [getExcelSuccess]: (state, action) => action.payload,
    },
    []
);

const exportForm = combineReducers({
    exportFormSearchWarehouseResult,
    exportFormSearchWarehouseStatus,
    exportFormSearchBookingResult,
    exportFormSearchBookingStatus,
    exportFormSearchDestinationResult,
    exportFormSearchDestinationStatus,
    getExcelStatus,
    getExcelResult
});

export {exportForm};