import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    handleAuthAppSuccess,
    handleAuthApp,
    handleAuthAppFailure,
    handleAuthAppIdle,
    handleAuthAppInit,
    setAuthApp
} from "./actions";

const status = handleActions(
    {
        [handleAuthAppSuccess]: () => "SUCCESS",
        [handleAuthApp]: () => "LOADING",
        [handleAuthAppFailure]: () => "FAILURE",
        [handleAuthAppIdle]: () => "IDLE",
        [setAuthApp]: () => 'SUCCESS'
    },
    'IDLE'
);

const response = handleActions(
    {
        [handleAuthAppSuccess]: (state, action) => action.payload,
        [handleAuthAppFailure]: (state, action) => action.payload,
        [setAuthApp]: (state, action) => action.payload,
    },
    null
)

const init = handleActions(
    {
        [handleAuthAppInit]: (state, action) => action.payload,
    },
    null
)

const typeUser = handleActions(
    {
        [handleAuthAppInit]: (state, action) => {
            let type = 'isCarrier';
            if(action.payload && action.payload.group && action.payload.group == 'user_ul'){
                type = 'isUniliver'
            } else if(action.payload && action.payload.group && action.payload.group == 'user_view'){
                type = 'isView';
            }
            return type;
        },
    },
    null
)

const authApp = combineReducers({
    status,
    response,
    init,
    typeUser
});

export {authApp};
