import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import {
    getListCodReguest,
    searchFilterReguest,
    addCodInfoReguest,
    getLogReguest,
    addLogReguest,
    updateLogReguest,
    updateShipmentReguest,
    getShipmentReguest,
    getShipmentByIDAndLocationReguest
} from './api'

import {
    getListCod,
    getListCodSuccess,
    getListCodFailure,
    getListCodIdle,
    searchFilter,
    searchFilterSuccess,
    searchFilterFailure,
    searchFilterIdle,
    addCodInfo,
    addCodInfoSuccess,
    addCodInfoFailure,
    addCodInfoIdle,
    addLog,
    addLogSuccess,
    addLogFailure,
    addLogIdle,
    addLogDataSuccess
} from './actions'
import { fieldsLogs, fieldsShipment, getValueProperty } from "../../constants/config";

function* addLogSaga(action){
    try {

        const { payload } = action;

        // console.log(payload);

        let log = yield call(getLogReguest, {
            code: payload.logProps.ELEMENT_CODE
        });

        // let shipment = yield call(getShipmentReguest, {
        //     id: payload.shipmentProps.bxDeliveryId
        // });

        //console.log(payload);

        let propsShipment = {
            [fieldsShipment.PROPERTY_725.code]: payload.shipmentProps[fieldsShipment.PROPERTY_725.code],
            [fieldsShipment.PROPERTY_726.code]: payload.shipmentProps[fieldsShipment.PROPERTY_726.code],
            [fieldsShipment.PROPERTY_727.code]: payload.shipmentProps[fieldsShipment.PROPERTY_727.code],
            [fieldsShipment.PROPERTY_728.code]: payload.shipmentProps[fieldsShipment.PROPERTY_728.code],
            [fieldsShipment.PROPERTY_729.code]: payload.shipmentProps[fieldsShipment.PROPERTY_729.code],
        };

        let shipments = yield call(getShipmentByIDAndLocationReguest, {
            id: payload.shipmentPropsAll['Shipment'], 
            location: payload.shipmentPropsAll['Ship-to']
        })

        //console.log(propsShipment);

        yield all(shipments.result.map((item, index) => {
            return call(updateShipmentReguest, {
                'ELEMENT_ID': item.ID,
                'FIELDS': {
                    ...item,
                    ...propsShipment
                }
            })
        }))


        if(log && log.result && log.result.length > 0){
            yield call(updateLogReguest, {
                'ELEMENT_ID': log.result[0].ID,
                'FIELDS': {
                    ...log.result[0],
                    [fieldsLogs.PROPERTY_721.code]: payload.logProps.FIELDS[fieldsLogs.PROPERTY_721.code],
                    [fieldsLogs.PROPERTY_722.code]: payload.logProps.FIELDS[fieldsLogs.PROPERTY_722.code],
                    [fieldsLogs.PROPERTY_723.code]: payload.logProps.FIELDS[fieldsLogs.PROPERTY_723.code],
                    [fieldsLogs.PROPERTY_724.code]: payload.logProps.FIELDS[fieldsLogs.PROPERTY_724.code]
                }
            })
        } else {
            yield call(addLogReguest, payload.logProps);
        }

        yield put(addLogDataSuccess({
            bxID: payload.shipmentProps.bxDeliveryId,
            props: {
                ['Cod Event Date']: payload.shipmentProps[fieldsShipment.PROPERTY_725.code],
                ['Cod Event Time']: payload.shipmentProps[fieldsShipment.PROPERTY_726.code],
                ['Truck Departure Date']: payload.shipmentProps[fieldsShipment.PROPERTY_727.code],
                ['Truck Departure Time']: payload.shipmentProps[fieldsShipment.PROPERTY_728.code],
                ['Comments']: payload.shipmentProps[fieldsShipment.PROPERTY_729.code],
                ['Modified by']: payload.logProps.FIELDS[fieldsLogs.PROPERTY_722.code],
                ['Modyfying date']: payload.logProps.FIELDS[fieldsLogs.PROPERTY_723.code] + ' ' + payload.logProps.FIELDS[fieldsLogs.PROPERTY_724.code]
            }
        }))

        yield put(addLogSuccess(true));

    } catch (error){

        yield put(addLogFailure(error));

    }
}

function* addCodInfoSaga(action){
    try {

        const { payload } = action;

        let response = yield call(addCodInfoReguest, payload);

        yield put(addCodInfoSuccess(response));

    } catch (error){

        yield put(addCodInfoFailure(error));

    }
}

function* getListCodSaga(action) {
    try {

        const { payload } = action;

        // console.log(payload.filters);

        const response = yield call(getListCodReguest, payload);

        yield put(getListCodSuccess(response));

    } catch (error) {

        yield put(getListCodFailure(error));

    }
}

function* searchFilterSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(searchFilterReguest, payload);

        // console.log(response);

        yield put(searchFilterSuccess(response));

    } catch (error) {

        yield put(searchFilterFailure(error));

    }
}

export function* codWatcher() {
    yield takeLatest(getListCod.toString(), getListCodSaga);
    yield takeLatest(searchFilter.toString(), searchFilterSaga);
    yield takeLatest(addCodInfo.toString(), addCodInfoSaga);
    yield takeLatest(addLog.toString(), addLogSaga);
}