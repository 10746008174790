import {createActions} from "redux-actions";

const {
    getListRequests,
    getListRequestsSuccess,
    getListRequestsFailure,
    getListRequestsIdle,
    getListRequestsClear,
    getItemRequests,
    getItemRequestsSuccess,
    getItemRequestsFailure,
    getItemRequestsIdle,
    getItemRequestsClear,
    addItemRequests,
    addItemRequestsSuccess,
    addItemRequestsFailure,
    addItemRequestsIdle,
    updateItemRequests,
    updateItemRequestsSuccess,
    updateItemRequestsFailure,
    updateItemRequestsIdle,
    setRequestsIds
} = createActions(
    "GET_LIST_REQUESTS",
    "GET_LIST_REQUESTS_SUCCESS",
    "GET_LIST_REQUESTS_FAILURE",
    "GET_LIST_REQUESTS_IDLE",
    "GET_LIST_REQUESTS_CLEAR",
    "GET_ITEM_REQUESTS",
    "GET_ITEM_REQUESTS_SUCCESS",
    "GET_ITEM_REQUESTS_FAILURE",
    "GET_ITEM_REQUESTS_IDLE",
    "GET_ITEM_REQUESTS_CLEAR",
    "ADD_ITEM_REQUESTS",
    "ADD_ITEM_REQUESTS_SUCCESS",
    "ADD_ITEM_REQUESTS_FAILURE",
    "ADD_ITEM_REQUESTS_IDLE",
    "UPDATE_ITEM_REQUESTS",
    "UPDATE_ITEM_REQUESTS_SUCCESS",
    "UPDATE_ITEM_REQUESTS_FAILURE",
    "UPDATE_ITEM_REQUESTS_IDLE",
    "SET_REQUESTS_IDS"
)

export {
    getListRequests,
    getListRequestsSuccess,
    getListRequestsFailure,
    getListRequestsIdle,
    getListRequestsClear,
    getItemRequests,
    getItemRequestsSuccess,
    getItemRequestsFailure,
    getItemRequestsIdle,
    getItemRequestsClear,
    addItemRequests,
    addItemRequestsSuccess,
    addItemRequestsFailure,
    addItemRequestsIdle,
    updateItemRequests,
    updateItemRequestsSuccess,
    updateItemRequestsFailure,
    updateItemRequestsIdle,
    setRequestsIds
}