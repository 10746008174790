import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getRequests } from "../../../../store/ducks/requests/actions";
// import { selectRequests, selectRequestsHome, selectRequestsHomeConfirm } from "../../../../store/ducks/requests/selector";
import LoadMore from "../../../../components/loadMore";
import cx from "classnames";
import { Link } from "react-router-dom"
import iconExternalLink from '../../../../../assets/img/icons/icon_external-link.png';
import { requests, requestsHome, requestsConfirm } from "../../constants";
import { requestsSelector } from "../../../../../modules/requests/selector";
import { setRequestsIds } from "../../../../../modules/requests/actions";

const ListRequests = ({ home, confirmation }) => {

  const dispatch = useDispatch();
  // const filter: object = useSelector((state: State) => state.filter.data);
  const filter = {};
  // const requests: SelectedRequests[] | [] = useSelector(selectRequests);
  // const requestsHome: SelectedRequests[] | [] = useSelector(selectRequestsHome);
  // const requestsConfirm: SelectedRequests[] | [] = useSelector(selectRequestsHomeConfirm);
  // const next: string = useSelector((state: State) => state.requests.next);
  const next = null;
  const [RequestsItems, setRequestsItems] = React.useState([]);
  const dataUser = useSelector(state => state.authApp.init);
  const requestsList = useSelector(requestsSelector);

  React.useEffect(() => {
    if (dataUser && dataUser.id) {
      // dispatch(getRequests({filter, next: "", userId: currentUser.id, home, confirmation}));
    }
  }, [filter, dataUser]);

  const loadMore = (e) => {
    e.preventDefault();
    // dispatch(getRequests({filter, next: next, userId: currentUser.id}));
  }

  const check = (e, item) => {
    const checked = e.target.checked;
    if (checked) {
      setRequestsItems([...RequestsItems, item.ID]);
      // setItemsExport([...itemsExport, {
      //   "Vendor code/Код поставщика": item.ServiceProviderCode,
      //   "Vendor name/ Наименование поставщика": item.ServiceProviderName,
      //   "Shipment": item.ShipmentNumber,
      //   "PO": item.POnumber,
      //   "Дата РО": item.POdate,
      //   "Invoice number/ Номер счет-фактуры": "",
      //   "Invoice date/ Дата счет-фактуры": "",
      //   "Amount/ Сумма по рейсу": item.PlannedCost,
      //   "Дата отправки документов": "",
      //   "Локация, куда документы отправлены": ""
      // }]);
    } else {
      setRequestsItems(RequestsItems.filter(item => item !== item.ID));
      // setItemsExport(itemsExport.filter(item => item.PO !== item.POnumber));
    }
  }
  React.useEffect(() => {
    dispatch(setRequestsIds(RequestsItems));
  }, [RequestsItems]);

  return <div id="table_request" className="section-table section-table--po main__section-table">
  <div className="section-table__wrap-tables">
    {!home && (
      <div className="section-table__table-left">
        <div className="section-table__row-left"></div>
        {requests.length > 0 && requests.map((item, idx) => (
          <div key={idx} className={cx(
            "section-table__row-left",
            !item.View && "section-table__row-left--new"
          )}></div>
        ))}
      </div>
    )}
    <div className="section-table__table">
      <div className="section-table__row">
        <div className="section-table__col">Направление</div>
        <div className="section-table__col">Номер запроса</div>
        <div className="section-table__col">Склад</div>
        {dataUser && dataUser.group === "user_ul" && (
          <>
            <div className="section-table__col">Партнёр</div>
            <div className="section-table__col">Наименование Партнёра</div>
          </>
        )}
        <div className="section-table__col">Статус запроса</div>
        <div className="section-table__col">Тип запроса</div>
        <div className="section-table__col">Номер рейса</div>
        <div className="section-table__col">Дата создания</div>
      </div>
      {!home && !confirmation && requestsList.length > 0 && requestsList.map(item => (
        <Link key={item.ID} to={`/requestItem/${item.ID}`} className="section-table__row">
          <div className="section-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Входящий</span>
              </span>
            )}
            {dataUser && dataUser.id === item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Исходящий</span>
              </span>
            )}
          </div>
          <div className="section-table__col">{item.ID}</div>
          <div className="section-table__col">{item.Warehouse}</div>
          {dataUser && dataUser.group === "user_ul" && (
            <>
              <div className="section-table__col">{item.Partner}</div>
              <div className="section-table__col">{item.PartnerName}</div>
            </>
          )}
          <div className="section-table__col">{item.Status}</div>
          <div className="section-table__col">{item.TypeRequest}</div>
          <div className="section-table__col">{item.FlightNumber}</div>
          <div className="section-table__col">{item.Created}</div>
        </Link>
      ))}
      {home && !confirmation && requestsHome.length > 0 && requestsHome.map(item => (
        <Link key={item.ID} to={`/requestItem/${item.ID}`} className="section-table__row">
          <div className="section-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Входящий</span>
              </span>
            )}
            {dataUser && dataUser.id === item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Исходящий</span>
              </span>
            )}
          </div>
          <div className="section-table__col">{item.ID}</div>
          <div className="section-table__col">{item.Warehouse}</div>
          {dataUser && dataUser.group === "user_ul" && (
            <div className="section-table__col">{item.Partner}</div>
          )}
          <div className="section-table__col">{item.Status}</div>
          <div className="section-table__col">{item.TypeRequest}</div>
          <div className="section-table__col">{item.FlightNumber}</div>
          <div className="section-table__col">{item.Created}</div>
        </Link>
      ))}
      {home && confirmation && requestsConfirm.length > 0 && requestsConfirm.map(item => (
        <Link key={item.ID} to={`/requestItem/${item.ID}`} className="section-table__row">
          <div className="section-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Входящий</span>
              </span>
            )}
            {dataUser && dataUser.id === item.Author && (
              <span className="wrapper-link-new-window">
                <img className="img-link-new-window" src={iconExternalLink} />
                <span>Исходящий</span>
              </span>
            )}
          </div>
          <div className="section-table__col">{item.ID}</div>
          <div className="section-table__col">{item.Warehouse}</div>
          {dataUser && dataUser.group === "user_ul" && (
            <div className="section-table__col">{item.Partner}</div>
          )}
          <div className="section-table__col">{item.Status}</div>
          <div className="section-table__col">{item.TypeRequest}</div>
          <div className="section-table__col">{item.FlightNumber}</div>
          <div className="section-table__col">{item.Created}</div>
        </Link>
      ))}
    </div>
    {!home && (
      <div className="section-table__table-right">
        <div className="section-table__row-right"></div>
      </div>
    )}
  </div>
  <p className="section-table__text">Используйте scroll bar, чтобы увидеть больше данных</p>
  {!home && next && (
    <LoadMore loadMore={loadMore} />
  )}
</div>;
};
export default ListRequests;
