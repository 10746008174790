import * as React from "react";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import { DocumentsSelectProvide } from "../../constants";
import BtnsForm from '../btnsForm';
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";

const TransportDocumentsForm = ({ setSendData, requestItem, listFlightNumber, autocomplete }) => {

  const dispatch = useDispatch();
  // const defaultData: SelectedInitData[] | [] = useSelector(selectInitData);
  const defaultData = [];
  const dataUser = useSelector(state => state.authApp.init);
  const [other, setOther] = React.useState(false);
  const warehouses = useSelector(warehousesOptionsSelector);
  // const [warehouses, setWarehouses] = React.useState(warehousesOptions);
  const [error, setError] = React.useState(false);

  console.log(requestItem);
  const { control, handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: null,
  });
  React.useEffect(() => {
    console.log(requestItem);
    reset(requestItem);
  }, [requestItem])


  const onSubmit = data => {
    console.log(data);
    setSendData({ ...data, ...{ TypeRequest: 'Запрос документов транспортных' } });
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "60px",
    })
  };

  React.useEffect(() => {
    if (defaultData.length === 0) {
      // dispatch(initData());
    }
  }, []);

  // React.useEffect(() => {
  //   if (watch('DocumentsNeedProvide') && watch('DocumentsNeedProvide').length > 0 && watch('DocumentsNeedProvide').some((element) => element.value === 'Другое')) {
  //     setOther(true);
  //   } else {
  //     setOther(false);
  //   }
  // }, [watch('DocumentsNeedProvide')]);

  // React.useEffect(() => {
  //   if (watch('Partner') && watch('Partner').meta && watch('Partner').meta.length > 0) {
  //     setWarehouses(watch('Partner').meta);
  //   }
  // }, [watch('Partner')])

  React.useEffect(() => {
    if (watch("FlightNumber") && watch("FlightNumber")['value']) {
      const flightNumber = watch("FlightNumber")['value'];
      const obj = autocomplete.find(el => el.FlightNumber === flightNumber);
      setValue("Warehouse", obj.Warehouse);
      let partnerObj = {
        label: "",
        value: [],
        meta: []
      };
      let partnerEmpty = true;
      const { partners } = dataUser;
      partners && partners.length > 0 && partners.map((partner) => {
        const { meta } = partner;
        meta.map((item) => {
          if (item.label === obj.Warehouse.label) {
            partnerObj = partner;
            partnerEmpty = false;
          }
        })
      });
      if (!partnerEmpty) {
        setValue("Partner", partnerObj);
      }
    }
  }, [watch("FlightNumber")])

  React.useEffect(() => {
    if (watch('Warehouse')) {
      if (warehouses.length > 0 && !warehouses.some(elem => elem.value === watch('Warehouse')['value'])) {
        setError(true)
      } else {
        setError(false)
      }
    }
  }, [watch('Partner'), watch('Warehouse'), watch('FlightNumber'), warehouses]);

  return <div className="block-tabs__block active">
    <form onSubmit={handleSubmit(onSubmit)} className="form form--request form--transport-doc block-tabs__block__form" action="#">
      <div className="form__container">
        <div className="form__row">
          <div className="form__col">
            <label className={errors.FlightNumber && "label__form__input error" || "label__form__input"}>Номер рейса {errors.FlightNumber && "*"}</label>
            {listFlightNumber.length > 0 && (
              <Controller
                name="FlightNumber"
                control={control}
                rules={{ 
                  required: true 
                }}
                render={({ field }) => <Select
                  value={field.value || ""}
                  styles={customStyles}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Номер рейса"
                  options={listFlightNumber}
                />}
              />
            ) || (
                <input {...register('FlightNumber', { 
                  required: true 
                })} className="input form__input" type="text" placeholder="Номер рейса" />
              )}
          </div>
        </div>
        <div className="form__row">
          <div className="form__col">
            <label className={errors.Partner && "label__form__input error" || "label__form__input"}>Партнёр {errors.Partner && "*"}</label>
            <input {...register('Partner', { 
                required: true 
              })} className="input form__input" type="text" placeholder="Партнер" />
            {/* <Controller
              name="Partner"
              control={control}
              rules={{ 
                // required: true 
              }}
              render={({ field }) => <Select
                value={field.value || ""}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Партнёр"
                options={[]}
              />}
            /> */}
          </div>
        </div>
        <div className="form__row">
          <div className="form__col">
            <label className={(errors.Warehouse || error) && "label__form__input error" || "label__form__input"}>Склад отгрузки {(errors.Warehouse || error) && "*"}</label>
            <Controller
              name="Warehouse"
              control={control}
              rules={{ 
                required: true 
              }}
              render={({ field }) => <Select
                value={field.value}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Склад отгрузки"
                options={warehouses}
              />}
            />
          </div>
        </div>
        <div className="form__row">
          <div className="form__col">
            <label className={errors.DocumentsNeedProvide && "label__form__input error" || "label__form__input"}>Какие документы нужно предоставить? {errors.DocumentsNeedProvide && "*"}</label>
            <Controller
              name="DocumentsNeedProvide"
              control={control}
              rules={{ 
                required: true 
              }}
              render={({ field }) => <Select
                value={field.value}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Какие документы нужно предоставить?"
                isMulti={true}
                options={DocumentsSelectProvide}
              />}
            />
          </div>
        </div>
        {other && (
          <div className="form__row">
            <div className="form__col">
              <label className={errors.Other && "label__form__input error" || "label__form__input"}>Название документов {errors.Other && "*"}</label>
              <textarea {...register('Other', { required: true })} className="input form__textarea" placeholder="Название документов"></textarea>
            </div>
          </div>
        )}
      </div>
      <div className="form__container">
        <BtnsForm error={error} id={requestItem && requestItem.ID} setValue={setValue} />
      </div>
    </form>
  </div>;
};
export default TransportDocumentsForm;
