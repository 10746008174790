import * as React from "react";
import { Link } from "react-router-dom";


const BtnsForm = ({ setValue, id, error }) => {

    const changeStatusForm = (e, status) => {
      setValue('Status', status);
    }

  return <div className="form__row btnsForm">
  {!id && (
    <>
      <div className="form__col">
        <button type="submit" disabled={error && true || false} onClick={(e) => changeStatusForm(e, 'Отправлен')} className="btn btn--blue form__btn">Отправить</button>
      </div>
      <div className="form__col">
        <button type="submit" disabled={error && true || false} onClick={(e) => changeStatusForm(e, 'Черновик')} className="btn form__btn">Создать черновик</button>
      </div>
    </>
  ) || (
      <div className="form__col">
        <button type="submit" disabled={error && true || false} className="btn btn--blue form__btn">Сохранить</button>
      </div>
  )}
  <div className="form__col cancel">
    <Link to={!id && `/requests` || `/requestItem/${id}`} className="btn btn--blue form__btn">Отменить</Link>
  </div>
</div>;
};
export default BtnsForm;
