import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appID, domain, getWithExpiry, setWithExpiry, timeSessionApp } from "../../constants/config";
import { handleFixDate } from "./actions";

const FixDate = function(){

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(handleFixDate());
    }, [])

    return (
        <></>    
    )
}

export default FixDate;