import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getInitData,
    getInitDataSuccess,
    getInitDataFailure,
    getInitDataIdle,
} from "./actions";

const status = handleActions(
    {
        [getInitDataSuccess]: () => "SUCCESS",
        [getInitData]: () => "LOADING",
        [getInitDataFailure]: () => "FAILURE",
        [getInitDataIdle]: () => "IDLE",
    },
    'IDLE'
);

const warehouses = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.warehouses,
    },
    {}
)

const docks = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.docks,
    },
    {}
)

const time = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.time,
    },
    {}
)

const timeList = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.timeList,
    },
    {}
)

const fieldsWarehouses = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.fieldsWarehouses,
    },
    {}
)

const fieldsDocks = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.fieldsDocks,
    },
    {}
)

const fieldsBookings = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.fieldsBookings,
    },
    {}
)

const equipments = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.equipments,
    },
    {}
)

const typeTrucks = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.typeTrucks,
    },
    {}
)

const listUsersProdider = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.listUsersProdider,
    },
    {}
)

const listLocations = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.listLocations,
    },
    {}
)

const listProvider = handleActions(
    {
        [getInitDataSuccess]: (state, action) => action.payload.listProvider,
    },
    {}
)

const initData = combineReducers({
    status,
    warehouses,
    docks,
    time,
    timeList,
    fieldsWarehouses,
    fieldsDocks,
    fieldsBookings,
    equipments,
    typeTrucks,
    listUsersProdider,
    listLocations,
    listProvider
});

export {initData};
