import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../modules/breadCrumbs";
// import { getPoItem, addPayment, changeView } from "../../store/ducks/poItem/actions";
// import { selectPOItem } from "../../store/ducks/poItem/selector";
import Chat from "../../components/chat";
// import { poItem } from "./data";
import {
  notification
} from 'antd';
import { getItemPo, getItemPoIdle, updateItemPo, updateItemPoIdle } from "../../../modules/po/actions";
import { useEffect } from "react";
import { poItemSelector } from "../../../modules/po/selector";


const POItem = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const [id, setId] = React.useState(null);
  const [breadcrumb, setBreadcrumb] = React.useState([
    {name: 'Сервисы', link: `/`},
    {name: 'PO', link: `/po`}
  ]);
  const [breadcrumbUpdate, setBreadcrumbUpdate] = React.useState(false);
  // const poItem = useSelector(selectPOItem);
  // const status = "SUCCESS";
  // const status = useSelector((state) => state.poItem.status);

  React.useEffect(() => {
    if (params.id) {
      setId(Number(params.id));
    }
  }, [params]);

  const status = useSelector(state => state.poItem.statusItem);
  const statusUpdate = useSelector(state => state.poItem.statusItemUpdate);
  const poItemDefault = useSelector(state => state.poItem.dataItem);
  const poItem = useSelector(poItemSelector);

  useEffect(() => {
    if(statusUpdate == 'SUCCESS'){
      dispatch(updateItemPoIdle());
    }
  }, [statusUpdate])

  useEffect(() => {
    if(status == "SUCCESS"){
      dispatch(getItemPoIdle());
    }
  }, [status])

  React.useEffect(() => {
    if (id) {
      if(status == 'IDLE'){
        dispatch(getItemPo({
          id
        }))
      }
    }
  }, [id])

  React.useEffect(() => {
    // console.log('poItem: ', poItem);
    if (poItem.ID && !breadcrumbUpdate) {
      setBreadcrumb([...breadcrumb, {
        name: `# ${poItem.ID}`,
        link: `/poItem/${poItem.ID}`
      }]);
      setBreadcrumbUpdate(true);
    }
    if (poItem.ID && ( !poItem.View || poItem.View == '0')) {
      if(status == "SUCCESS" && statusUpdate == 'IDLE'){
        dispatch(updateItemPo({
          props: {
            ...poItemDefault[0],
            'PROPERTY_187': '1'
          }
        }));
      }
    }
  }, [poItem])

  const addPaymentHandle = (e, id) => {
    e.preventDefault();
    if(status == "SUCCESS" && statusUpdate == 'IDLE'){
      dispatch(updateItemPo({
        props: {
          ...poItemDefault[0],
          'PROPERTY_185': '99'
        }
      }));
    }
  };

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, description, message) => {
    if (message) {
      api[type]({
        message: message,
        description
      });
    } else {
      api[type]({
        message: type == 'error' ? 'Ошибка' : 'Успешно',
        description
      });
    }
  };

  return <>{contextHolder}
  <main className="main">
  <div className="main__content container">
    <div className="main__wrap">
      <BreadCrumbs breadcrumb={breadcrumb} />
    </div>
    {status == 'LOADING' && <h1 className="section-title main__section-title">Загружаем данные...</h1> || poItem && (
      <>
        <h1 className="section-title main__section-title">{poItem.ID && `PO # ${poItem.ID}`}</h1>
        <div className="application-table main__application-table">
          <div className="application-table__wrap-tables">
            <div className="application-table__table">
              {/* <div className="application-table__row"> 
                <div className="application-table__col">Дата создания</div>
                <div className="application-table__col">{poItem.Created}</div>
              </div> */}
              <div className="application-table__row"> 
                <div className="application-table__col">Дата PO</div>
                <div className="application-table__col">{poItem.POdate}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Код склада</div>
                <div className="application-table__col">{poItem.ShippingPoint}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Код перевозчика</div>
                <div className="application-table__col">{poItem.ServiceProviderCode}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Название перевозчика</div>
                <div className="application-table__col">{poItem.ServiceProviderName}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Номер отправления</div>
                <div className="application-table__col">{poItem.ShipmentNumber}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Номер PO</div>
                <div className="application-table__col">{poItem.POnumber}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Сумма PO</div>
                <div className="application-table__col">{poItem.PlannedCost}</div>
              </div>
            </div>
            <div className="application-table__table">
              <div className="application-table__row"> 
                <div className="application-table__col">Валюта</div>
                <div className="application-table__col">{poItem.Currency}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Код получателя</div>
                <div className="application-table__col">{poItem.ShipTo}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Наименование получателя</div>
                <div className="application-table__col">{poItem.ShipToName}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Номер накладной</div>
                <div className="application-table__col">{poItem.DeliveryNumber}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Дата погрузки</div>
                <div className="application-table__col">{poItem.LoadingDate}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Тип грузового авто</div>
                <div className="application-table__col">{poItem.TruckType}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Кол-во паллетов</div>
                <div className="application-table__col">{poItem.PalletQTy}</div>
              </div>
              <div className="application-table__row"> 
                <div className="application-table__col">Статус</div>
                <div className="application-table__col">{poItem.Status}</div>
              </div>
            </div>
          </div>
          {poItem.Status === 'Новый' && (
            <button onClick={(e) => addPaymentHandle(e, poItem.ID)} className="btn btn--blue application-table__btn">Счет выставлен</button>
          )}
          {poItem.ID && <Chat openNotificationWithIcon={openNotificationWithIcon} request={false} list={'Chat'} id={poItem.ID} />}
        </div>
      </>
    )}
  </div>
  </main></>;
};
export default POItem;
