import * as React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import DatePicker, { registerLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import dayjs from 'dayjs';
// import { initData } from "../../../../store/ducks/initData/actions";
// import { getPoIdle } from "../../../../store/ducks/po/actions";
// import { changeFilter } from "../../../../store/ducks/filter/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import moment from 'moment';
import Tooltip from "rc-tooltip";
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";
import { getListPo, getListPoClear } from "../../../../../modules/po/actions";
registerLocale('ru', ru);

const selectStyles = {
  singleValue: styles => ({ ...styles, lineHeight: '18px' }),
  container: styles => ({...styles, maxWidth: '250px', width: '250px'}),
};

const Filter = (props) => {

  const dispatch = useDispatch();
  const dataUser = useSelector(state => state.authApp.init);
  const defaultData = [];
  const [startDateCreated, setStartDateCreated] = React.useState(null);
  const [endDateCreated, setEndDateCreated] = React.useState(null);
  const [startDatePOdate, setStartDatePOdate] = React.useState(null);
  const [endDatePOdate, setEndDatePOdate] = React.useState(null);
  const [startDateLoadingDate, setStartDateLoadingDate] = React.useState(null);
  const [endDateLoadingDate, setEndDateLoadingDate] = React.useState(null);
  const { openNotificationWithIcon } = props;

  const { control, handleSubmit, register, reset, setValue } = useForm();
  const [localFilterData, setLocalFilterData] = React.useState({
    po: null,
    requests: null
  });
  const status = useSelector(state => state.po.status);
  const localFilter = localStorage.getItem('filter');

  React.useEffect(() => {
    if (localFilter) {
      setLocalFilterData(JSON.parse(localFilter));
    } else {
      localStorage.setItem("filter", JSON.stringify({
        po: null,
        requests: null
      }));
    }
  }, [localFilter]);

  React.useEffect(() => {
    const { po } = localFilterData;
    if (po) {
      setStartDateCreated(po.startDateCreated && moment(po.startDateCreated).toDate() || null);
      setEndDateCreated(po.endDateCreated && moment(po.endDateCreated).toDate() || null);
      setStartDatePOdate(po.startDatePOdate && moment(po.startDatePOdate).toDate() || null);
      setEndDatePOdate(po.endDatePOdate && moment(po.endDatePOdate).toDate() || null);
      setStartDateLoadingDate(po.startDateLoadingDate && moment(po.startDateLoadingDate).toDate() || null);
      setEndDateLoadingDate(po.endDateLoadingDate && moment(po.endDateLoadingDate).toDate() || null);
      setValue('ShippingPoint', po.ShippingPoint);
      setValue('Status', po.Status);
      setValue('TypeCost', po.TypeCost);
      setValue('ShipmentNumber', po.ShipmentNumber);
      setValue('POnumber', po.POnumber);
      setValue('PlannedCost', po.PlannedCost);
      setValue('Currency', po.Currency);
      setValue('ShipTo', po.ShipTo);
      setValue('ShipToName', po.ShipToName);
      setValue('DeliveryNumber', po.DeliveryNumber);
      setValue('TruckType', po.TruckType);
      setValue('PalletQTy', po.PalletQTy);
      if (dataUser && dataUser.group === "user_ul") {
        setValue('Partner', po.Partner);
      }
    }
  }, [localFilterData, dataUser]);

  const onChangeCreated = (dates) => {
    const [start, end] = dates;
    setStartDateCreated(start);
    setEndDateCreated(end);
  };

  const onChangePOdate = (dates) => {
    const [start, end] = dates;
    setStartDatePOdate(start);
    setEndDatePOdate(end);
  };

  const onChangeLoadingDate = (dates) => {
    const [start, end] = dates;
    setStartDateLoadingDate(start);
    setEndDateLoadingDate(end);
  };
  
  
  const onSubmit = data => {
    const filterObj = {}, localObj = {};
    data['Created'] = startDateCreated;
    data['POdate'] = startDatePOdate;
    data['LoadingDate'] = startDateLoadingDate;
    let error = false;
    Object.keys(data).map(key => {
      if (data[key]) {
        localObj[key] = data[key];
        if (key === "Created") {
          if (endDateCreated) {
            const start = moment(startDateCreated).add(12, 'hours').toDate();
            start.setUTCHours(0,0,0,0);
            const end = moment(endDateCreated).add(12, 'hours').toDate();
            end.setUTCHours(23,59,59,999);
            filterObj[key] = [start.toISOString(), end.toISOString()];
            localObj['startDateCreated'] = startDateCreated;
            localObj['endDateCreated'] = endDateCreated;
          } else {
            openNotificationWithIcon("error", "Закончите выбор даты в поле - Дата создания");
            error = true;
          }
        } else if (key === "POdate") {
          if (endDatePOdate) {
            const start = moment(startDatePOdate).add(12, 'hours').toDate();
            start.setUTCHours(0,0,0,0);
            const end = moment(endDatePOdate).add(12, 'hours').toDate();
            end.setUTCHours(23,59,59,999);
            filterObj[key] = [start.toISOString(), end.toISOString()];
            localObj['startDatePOdate'] = startDatePOdate;
            localObj['endDatePOdate'] = endDatePOdate;
          } else {
            openNotificationWithIcon("error", "Закончите выбор даты в поле - Дата PO");
            error = true;
          }
        } else if (key === "LoadingDate") {
          if (endDateLoadingDate) {
            const start = moment(startDateLoadingDate).add(12, 'hours').toDate();
            start.setUTCHours(0,0,0,0);
            const end = moment(endDateLoadingDate).add(12, 'hours').toDate();
            end.setUTCHours(23,59,59,999);
            filterObj[key] = [start.toISOString(), end.toISOString()];
            localObj['startDateLoadingDate'] = startDateLoadingDate;
            localObj['endDateLoadingDate'] = endDateLoadingDate;
          } else {
            openNotificationWithIcon("error", "Закончите выбор даты в поле - Дата погрузки");
            error = true;
          }
        } else if (Array.isArray(data[key])) {
          let arrValues = [];
          data[key].map(item => {
            arrValues = [...arrValues, item.value];
          });
          filterObj[key] = arrValues;
        } else if (typeof data[key] === "object") {
          if (key !== 'Partner') {
            filterObj[key] = data[key].value;
          } else {
            filterObj[key] = data[key].partnerKey;
          }
        } else {
          filterObj[key] = data[key];
        }
      }
    });
    localStorage.setItem("filter", JSON.stringify({...localFilterData, po: localObj}));
    if (!error) {
      if(status == 'IDLE'){
        let filterProperty = {};

        if(filterObj.ShippingPoint){
          filterProperty['PROPERTY_157'] = filterObj.ShippingPoint;
        }
        if(filterObj.Status){
          filterProperty['PROPERTY_185'] = filterObj.Status;
        }
        if(filterObj.Created){
          filterProperty['>PROPERTY_746'] = dayjs(filterObj.Created[0]).format('DD.MM.YYYY');
          filterProperty['<PROPERTY_746'] = dayjs(filterObj.Created[1]).format('DD.MM.YYYY');
        }
        if(filterObj.TypeCost){
          filterProperty['PROPERTY_752'] = filterObj.TypeCost;
        }
        if(filterObj.ShipmentNumber){
          let shipmentNumbers = filterObj.ShipmentNumber.replaceAll(' ', '');
          filterProperty['PROPERTY_163'] = shipmentNumbers.split(',');
        }
        if(filterObj.POnumber){
          filterProperty['PROPERTY_165'] = filterObj.POnumber;
        }
        if(filterObj.POdate){
          filterProperty['>PROPERTY_167'] = dayjs(filterObj.POdate[0]).format('DD.MM.YYYY');
          filterProperty['<PROPERTY_167'] = dayjs(filterObj.POdate[1]).format('DD.MM.YYYY');
        }
        if(filterObj.PlannedCost){
          filterProperty['PROPERTY_169'] = filterObj.PlannedCost;
        }
        if(filterObj.Currency){
          filterProperty['PROPERTY_171'] = filterObj.Currency;
        }
        if(filterObj.ShipTo){
          filterProperty['PROPERTY_173'] = filterObj.ShipTo;
        }
        if(filterObj.ShipToName){
          filterProperty['PROPERTY_175'] = filterObj.ShipToName;
        }
        if(filterObj.DeliveryNumber){
          filterProperty['PROPERTY_177'] = filterObj.DeliveryNumber;
        }
        if(filterObj.LoadingDate){
          filterProperty['>PROPERTY_179'] = dayjs(filterObj.LoadingDate[0]).format('DD.MM.YYYY');
          filterProperty['<PROPERTY_179'] = dayjs(filterObj.LoadingDate[1]).format('DD.MM.YYYY');
        }
        if(filterObj.TruckType){
          filterProperty['PROPERTY_745'] = filterObj.TruckType;
        }
        if(filterObj.PalletQTy){
          filterProperty['PROPERTY_183'] = filterObj.PalletQTy;
        }

        dispatch(getListPoClear());
        dispatch(getListPo({
          filters: filterProperty
        }))
      }
      console.log('po_filterObj: ', filterObj);
      // dispatch(getPoIdle());
      // dispatch(changeFilter(filterObj));
    }
  };

  const clearFilterHandler = (e) => {
    e.preventDefault();
    
    dispatch(getListPoClear());
    dispatch(getListPo({}));

    setStartDateCreated(null);
    setEndDateCreated(null);
    setStartDatePOdate(null);
    setEndDatePOdate(null);
    setStartDateLoadingDate(null);
    setEndDateLoadingDate(null);
    reset();
    localStorage.setItem("filter", JSON.stringify({...localFilterData, po: null}));
    const form = document.querySelector("#filterPO");
    if (form) {
      form.reset();
    }
  };

  // React.useEffect(() => {
  //   dispatch(initData());
  // }, []);

  const warehousesOptions = useSelector(warehousesOptionsSelector);
  const truckTypesOptions = useSelector(state => state.initData.typeTrucks.selectOptions);

  return <form id="filterPO" onSubmit={handleSubmit(onSubmit)} className="po-filters main__po-filters form">
  <div className="po-filters__row">
    <div className="po-filters__col"> 
      <Controller
        name="ShippingPoint"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          isMulti={true}
          onChange={(value) => field.onChange(value)}
          placeholder="Номер склада" 
          options={warehousesOptions} 
        />}
      />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="Status"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          isMulti={false}
          onChange={(value) => field.onChange(value)}
          placeholder="Статус" 
          options={[
            {value: 97, label: "Новый"},
            {value: 99, label: "Счет выставлен"}
          ]} 
        />}
      />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="TypeCost"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          isMulti={false}
          onChange={(value) => field.onChange(value)}
          placeholder="Тип коста" 
          options={[
            {value: 477, label: "Основной"},
            {value: 478, label: "Дополнительный"}
          ]} 
        />}
      />
    </div>
    {/* <div className="po-filters__col">		
      <Controller
        name="Created"
        control={control}
        defaultValue={null}
        render={({ field }) =>
        <DatePicker
          dateFormat="d MMMM yyyy"
          placeholderText="Дата создания"
          selected={startDateCreated}
          className="input form__input"
          onChange={onChangeCreated}
          startDate={startDateCreated}
          endDate={endDateCreated}
          selectsRange
          locale="ru"
        />
        }
        />
    </div> */}
    {dataUser && dataUser.group === "user_ul" && (
      <div className="po-filters__col"> 
        <Controller
          name="Partner"
          control={control}
          render={({ field }) => <Select 
            value={field.value || ""}
            styles={selectStyles}
            onChange={(value) => field.onChange(value)}
            placeholder="Партнёр"
            options={[]} 
          />}
        />
      </div>
    )}
    <Tooltip placement="top" trigger={['hover']} overlay={<span>Вы можете ввести несколько значений через запятую</span>}>
      <div className="po-filters__col"> 
          <input className="input form__input" type="text" {...register('ShipmentNumber')} placeholder="Номер рейса" />
      </div>
    </Tooltip>
    <div className="po-filters__col"> 
        <input className="input form__input" type="number" {...register('POnumber')} placeholder="Номер PO" />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="POdate"
        control={control}
        render={({ field }) =>
        <DatePicker
          dateFormat="d MMMM yyyy"
          placeholderText="Дата PO"
          selected={startDatePOdate}
          className="input form__input"
          onChange={onChangePOdate}
          startDate={startDatePOdate}
          endDate={endDatePOdate}
          selectsRange
          locale="ru"
        />
        }
      />
    </div>
    <div className="po-filters__col"> 
        <input className="input form__input" type="number" {...register('PlannedCost')} placeholder="Сумма PO" />
    </div>
    {/* <div className="po-filters__col"> 
        <input className="input form__input" type="text" {...register('Currency')} placeholder="Валюта" />
    </div> */}
    <div className="po-filters__col"> 
        <input className="input form__input" type="number" {...register('ShipTo')} placeholder="Код получателя" />
    </div>
    <div className="po-filters__col"> 
        <input className="input form__input" type="text" {...register('ShipToName')} placeholder="Название получателя" />
    </div>
    <div className="po-filters__col"> 
        <input className="input form__input" type="number" {...register('DeliveryNumber')} placeholder="Номер накладной" />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="LoadingDate"
        control={control}
        render={({ field }) =>
        <DatePicker
          dateFormat="d MMMM yyyy"
          placeholderText="Дата отгрузки"
          selected={startDateLoadingDate}
          className="input form__input"
          onChange={onChangeLoadingDate}
          startDate={startDateLoadingDate}
          endDate={endDateLoadingDate}
          selectsRange
          locale="ru"
        />
        }
      />
    </div>
    <div className="po-filters__col"> 
      <Controller
        name="TruckType"
        control={control}
        render={({ field }) => <Select 
          value={field.value || ""}
          isMulti={true}
          onChange={(value) => field.onChange(value)}
          placeholder="Тип грузового авто" 
          options={truckTypesOptions} 
        />}
      />
    </div>
    {/* <div className="po-filters__col"> 
        <input className="input form__input" type="number" {...register('PalletQTy')} placeholder="Кол-во паллетов" />
    </div> */}
    <div className="po-filters__col">
      <button type="submit" className="btn btn--blue po-filters__btn">Применить</button>
    </div>
    <div className="po-filters__col">
      <button onClick={(e) => clearFilterHandler(e)} className="btn btn po-filters__btn">Сбросить фильтр</button>
    </div>
  </div>
</form>;
};
export default Filter;
