import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getMessages,
    getMessagesSuccess,
    getMessagesFailure,
    getMessagesIdle,
    getMessagesClear,
    addMessage,
    addMessageSuccess,
    addMessageFailure,
    addMessageIdle
} from './actions'

const statusMessages = handleActions(
    {
        [getMessagesSuccess]: () => "SUCCESS",
        [getMessages]: () => "LOADING",
        [getMessagesFailure]: () => "FAILURE",
        [getMessagesIdle]: () => "IDLE",
    },
    'IDLE'
);

const dataMessages = handleActions(
    {
        [getMessagesSuccess]: (state, action) => action.payload.result,
        [getMessagesFailure]: (state, action) => [],
        [getMessagesClear]: () => []
    },
    []
);

const statusAddMessage = handleActions(
    {
        [addMessageSuccess]: () => "SUCCESS",
        [addMessage]: () => "LOADING",
        [addMessageFailure]: () => "FAILURE",
        [addMessageIdle]: () => "IDLE",
    },
    'IDLE'
);

const chat = combineReducers({
    statusMessages,
    dataMessages,
    statusAddMessage
})

export { chat };