import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { 
    Button, 
    Space,
    Modal,
    Form, 
    Input,
    Select,
    Radio,
    Spin,
    Card,
    Checkbox, 
    DatePicker,
    Alert,
    notification,
    Switch,
    Popconfirm,
    Row,
    Col,
    Popover,
    Dropdown,
    TimePicker,
    theme,
    Typography,
    message
} from 'antd';

import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';

import BreadCrumbs from "../../modules/breadCrumbs";
import { addCodInfo, addCodInfoIdle } from '../../modules/cod/actions';
import { Link } from 'react-router-dom';

const { Title } = Typography;

const CodAdd = () => {

    const [formCod] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();

    const [breadcrumb, setBreadcrumb] = useState([
        { name: 'Главная', link: `/` },
        { name: 'Сервисы', link: `/` },
        { name: 'Confirmation of Delivery', link: `/cod` }
    ]);

    const dispatch = useDispatch();

    const status = useSelector(state => state.cod.addCodInfoStatus);

    useEffect(() => {
        if(status == 'SUCCESS'){
            dispatch(addCodInfoIdle());
            messageApi.open({
                type: 'success',
                content: 'Данные успешно добавлены',
            });
            formCod.resetFields();
        }
    }, [status])

    return (
        <main className="main">
            <div className="main__content container">

                <div className='main__header'>
                
                    <BreadCrumbs breadcrumb={breadcrumb} />

                </div>
                
                <div className='cod-add'>

                    <Title>Confirmation of Delivery</Title>

                    <Form
                        name="basic"
                        layout="vertical"
                        form={formCod}
                        initialValues={{
                            size: 'large',
                        }}
                        size='large'
                        onFinish={e => {
                            console.log(e);
                            if(status == 'IDLE'){
                                dispatch(addCodInfo({
                                    'ELEMENT_CODE': e['PROPERTY_665'] + '_' + e['PROPERTY_667'],
                                    'FIELDS': {...e}
                                }));
                            }
                        }}
                        style={{ maxWidth: 470 }}
                    >
                        <Form.Item
                            name="NAME"    
                            hidden
                        />
                        <Form.Item
                            name="PROPERTY_665"                            
                        >
                            <Input
                                placeholder="Shipment ID"
                                onChange={(e) => {
                                    formCod.setFieldValue('NAME', e.target.value);
                                    // console.log(date, dateString)
                                }} 
                            />
                        </Form.Item>
                        <Form.Item
                            name="PROPERTY_667"                            
                        >
                            <Input
                                placeholder="Склад отгрузки (Source location)"
                            />
                        </Form.Item>
                        <Form.Item
                            name="PROPERTY_669"    
                            hidden
                        />
                        <Form.Item
                            name="planovaya_data_dostavki_ldd"
                        >
                            <DatePicker 
                                style={{ width: '100%' }}
                                placeholder='Плановая дата доставки (LDD)'
                                locale={locale}
                                format={'DD.MM.YYYY'}
                                onChange={(date, dateString) => {
                                    formCod.setFieldValue('PROPERTY_669', dateString);
                                    // console.log(date, dateString)
                                }} 
                            />
                        </Form.Item>
                        <Form.Item 
                            name="PROPERTY_671" 
                            label="Данные COD заполнены?"
                            className="cod-add__fill"
                            initialValue="N"
                        >
                            <Radio.Group defaultValue={'N'}>
                                <Radio value={'Y'}>Да</Radio>
                                <Radio value={'N'}>Нет</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                            <Space size={16}>
                                <Button style={{
                                    background: '#1F36C7',
                                    color: '#ffffff',
                                    fontWeight: '700',
                                    borderRadius: 0,
                                    padding: 0,
                                    fontSize: 14,
                                    lineHeight: 1
                                }}>
                                    <Link style={{
                                        padding: '10px 24px',
                                        height: 39,
                                    }} to={'/cod'}>
                                        Вернуться
                                    </Link>
                                </Button>
                                <Button 
                                    style={{
                                        background: '#1F36C7',
                                        color: '#ffffff',
                                        fontWeight: '700',
                                        borderRadius: 0,
                                        fontSize: 14,
                                        padding: '10px 24px',
                                        height: 39,
                                        lineHeight: 1
                                    }} 
                                    type="primary" 
                                    htmlType="submit"
                                    disabled={status == 'LOADING' && true || false}
                                >
                                    Сохранить
                                </Button>
                                {status == 'LOADING' && (
                                    <Spin />
                                )}
                            </Space>
                        </Form.Item>
                    </Form>

                </div>

            </div>
        </main>
    )
}

export default CodAdd;