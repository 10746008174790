import {
  Alert,
  Button,
  Card,
  Col,
  DatePicker,
  Dropdown,
  Form,
  Input,
  Modal,
  notification,
  Popconfirm,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Spin,
  Switch,
  theme,
  TimePicker,
} from "antd";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { domainMain, getValueProperty } from "../../../../constants/config";
import {
  exportFormSearchBooking,
  exportFormSearchBookingIdle,
  exportFormSearchDestination,
  exportFormSearchDestinationIdle,
  exportFormSearchWarehouse,
  exportFormSearchWarehouseIdle,
  getExcel,
  getExcelIdle,
} from "../../../../modules/export/actions";

const { TextArea } = Input;

let timeoutSearchWarehouse;
let timeoutSearchBooking;
let timeoutSearchDestination;

const ExportForm = (props) => {
  const { setView } = props;

  const [locations, setLocations] = useState("");
  const [shipments, setShipments] = useState("");
  const [destinations, setDestinations] = useState("");

  const dispatch = useDispatch();

  const [formExport] = Form.useForm();

  const [dataWarehouses, setDataWarehouses] = useState([]);
  const [dataBookings, setDataBookings] = useState([]);
  const [dataDestinations, setDataDestinations] = useState([]);

  const exportFormSearchWarehouseStatus = useSelector((state) =>
    state.exportForm.exportFormSearchWarehouseStatus
  );
  const exportFormSearchWarehouseResult = useSelector((state) =>
    state.exportForm.exportFormSearchWarehouseResult
  );

  const exportFormSearchBookingStatus = useSelector((state) =>
    state.exportForm.exportFormSearchBookingStatus
  );
  const exportFormSearchBookingResult = useSelector((state) =>
    state.exportForm.exportFormSearchBookingResult
  );

  const exportFormSearchDestinationStatus = useSelector((state) =>
    state.exportForm.exportFormSearchDestinationStatus
  );
  const exportFormSearchDestinationResult = useSelector((state) =>
    state.exportForm.exportFormSearchDestinationResult
  );

  const getExcelStatus = useSelector((state) =>
    state.exportForm.getExcelStatus
  );
  const getExcelResult = useSelector((state) =>
    state.exportForm.getExcelResult
  );
  
  const typeTrucks = useSelector(state => state.initData.typeTrucks);
  const listLocations = useSelector(state => state.initData.listLocations);

  useEffect(() => {
    if (getExcelStatus == "SUCCESS") {
      dispatch(getExcelIdle());
      formExport.resetFields();
    }
    if (getExcelStatus == "FAILURE") {
      dispatch(getExcelIdle());
    }
  }, [getExcelStatus]);

  useEffect(() => {
    if (exportFormSearchWarehouseStatus == "SUCCESS") {
      setDataWarehouses(exportFormSearchWarehouseResult);

      dispatch(exportFormSearchWarehouseIdle());
    }
    if (exportFormSearchWarehouseStatus == "FIND") {
      let result = [];

      if (exportFormSearchWarehouseResult) {
        result = [
          ...result,
          ...exportFormSearchWarehouseResult,
        ];
      }
      if (
        formExport.getFieldValue("WarehouseId") &&
        formExport.getFieldValue("WarehouseId").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("WarehouseId"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("WarehouseId", result);
      }

      dispatch(exportFormSearchWarehouseIdle());
    }
  }, [exportFormSearchWarehouseStatus]);

  useEffect(() => {
    if (exportFormSearchBookingStatus == "SUCCESS") {
      dispatch(exportFormSearchBookingIdle());

      setDataBookings(exportFormSearchBookingResult);
    }
    if (exportFormSearchBookingStatus == "FIND") {
      let result = [];

      if (exportFormSearchBookingResult) {
        result = [
          ...result,
          ...exportFormSearchBookingResult,
        ];
      }
      if (
        formExport.getFieldValue("ShipmentNo") &&
        formExport.getFieldValue("ShipmentNo").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("ShipmentNo"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("ShipmentNo", result);
      }

      dispatch(exportFormSearchBookingIdle());
    }
  }, [exportFormSearchBookingStatus]);

  useEffect(() => {
    if (exportFormSearchDestinationStatus == "SUCCESS") {
      dispatch(exportFormSearchDestinationIdle());

      setDataDestinations(exportFormSearchDestinationResult);
    }
    if (exportFormSearchDestinationStatus == "FIND") {
      let result = [];

      if (exportFormSearchDestinationResult) {
        result = [
          ...result,
          ...exportFormSearchDestinationResult,
        ];
      }
      if (
        formExport.getFieldValue("DestinationIDSelect") &&
        formExport.getFieldValue("DestinationIDSelect").length > 0
      ) {
        result = [
          ...result,
          ...formExport.getFieldValue("DestinationIDSelect"),
        ];
      }

      if (result && result.length > 0) {
        formExport.setFieldValue("DestinationIDSelect", result);
      }

      dispatch(exportFormSearchDestinationIdle());
    }
  }, [exportFormSearchDestinationStatus]);

  return (
    <Form
      name="basic"
      layout="vertical"
      form={formExport}
      style={{
        maxWidth: "600px",
        margin: "0 auto",
      }}
      onFinish={(e) => {
        if (!e.errorFields) {
          let LocationIDList = [];
          if (e.WarehouseId && e.WarehouseId.length > 0) {
            e.WarehouseId.map((item) => {
              let value = item;
              if (item.value) {
                value = item.value;
              }
              if (LocationIDList.indexOf(value) < 0) {
                LocationIDList.push(value);
              }
            });
          }
          let ShipmentIDList = [];
          if (e.ShipmentNo && e.ShipmentNo.length > 0) {
            e.ShipmentNo.map((item) => {
              let value = item;
              if (item.value) {
                value = item.value;
              }
              if (ShipmentIDList.indexOf(value) < 0) {
                ShipmentIDList.push(value);
              }
            });
          }
          let DestinationIDList = [];
          console.log(e.DestinationIDSelect);
          if (e.DestinationIDSelect && e.DestinationIDSelect.length > 0) {
              e.DestinationIDSelect.map((item) => {
                let value = item;
                if (item.value) {
                  value = item.value;
                }
                if (DestinationIDList.indexOf(value) < 0) {
                  DestinationIDList.push(value);
                  // let code = value.split(' (');
                  // if(listLocations && listLocations.result){
                  //   let filterLocation = listLocations.result.filter(item => item.ID == value);
                  //   console.log(filterLocation, listLocations);
                  //   DestinationIDList.push(item.label.split(' (')[0]);
                  // }
                }
              });
          }
          let dataSend = {
            "LddDateFrom": e.LddDateFrom,
            "LddDateTo": e.LddDateTo,
            "InWork": e.InWork,
            "cod": e.cod,
            "Transport": e.Equipment,
            "ShipmentStartDateFrom": e.ShipmentStartDateFrom,
            "ShipmentStartDateTo": e.ShipmentStartDateTo,
            "ActualArrivalDateFrom": e.ActualArrivalDateFrom,
            "ActualArrivalDateTo": e.ActualArrivalDateTo,
            "LocationCode": DestinationIDList.length > 0 && DestinationIDList || undefined,
            "WarehouseId": LocationIDList.length > 0 && LocationIDList || undefined,
            "ShipmentNo": ShipmentIDList.length > 0 && ShipmentIDList || undefined,
            'big': 1,
            link: 'https://'+domainMain+'/api/cod_report_appointment_data.php'
          }
          //console.log(dataSend);
          if (getExcelStatus == "IDLE") {
            // console.log(dataSend);
            dispatch(getExcel(dataSend));
          }
        }
      }}
      autoComplete="off"
    >
      <Form.Item
        label="Номер рейса"
        name="ShipmentNo"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchBooking) {
                clearTimeout(timeoutSearchBooking);
                timeoutSearchBooking = null;
              }

              timeoutSearchBooking = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchBookingStatus == "IDLE") {
                    dispatch(exportFormSearchBooking({ code: newValue, type: 'cod' }));
                  }
                } else {
                  setDataBookings([]);
                }
              }, 300);
            } else {
              setDataBookings([]);
            }
          }}
          onChange={(newValue) => {
            setTimeout(function () {
              setDataBookings([]);
              dispatch(exportFormSearchBookingIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchBookingStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataBookings || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={shipments}
          onChange={(e) => {
            setShipments(e.target.value);
          }}
          rows={4}
          placeholder="Введите код рейса с новой строки"
        />
        <Button
          onClick={(e) => {
            let listShipments = [];

            if (
              shipments && shipments.length > 0 &&
              shipments.split(/\r?\n|\r|\n/g).length > 0
            ) {
              shipments.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  listShipments.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchBooking({ list: listShipments, type: 'cod' }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>
      <Form.Item
        label="Склад отгрузки"
        name="WarehouseId"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchWarehouse) {
                clearTimeout(timeoutSearchWarehouse);
                timeoutSearchWarehouse = null;
              }

              timeoutSearchWarehouse = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchWarehouseStatus == "IDLE") {
                    dispatch(exportFormSearchWarehouse({ code: newValue }));
                  }
                } else {
                  setDataWarehouses([]);
                }
              }, 300);
            } else {
              setDataWarehouses([]);
            }
          }}
          onChange={(e) => {
            // console.log(e);
            setTimeout(function () {
              setDataWarehouses([]);
              dispatch(exportFormSearchWarehouseIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchWarehouseStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataWarehouses || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={locations}
          onChange={(e) => {
            setLocations(e.target.value);
          }}
          rows={4}
          placeholder="Введите код склада с новой строки"
        />
        <Button
          onClick={(e) => {
            let listLocations = [];

            if (
              locations && locations.length > 0 &&
              locations.split(/\r?\n|\r|\n/g).length > 0
            ) {
              locations.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  // listLocations.push(item.replace(' ', ''));
                  // listLocations.push({
                  //     '%NAME': item.replace(' ', '')
                  // });
                  listLocations.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchWarehouse({ list: listLocations }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>

      <Form.Item
        label="Рейс взят в работу"
        name="InWork"
      >
        <Select
          allowClear
          placeholder="Y, N"
          onChange={(e) => {
            // console.log(e);
          }}
          options={[
            { value: "Y", label: "Y" },
            { value: "N", label: "N" },
          ]}
        />
      </Form.Item>
      <Form.Item
        label="Код точки доставки"
        name="DestinationIDSelect"
      >
        <Select
          showSearch
          placeholder="Выберите вариант"
          mode="multiple"
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={(newValue) => {
            if (newValue) {
              if (timeoutSearchDestination) {
                clearTimeout(timeoutSearchDestination);
                timeoutSearchDestination = null;
              }

              timeoutSearchDestination = setTimeout(function () {
                if (newValue.length > 0) {
                  if (exportFormSearchDestinationStatus == "IDLE") {
                    dispatch(exportFormSearchDestination({ code: newValue }));
                  }
                } else {
                  setDataDestinations([]);
                }
              }, 300);
            } else {
              setDataDestinations([]);
            }
          }}
          onChange={(newValue) => {
            setTimeout(function () {
              setDataDestinations([]);
              dispatch(exportFormSearchDestinationIdle());
            }, 300);
          }}
          notFoundContent={exportFormSearchDestinationStatus != "IDLE"
            ? <Spin size="small" />
            : null}
          options={(dataDestinations || []).map((d) => ({
            value: d.value,
            label: d.label,
          }))}
        />
      </Form.Item>

      <Form.Item>
        <TextArea
          value={destinations}
          onChange={(e) => {
            setDestinations(e.target.value);
          }}
          rows={4}
          placeholder="Введите код точки с новой строки"
        />
        <Button
          onClick={(e) => {
            let listDestinations = [];

            if (
              destinations && destinations.length > 0 &&
              destinations.split(/\r?\n|\r|\n/g).length > 0
            ) {
              destinations.split(/\r?\n|\r|\n/g).map((item) => {
                if (item.replace(" ", "").length > 0) {
                  listDestinations.push("^" + item.replace(" ", "") + "^");
                }
              });

              dispatch(exportFormSearchDestination({ list: listDestinations }));
            }
          }}
        >
          Добавить
        </Button>
      </Form.Item>
      <Form.Item
        name="LddDateFrom"
        hidden={true}
      />
      <Form.Item
        name="LddDateTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Дата доставки С"
            name="DateDostavkiS"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("LddDateFrom", dateString);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Дата доставки ПО"
            name="DateDostavkiPo"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("LddDateTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="ShipmentStartDateFrom"
        hidden={true}
      />
      <Form.Item
        name="ShipmentStartDateTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label="Плановая дата отгрузки С"
            name="PlanovayaDatePogruzkiS"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentStartDateFrom", dateString);
                // console.log(date, dateString)
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Плановая дата отгрузки ПО"
            name="PlanovayaDatePogruzkiPo"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ShipmentStartDateTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        name="ActualArrivalDateFrom"
        hidden={true}
      />
      <Form.Item
        name="ActualArrivalDateTo"
        hidden={true}
      />
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            label={
              <>
                Фактическая дата и время прибытия С <br />(COD)
              </>
            }
            name="DatePogruzkiVDokBukingeS"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ActualArrivalDateFrom", dateString);
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={
              <>
                Фактическая дата и время прибытия ПО <br />(COD)
              </>
            }
            name="DatePogruzkiVDokBukingePo"
          >
            <DatePicker
              style={{ width: "100%" }}
              format="DD.MM.YYYY"
              placeholder="Выберите дату"
              onChange={(date, dateString) => {
                formExport.setFieldValue("ActualArrivalDateTo", dateString);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Заполнено ли COD"
        name="cod"
      >
        <Select
          allowClear
          // mode="multiple"
          placeholder="Y, N"
          onChange={(e) => {
            // console.log(e);
          }}
          options={[
            { value: "Y", label: "Y" },
            { value: "N", label: "N" },
          ]}
        />
      </Form.Item>
      <Form.Item
          label="Тип транспортного средства"
          name={'Equipment'}
          
      >
          <Select
              showSearch
              mode="multiple"
              placeholder="Введите название"
              filterOption={(input, option) =>
                  (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={typeTrucks.selectOptions}
          />
      </Form.Item>
      <Form.Item>
        <Space size={8}>
          <Button htmlType="submit" type="primary">Выгрузить</Button>
          <Button
            htmlType="button"
            onClick={(e) => {
              formExport.resetFields();
            }}
          >
            Очистить
          </Button>
          <Button
            htmlType="button"
            onClick={(e) => {
              setView("warehouses");
            }}
          >
            Закрыть
          </Button>
        </Space>
        {getExcelStatus == "LOADING" && <Spin />}
        {getExcelStatus !== "LOADING" && getExcelResult &&
            getExcelResult.result && getExcelResult.result == "ok" && (
              <a
                target="_blank"
                href={`https://${domainMain}/${getExcelResult.file_url}`}
              >
                ULU_COD_REPORT
              </a>
            ) ||
          getExcelResult && getExcelResult.error_message &&
            getExcelResult.error_message == "no data" && "Нет данных" ||
          ""}
      </Form.Item>
    </Form>
  );
};

export default ExportForm;
