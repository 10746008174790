import {call, put, takeEvery} from "redux-saga/effects";

import { authAppRequest } from "./api";

import {
    handleAuthApp,
    handleAuthAppSuccess,
    handleAuthAppFailure,
    handleAuthAppInit,
} from "./actions";
import { isLocalhost, redirectAuth } from "../../constants/config";

function* handleAuthAppSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(authAppRequest, payload);

        // console.log(response);

        if(!isLocalhost && response && ( response.resp == 'Unauthorized' || response.resp == 'Refreshed' ) ){
            // console.log(response)
            redirectAuth();
        }

        // if(response){
        //     if(typeof response !== 'string' && response !== 'Unauthorized' && response !== 'Refreshed'){
        //         if(response.group){
        //             yield put(handleAuthAppInit(response));
        //         }
        //     } else if(
        //         response && 
        //         typeof response == 'string' && 
        //         response.replace(/\n/g, '') !== 'Unauthorized' && 
        //         response.replace(/\s/g, '') !== 'Unauthorized' && 
        //         response.replace(/\n/g, '') !== 'Refreshed' && 
        //         response.replace(/\s/g, '') !== 'Refreshed'
        //     ) {
        //         if(JSON.parse(response).group){
        //             yield put(handleAuthAppInit(JSON.parse(response)));
        //         }
        //     }
        // }

        yield put(handleAuthAppSuccess(response));

    } catch (error) {

        yield put(handleAuthAppFailure(error));

    }
}


export function* handleAuthAppWatcher() {
    yield takeEvery(handleAuthApp.toString(), handleAuthAppSaga);
}
