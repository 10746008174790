import {createActions} from "redux-actions";

const {
    getListPo,
    getListPoSuccess,
    getListPoFailure,
    getListPoIdle,
    getListPoClear,
    getItemPo,
    getItemPoSuccess,
    getItemPoFailure,
    getItemPoIdle,
    getItemPoClear,
    updateItemPo,
    updateItemPoSuccess,
    updateItemPoFailure,
    updateItemPoIdle,
    setPoIds
} = createActions(
    "GET_LIST_PO",
    "GET_LIST_PO_SUCCESS",
    "GET_LIST_PO_FAILURE",
    "GET_LIST_PO_IDLE",
    "GET_LIST_PO_CLEAR",
    "GET_ITEM_PO",
    "GET_ITEM_PO_SUCCESS",
    "GET_ITEM_PO_FAILURE",
    "GET_ITEM_PO_IDLE",
    "GET_ITEM_PO_CLEAR",
    "UPDATE_ITEM_PO",
    "UPDATE_ITEM_PO_SUCCESS",
    "UPDATE_ITEM_PO_FAILURE",
    "UPDATE_ITEM_PO_IDLE",
    "SET_PO_IDS"
)

export {
    getListPo,
    getListPoSuccess,
    getListPoFailure,
    getListPoIdle,
    getListPoClear,
    getItemPo,
    getItemPoSuccess,
    getItemPoFailure,
    getItemPoIdle,
    getItemPoClear,
    updateItemPo,
    updateItemPoSuccess,
    updateItemPoFailure,
    updateItemPoIdle,
    setPoIds
}