import {createActions} from "redux-actions";

const {
    getMessages,
    getMessagesSuccess,
    getMessagesFailure,
    getMessagesIdle,
    getMessagesClear,
    addMessage,
    addMessageSuccess,
    addMessageFailure,
    addMessageIdle
} = createActions(
    "GET_MESSAGES",
    "GET_MESSAGES_SUCCESS",
    "GET_MESSAGES_FAILURE",
    "GET_MESSAGES_IDLE",
    "GET_MESSAGES_CLEAR",
    "ADD_MESSAGE",
    "ADD_MESSAGE_SUCCESS",
    "ADD_MESSAGE_FAILURE",
    "ADD_MESSAGE_IDLE"
)

export {
    getMessages,
    getMessagesSuccess,
    getMessagesFailure,
    getMessagesIdle,
    getMessagesClear,
    addMessage,
    addMessageSuccess,
    addMessageFailure,
    addMessageIdle
}