import {createActions} from "redux-actions";

const {
    handleAuthApp,
    handleAuthAppSuccess,
    handleAuthAppFailure,
    handleAuthAppIdle,
    handleAuthAppInit,
    setAuthApp
} = createActions(
    "HANDLE_AUTH_APP",
    "HANDLE_AUTH_APP_SUCCESS",
    "HANDLE_AUTH_APP_FAILURE",
    "HANDLE_AUTH_APP_IDLE",
    "HANDLE_AUTH_APP_INIT",
    "SET_AUTH_APP"
);


export {
    handleAuthApp,
    handleAuthAppSuccess,
    handleAuthAppFailure,
    handleAuthAppIdle,
    handleAuthAppInit,
    setAuthApp
};
