import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { list } from "../../../requests/constants";
import { updateItemRequests } from "../../../../../modules/requests/actions";
// import { changeStatusRequestItem } from "../../../../store/ducks/requestItem/actions";

const BtnsActions = ({ id, type, upload, status, role, statusUpdate, requestsItemDefault }) => {

  // const pts = useSelector((state) => state.files.pts);
  // const labour = useSelector((state) => state.files.labour);
  // const identity = useSelector((state) => state.files.identity);
  // const passport = useSelector((state) => state.files.passport);
  // const third_party = useSelector((state) => state.files.third_party);
  // const forwarding = useSelector((state) => state.files.forwarding);
  // const cont_bet_forw_and_car = useSelector((state) => state.files.cont_bet_forw_and_car);
  // const attorney = useSelector((state) => state.files.attorney);
  // const extract = useSelector((state) => state.files.extract);
  // const criminal = useSelector((state) => state.files.criminal);
  // const bankruptcy = useSelector((state) => state.files.bankruptcy);
  // const tax = useSelector((state) => state.files.tax);
  // const assets = useSelector((state) => state.files.assets);
  // const obligations = useSelector((state) => state.files.obligations);
  // const tractor = useSelector((state) => state.files.tractor);
  // const trailer = useSelector((state) => state.files.trailer);
  // const rent = useSelector((state) => state.files.rent);
  const dataUser = useSelector(state => state.authApp.init);
  

  const pts = [];
  const labour = [];
  const identity = [];
  const passport = [];
  const third_party = [];
  const forwarding = [];
  const cont_bet_forw_and_car = [];
  const attorney = [];
  const extract = [];
  const criminal = [];
  const bankruptcy = [];
  const tax = [];
  const assets = [];
  const obligations = [];
  const tractor = [];
  const trailer = [];
  const rent = [];
  
  const dispatch = useDispatch();


  const changeStatusHandle = (e, status) => {
    e.preventDefault();
    let documentsArr = [], documentsStr = "";
    if (type === 'Запрос документов по проверке безопасности' && role === 'Перевозчик') {
      documentsArr = [...documentsArr, ...pts, ...labour, ...identity, ...passport]
    }
    if (type === 'Запрос документов по проверке безопасности' && role === 'Экспедитор') {
      documentsArr = [...documentsArr, ...labour, ...identity, ...passport, ...third_party, ...forwarding, ...cont_bet_forw_and_car, ...attorney, ...extract, ...criminal, ...bankruptcy, ...tax, ...assets, ...obligations, ...tractor, ...trailer, ...rent];
    }
    if (documentsArr.length > 0) {
      documentsArr.map(item => {
        documentsStr += `${item};`;
      });
      documentsStr = documentsStr.slice(0, -1);
    }
    let customStatus = '';
    if(list && list[255]){
      customStatus = list[255].filter(item => item.label == status)[0].value;
    }
    // console.log(customStatus);
    // console.log("btnsActions: ", {id: id, data: { Status: status, Files: documentsStr }});
    if (type === 'Запрос документов по проверке безопасности') {
      if(statusUpdate == 'IDLE'){
        dispatch(updateItemRequests({
          props: {
            ...requestsItemDefault[0],
            'PROPERTY_255': customStatus
          }
        }));
      }
      // dispatch(changeStatusRequestItem({id: id, data: { Status: status, Files: documentsStr }}));
    } else {
      if(statusUpdate == 'IDLE'){
        dispatch(updateItemRequests({
          props: {
            ...requestsItemDefault[0],
            'PROPERTY_255': customStatus
          }
        }));
      }
      // dispatch(changeStatusRequestItem({id: id, data: { Status: status }}));
    }
  }

  return <div className="application-table__wrap-btns">
  <>
    {status === 'Отправлен' && (
      <>
        {upload && type === 'Запрос документов по проверке безопасности' && (
          <button onClick={(e) => changeStatusHandle(e, 'Выполнен')} className="btn btn--blue application-table__btn">Запрос выполнен</button>
        )}
        {!upload && type === 'Запрос документов по проверке безопасности' && (
          <button disabled={true} className="btn btn--disabled application-table__btn">Запрос выполнен</button>
        )}
        {!upload && type !== 'Запрос документов по проверке безопасности' && type !== 'Запрос с подтверждением' && (
          <button onClick={(e) => changeStatusHandle(e, 'Выполнен')} className="btn btn--blue application-table__btn">Запрос выполнен</button>
        )}
        {type === 'Запрос с подтверждением' && dataUser && dataUser.group !== "user_ul" && (
          <button onClick={(e) => changeStatusHandle(e, 'Подтвержден')} className="btn btn--blue application-table__btn">Подтвердить</button>
        )}
        {dataUser && dataUser.group === "user_ul" && (
          <button onClick={(e) => changeStatusHandle(e, 'Отозван')} className="btn btn--blue application-table__btn">Отменить</button>
        )}
      </>
    ) || (status === 'Черновик') && (
      <>
        <button onClick={(e) => changeStatusHandle(e, 'Отправлен')} className="btn btn--blue application-table__btn">Отправить</button>
        {dataUser && dataUser.group === "user_ul" && (
          <button onClick={(e) => changeStatusHandle(e, 'Отозван')} className="btn btn--blue application-table__btn">Отменить</button>
        )}
      </>
    )}
  </>
</div>;
};
export default BtnsActions;
