import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getPo, checkPoItems } from "../../../../store/ducks/po/actions";
// import { selectPO } from "../../../../store/ducks/po/selector";

import LoadMore from "../../../../components/loadMore";
import cx from "classnames";
import iconExternalLink from '../../../../../assets/img/icons/icon_external-link.png';
import iconNew from '../../../../../assets/img/icons/new.svg';
import { po } from "../../data";
import { Link } from "react-router-dom";
import { poSelector } from "../../../../../modules/po/selector";
import { setPoIds } from "../../../../../modules/po/actions";


const ListPO = ({ home, setItemsExport, itemsExport, openNotificationWithIcon }) => {

  const dispatch = useDispatch();
  const filter = {};
  const next = null;
  const [POItems, setPOItems] = React.useState([]);
  const dataUser = useSelector(state => state.authApp.init);

  const poList = useSelector(poSelector);

  // React.useEffect(() => {
  //   dispatch(getPo({filter, next: "", home}));
  // }, [filter]);

  const loadMore = (e) => {
    e.preventDefault();
    // dispatch(getPo({filter, next: next}));
  }

  const check = (e, item) => {
    const checked = e.target.checked;
    if (checked) {
      setPOItems([...POItems, item.ID]);
      setItemsExport([...itemsExport, {
        "Vendor code/Код поставщика": item.ServiceProviderCode,
        "Vendor name/ Наименование поставщика": item.ServiceProviderName,
        "Shipment": item.ShipmentNumber,
        "PO": item.POnumber,
        "Дата РО": item.POdate,
        "Invoice number/ Номер счет-фактуры": "",
        "Invoice date/ Дата счет-фактуры": "",
        "Amount/ Сумма по рейсу": item.PlannedCost,
        "Дата отправки документов": "",
        "Локация, куда документы отправлены": ""
      }]);
    } else {
      setPOItems(POItems.filter(item => item !== item.ID));
      setItemsExport(itemsExport.filter(item => item.PO !== item.POnumber));
    }
  }

  React.useEffect(() => {
    dispatch(setPoIds(POItems));
  }, [POItems]);

  return <div id="table_po" className="section-table section-table--po main__section-table">
  <div className="section-table__wrap-tables">
    {!home && (
      <div className="section-table__table-left">
        <div className="section-table__row-left"></div>
        {poList.length > 0 && poList.map((item, idx) => (
          <div key={idx} className={cx(
            "section-table__row-left",
            (!item.View || item.View == '0') && "section-table__row-left--new"
          )}>
            {(!item.View || item.View == '0') && (
               <img className="img-link-new-window" src={iconNew} alt="icon" />
            )}
          </div>
        ))}
      </div>
    )}
    <div className="section-table__table">
      <div className="section-table__row">
        <div className="section-table__col">Дата PO</div>
        <div className="section-table__col">Статус</div>
        <div className="section-table__col">Код склада</div>
        <div className="section-table__col">Номер рейса</div>
        {dataUser && dataUser.group === "user_ul" && (
          <>
           <div className="section-table__col">Код перевозчика</div>
           <div className="section-table__col">Название перевозчика</div>
          </>
        )}
        <div className="section-table__col">Номер PO</div>
        <div className="section-table__col">Сумма PO</div>
        <div className="section-table__col">Валюта</div>
        <div className="section-table__col">Код получателя</div>
        <div className="section-table__col">Название получателя</div>
        <div className="section-table__col">Номер накладной</div>
        <div className="section-table__col">Дата погрузки</div>
        <div className="section-table__col">Тип грузового авто</div>
        <div className="section-table__col">Кол-во паллетов</div>
      </div>
      {poList.length > 0 && poList.map(item => (
        <Link target="_blank" key={item.ID} to={`/poItem/${item.ID}`} className="section-table__row" style={{ height: 'auto' }}>
          <div className="section-table__col">
          <span className="wrapper-link-new-window">
            <img className="img-link-new-window" src={iconExternalLink} alt="icon" />
            <span>{item.POdate}</span>
          </span>
          </div>
          <div className="section-table__col">{item.Status}</div>
          <div className="section-table__col">{item.ShippingPoint}</div>
          <div className="section-table__col">{item.ShipmentNumber}</div>
          {dataUser && dataUser.group === "user_ul" && (
          <>
            <div className="section-table__col">{item.ServiceProviderCode}</div>
            <div className="section-table__col">{item.ServiceProviderName}</div>
          </>
          )}
          <div className="section-table__col">{item.POnumber}</div>
          <div className="section-table__col">{item.PlannedCost}</div>
          <div className="section-table__col">{item.Currency}</div>
          <div className="section-table__col">{item.ShipTo}</div>
          <div className="section-table__col">{item.ShipToName}</div>
          <div className="section-table__col">{item.DeliveryNumber}</div>
          <div className="section-table__col">{item.LoadingDate}</div>
          <div className="section-table__col">{item.TruckType}</div>
          <div className="section-table__col">{item.PalletQTy}</div>
        </Link>
      ))}
    </div>
    {!home && (
      <div className="section-table__table-right">
        <div className="section-table__row-right"></div>
        {poList.length > 0 && poList.map((item, idx) => (
          <div key={idx} className="section-table__row-right">
            <input onChange={(e) => check(e, item)} className="checkbox section-table__row-checkbox" type="checkbox" />
          </div>
        ))}
      </div>
    )}
  </div>
  {poList.length > 0 && <p className="section-table__text">Используйте scroll bar, чтобы увидеть больше данных</p>}
  {!home && next && (
    <LoadMore loadMore={loadMore} />
  )}
</div>;
};
export default ListPO;
