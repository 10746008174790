import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getListRequests,
    getListRequestsSuccess,
    getListRequestsFailure,
    getListRequestsIdle,
    getListRequestsClear,
    getItemRequests,
    getItemRequestsSuccess,
    getItemRequestsFailure,
    getItemRequestsIdle,
    getItemRequestsClear,
    updateItemRequests,
    updateItemRequestsSuccess,
    updateItemRequestsFailure,
    updateItemRequestsIdle,
    addItemRequests,
    addItemRequestsSuccess,
    addItemRequestsFailure,
    addItemRequestsIdle,
    setRequestsIds,
} from './actions'

const status = handleActions(
    {
        [getListRequestsSuccess]: () => "SUCCESS",
        [getListRequests]: () => "LOADING",
        [getListRequestsFailure]: () => "FAILURE",
        [getListRequestsIdle]: () => "IDLE",
    },
    'IDLE'
);

const count = handleActions(
    {
        [getListRequestsSuccess]: (state, action) => action.payload.count,
        [getListRequestsFailure]: (state, action) => 0,
        [getListRequestsClear]: () => 0
    },
    0
);

const data = handleActions(
    {
        [getListRequestsSuccess]: (state, action) => {
            return [
                ...state,
                ...action.payload.result
            ]
        },
        [getListRequestsFailure]: (state, action) => {
            return []
        },
        [getListRequestsClear]: () => []
    },
    []
);

const ids = handleActions(
    {
        [setRequestsIds]: (state, action) => action.payload,
    },
    []
);

const requests = combineReducers({
    status,
    data,
    count,
    ids
})

const statusItem = handleActions(
    {
        [getItemRequestsSuccess]: () => "SUCCESS",
        [getItemRequests]: () => "LOADING",
        [getItemRequestsFailure]: () => "FAILURE",
        [getItemRequestsIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusItemUpdate = handleActions(
    {
        [updateItemRequestsSuccess]: () => "SUCCESS",
        [updateItemRequests]: () => "LOADING",
        [updateItemRequestsFailure]: () => "FAILURE",
        [updateItemRequestsIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusItemAdd = handleActions(
    {
        [addItemRequestsSuccess]: () => "SUCCESS",
        [addItemRequests]: () => "LOADING",
        [addItemRequestsFailure]: () => "FAILURE",
        [addItemRequestsIdle]: () => "IDLE",
    },
    'IDLE'
);

const addIdRequest = handleActions(
    {
        [addItemRequestsSuccess]: (state, action) => {
            return action.payload.result
        },
    },
    null
);

const dataItem = handleActions(
    {
        [getItemRequestsSuccess]: (state, action) => {
            return action.payload.result
        },
        [getItemRequestsFailure]: (state, action) => {
            return []
        },
        [getItemRequestsClear]: () => []
    },
    []
);

const requestsItem = combineReducers({
    statusItem,
    statusItemUpdate,
    statusItemAdd,
    dataItem,
    addIdRequest
})

export { requests, requestsItem };