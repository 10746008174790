import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import { 
    Button, 
    Space,
    Modal ,
    Form, 
    Input,
    Select,
    Radio,
    Spin,
    Card,
    DatePicker,
    Alert,
    notification,
    Switch,
    Popconfirm
} from 'antd';
import { searchBooking, searchBookingIdle, searchBookingReset } from '../../../../modules/booking/actions';

const Search = (props) => {

    const dispatch = useDispatch();

    const { formSearch, activeWarehouses, activeWarehousesOptions, checkedSklads, setSearchWarehouse, setIsSelectWarehouse } = props;

    const searchBookingStatus = useSelector(state => state.booking.searchBookingStatus);
    const searchBookingResult = useSelector(state => state.booking.searchBookingResult);

    return (
        <Form
            name="basic"
            layout="vertical"
            form={formSearch}
            onFinish={e => {
                if(!e.errorFields){
                    if(searchBookingStatus == 'IDLE'){
                        // console.log(e);
                        if(!e.shipment || e.shipment == ''){
                            dispatch(searchBookingReset());
                            setIsSelectWarehouse(false);
                        } else {
                            let data = {
                                ...e,
                                warehouse: checkedSklads
                            }
                            dispatch(searchBooking(data));
                            setIsSelectWarehouse(true);
                        }
                    }
                }
            }}
            onFinishFailed={e => {
                // console.log(e);
            }}
            autoComplete="off"
        >
            <Space size={8}>
                {/* <Form.Item
                    name="warehouse"
                    style={{ marginBottom: 0 }}
                    type="hidden"
                >
                    <Input /> */}
                {/* {activeWarehouses && activeWarehouses.length > 0 && (
                    <Select
                        mode="multiple"
                        allowClear
                        style={{
                            width: 200,
                        }}
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        placeholder="Выберите склад"
                        options={activeWarehousesOptions}
                    />
                )} */}
                {/* </Form.Item> */}
                <Form.Item
                    name="shipment"
                    style={{ marginBottom: 0 }}
                >
                    <Input style={{ width: '100%' }} placeholder="Shipment ID" />
                </Form.Item>
                {/* <Form.Item
                    name="PROPERTY_435"
                    style={{ marginBottom: 0 }}
                >
                    <Input style={{ width: '100%' }} placeholder="Order Release ID" />
                </Form.Item> */}
                <Button type="primary" htmlType="submit" loading={searchBookingStatus == 'LOADING' && true || false}>
                    Search
                </Button>
                <Button onClick={e => {
                    dispatch(searchBookingReset());
                    setSearchWarehouse(null);
                    formSearch.resetFields();
                }}>
                    Reset
                </Button>
            </Space>
        </Form>
    )

}

export default Search;