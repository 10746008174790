export const RequestsStatus = [
    {
        value: 'Черновик',
        label: 'Черновик'
    },
    {
        value: 'Отправлен',
        label: 'Отправлен'
    },
    {
        value: 'Отозван',
        label: 'Отозван'
    },
    {
        value: 'Выполнен',
        label: 'Выполнен'
    },
    {
        value: 'Подтвержден',
        label: 'Подтвержден'
    }
];

export const list = {
    215: [
        {
            value: 123,
            label: 'В электронном виде'
        },
        {
            value: 125,
            label: 'С водителем (бум. копии)'
        }
    ],
    247: [
        {
            value: 135,
            label: 'Упаковочный лист'
        },
        {
            value: 137,
            label: 'Накладная на внутреннее перемещение'
        },
        {
            value: 139,
            label: 'Транспортная накладная'
        },
        {
            value: 141,
            label: 'Товарная накладная'
        },
        {
            value: 143,
            label: 'Полный комплект документов'
        },
        {
            value: 145,
            label: 'Акт'
        },
        {
            value: 147,
            label: 'Другое'
        },
    ],
    219: [
        {
            value: 127,
            label: 'Упаковочный лист'
        },
        {
            value: 129,
            label: 'Транспортная накладная'
        },
        {
            value: 131,
            label: 'Товарная накладная'
        },
        {
            value: 133,
            label: 'Другое'
        },
    ],
    255: [
        {
            value: 153,
            label: 'Черновик'
        },
        {
            value: 155,
            label: 'Отправлен'
        },
        {
            value: 157,
            label: 'Отозван'
        },
        {
            value: 159,
            label: 'Выполнен'
        },
        {
            value: 161,
            label: 'Подтвержден'
        },
    ],
    251: [
        {
            value: 149,
            label: 'Перевозчик'
        },
        {
            value: 151,
            label: 'Экспедитор'
        },
    ]
}

export const RequestsType = [
    {
        value: '101',
        label: 'Запрос документов'
    },
    {
        value: '103',
        label: 'Недостача, брак, излишки'
    },
    {
        value: '105',
        label: 'Нет сертификатов/вет. свидетельства'
    },
    {
        value: '107',
        label: 'Перегрузы'
    },
    {
        value: '109',
        label: 'Простой на выгрузке'
    },
    {
        value: '111',
        label: 'Нет электронного уведомления'
    },
    {
        value: '113',
        label: 'Опоздание на погрузку/выгрузку'
    },
    {
        value: '115',
        label: 'Иное'
    },
    {
        value: '117',
        label: 'Запрос документов транспортных'
    },
    {
        value: '119',
        label: 'Запрос с подтверждением'
    },
    {
        value: '121',
        label: 'Запрос документов по проверке безопасности'
    }
];

export const requests = [
    {
        ID: 404,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос документов по проверке безопасности",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 414,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Опоздание на погрузку/выгрузку",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 434,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Простой на выгрузке",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    }
];
export const requestsHome = [
    {
        ID: 854,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Простой на выгрузке",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 654,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос документов по проверке безопасности",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 354,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос документов транспортных",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    }
];
export const requestsConfirm = [
    {
        ID: 453,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос с подтверждением",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 454,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос с подтверждением",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    },
    {
        ID: 455,
        Created: "17.07.2023",
        FlightNumber: 453454,
        TypeRequest: "Запрос с подтверждением",
        Warehouse: "HG456",
        TnNumber: 4534,
        NameClient: "Test",
        DeliveryAddress: "Test",
        CarBrand: "Test",
        CarNumber: "Test",
        Driver: "Test",
        PhoneDriver: "Test",
        DateTimeDelivery: "17.07.2023",
        ActualDateTime: "17.07.2023",
        ReasonRestoringDocuments: "Test",
        DocumentTransferMethod: "Test",
        TransferDocuments: "Test",
        DocumentsNeedRestore: "Test",
        Article: "Test",
        QuantityBoxes: "Test",
        ScanAct: "Test",
        DriverAcceptance: "Test",
        Articles: "Test",
        AxleLoadSensor: "Test",
        SensorShowOverload: "Test",
        CarArriveOnTime: "Test",
        ReasonNonLoading: "Test",
        NumberInvoiceWhichLoadingRegistered: 453453434,
        TnNoEmailNotification: "Test",
        ReasonBeingLate: "Test",
        WhenLoadingUnloading: "Test",
        Other: "Test",
        DocumentsNeedProvide: "Test",
        TypeTrack: "Test",
        TKRole: "Экспедитор",
        View: false,
        Status: "Новый",
        Author: 777,
        Files: [],
        Partner: "Test"
    }
]