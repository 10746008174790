import * as React from "react";
import Select from "react-select";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import BtnsForm from '../btnsForm';
import FilesUpload from "../../../../components/filesUpload";
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";

const customStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "60px",
  })
};

const ConfirmationForm = ({ setSendData, requestItem, listFlightNumber, autocomplete }) => {

  const dispatch = useDispatch();
  const defaultData = [];
  const dataUser = useSelector(state => state.authApp.init);
  // const linksFiles = useSelector((state) => state.files.links);
  const linksFiles = [];
  const [linksStr, setLinksStr] = React.useState("");
  // const [warehouses, setWarehouses] = React.useState([]);
  const warehouses = useSelector(warehousesOptionsSelector);
  const [error, setError] = React.useState(false);
  const [path, setPath] = React.useState([]);

  const { control, handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: requestItem,
  });
  
  
  const onSubmit = data => {
    setSendData({...data, ...{TypeRequest: 'Запрос с подтверждением'}, ...{Files: linksStr}});
  };

  React.useEffect(() => {
    if (defaultData.length === 0) {
        // dispatch(initData());
    }
  }, []);

  React.useEffect(() => {
    let str = "";
    linksFiles.length > 0 && linksFiles.map(link => {
      str += `${link};`;
    });
    setLinksStr(str.slice(0, -1));
  }, [linksFiles]);

  // React.useEffect(() => {
  //   if (watch('Partner') && watch('Partner').length > 0) {
  //     let meta = [];
  //     watch('Partner').map(partner => {
  //       if (partner.meta && partner.meta.length > 0) {
  //         partner.meta.map(item => {
  //           meta = [...meta, item];
  //         })
  //       }
  //     })
  //     setWarehouses(meta);
  //   }
  //   if (watch('Partner') && watch('Partner').length > 0) {
  //     let pathArr = [];
  //     watch('Partner').map(partner => {
  //       pathArr = [...pathArr, `FilesUpload/${partner.partnerKey}`];
  //     });
  //     setPath(pathArr);
  //   }
  // }, [watch('Partner')])

  React.useEffect(() => {
    if (watch("FlightNumber") && watch("FlightNumber")['value']) {
      const flightNumber = watch("FlightNumber")['value'];
      const obj = autocomplete.find(el => el.FlightNumber === flightNumber);
        setValue("Warehouse", obj.Warehouse);
        let partnerObj = {
          label: "",
          value: []
        };
        let partnerEmpty = true;
        const { partners } = dataUser;
        partners && partners.length > 0 && partners.map((partner) => {
          const { meta } = partner;
          meta.map((item) => {
            if (item.label === obj.Warehouse.label) {
              partnerObj = partner;
              partnerEmpty = false;
            }
          })
        });
        if (!partnerEmpty) {
          setValue("Partner", [partnerObj]);
        }
    }
  }, [watch("FlightNumber")])

  React.useEffect(() => {
    if (watch('Warehouse')) {
      if (!Array.isArray(watch('Warehouse'))) {
        if (warehouses.length > 0 && !warehouses.find(elem => elem.value === watch('Warehouse')['value'])) setError(true)
        else setError(false)
      } else if (Array.isArray(watch('Warehouse'))) {
        const arr = watch('Warehouse');
        let flagError = false;
        arr.map(item => {
          if (warehouses.length > 0 && !warehouses.find(elem => elem.value === item['value'])) {
            flagError = true
          }
        });
        if (flagError) setError(true)
        else setError(false)
      }
    }
  }, [watch('Partner'), watch('Warehouse'), watch('FlightNumber'), warehouses]);

  React.useEffect(() => {
    if (dataUser && dataUser.filePath) {
      setPath([dataUser.filePath]);
    }
  }, [dataUser]);

  return <div className="block-tabs__block active">
      <form onSubmit={handleSubmit(onSubmit)} className="form form--request form--confirmation block-tabs__block__form" action="#">
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <label className={errors.FlightNumber && "label__form__input error" || "label__form__input"}>Номер рейса {errors.FlightNumber && "*"}</label>
            {listFlightNumber.length > 0 && (
              <Controller
                name="FlightNumber"
                control={control}
                rules={{ required: false }}
                render={({ field }) => <Select 
                  value={field.value || ""}
                  styles={customStyles}
                  onChange={(value) => field.onChange(value)}
                  placeholder="Номер рейса"
                  options={listFlightNumber} 
                />}
              />
            ) || (
              <input {...register('FlightNumber', { required: false })} className="input form__input"  type="text"   placeholder="Номер рейса" />
            )}
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
            <label className={errors.Partner && "label__form__input error" || "label__form__input"}>Партнёр {errors.Partner && "*"}</label>
            {/* <Controller
              name="Partner"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Select 
                value={field.value}
                isMulti={true}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Партнёр"
                options={[]} 
              />}
            /> */}
            <input {...register('Partner', { 
                required: true 
              })} className="input form__input" type="text" placeholder="Партнер" />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
            <label className={(errors.Warehouse || error) && "label__form__input error" || "label__form__input"}>Склад отгрузки {(errors.Warehouse || error) && "*"}</label>
            <Controller
                name="Warehouse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Select 
                value={requestItem ? [field.value] : field.value}
                isMulti={requestItem ? false : true}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Склад отгрузки" 
                options={warehouses} 
                />}
            />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
              <label className={errors.Other && "label__form__input error" || "label__form__input"}>Запрос {errors.Other && "*"}</label>
              <textarea {...register('Other', { required: true })} className="input form__textarea" placeholder="Запрос"></textarea>
            </div>
        </div>
        <div className="form_row">
          <div className="form__col">
            <label>Прикрепите файл {requestItem && requestItem.ID && "(при добавлении файла, новый заменит предыдущий документ)"}</label>
            <FilesUpload multi={false} path={path}/>
          </div>
        </div>
        </div>
        <div className="form__container">
        <BtnsForm error={error} id={requestItem && requestItem.ID} setValue={setValue} />
        </div>
      </form>
  </div>;
};
export default ConfirmationForm;
