import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import { 
    getListBookingReguest, 
    getListDocksWarehouseReguest, 
    getListBlockedReguest,
    addBlockedCellRequest,
    updateBookingRequest,
    updateBlockedRequest,
    addBookingRequest,
    getBookingShipmentRequest,
    deleteBookingRequest,
    getDefaulBlockedCellsReguest,
    addBookingShipmentRequest,
    deleteBookingShipmentRequest,
    updateBookingCShipmentRequest,
    searchTypeLocationRequest,
    searchUsersProviderRequest,
    getListBookingAllReguest,
    getShipmentRequest,
    getPerevozchikRequest
} from "./api";

import {
    getListBooking,
    getListBookingSuccess,
    getListBookingFailure,
    setListWarehousesBookings,
    setBlockedCells,
    addBlockedCell,
    addBlockedCellSuccess,
    addBlockedCellFailure,
    addBlockedCellIdle,
    updateBooking,
    updateBookingSuccess,
    updateBookingFailure,
    updateBookingIdle,
    setCheckedWarehouses,
    setReserveCells,
    setAllBlockedTimeDock,
    setCheckedDirection,
    setActiveWarehouses,
    checkedBlockedCells,
    checkedBlockedCellsSuccess,
    checkedBlockedCellsFailure,
    checkedBlockedCellsIdle,
    checkedBlockedCellsDate,
    checkedBlockedCellsDateSuccess,
    checkedBlockedCellsDateFailure,
    checkedBlockedCellsDateIdle,
    updateBlocked,
    updateBlockedSuccess,
    updateBlockedFailure,
    updateBlockedIdle,
    getFreeCells,
    getFreeCellsSuccess,
    getFreeCellsFailure,
    getFreeCellsIdle,
    addBooking,
    addBookingSuccess,
    addBookingFailure,
    addBookingIdle,
    getBookingShipment,
    getBookingShipmentSuccess,
    getBookingShipmentFailure,
    getBookingShipmentIdle,
    deleteBooking,
    deleteBookingSuccess,
    deleteBookingFailure,
    deleteBookingIdle,
    searchBooking,
    searchBookingSuccess,
    searchBookingFailure,
    searchBookingIdle,
    searchTypeLocationSuccess,
    searchTypeLocationFailure,
    searchTypeLocation,
    searchUsersProviderSuccess,
    searchUsersProviderFailure,
    searchUsersProvider,
    changeCells,
    changeCellsSuccess,
    changeCellsFailure,
    changeCellsIdle,
    getServprovName,
    getServprovNameSuccess,
    getServprovNameFailure,
    getServprovNameIdle,
} from "./actions";
import { getFieldsReguest } from "../initData/api";

import dayjs from 'dayjs';
import { getValueProperty, fields, fieldsShipment, shipmentList } from "../../constants/config";

function* getServprovNameSaga(action){
    try {

        const { payload } = action;

        const { booking } = payload;

        let response = yield call(getPerevozchikRequest, { code: getValueProperty(booking, 377) });

        // if(response && response.result){
        yield put(getServprovNameSuccess({
            booking,
            shortName: response && response.result && response.result[0] && getValueProperty(response.result[0], 459) || ''
        }));
        // }


    } catch (error) {

        yield put(getServprovNameFailure(error));

    }
}

function* getListBookingSaga(action) {
    try {

        const { payload } = action;

        let { idWarehouse, dateBookings, directionDock, timeList, infoWarehouses, dataUser } = payload;

        // let listBooking = yield call(getListBookingReguest, { idWarehouse, dateBookings });

        let [listBooking1, listBooking2, listBooking3, listBooking4, listBooking5, listBooking6, listBooking7] = yield all([
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 0 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 50 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 100 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 150 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 200 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 250 }),
            call(getListBookingReguest, { idWarehouse, dateBookings, start: 300 }),
        ]);
        
        let listBookingTotal = 0;
        let listBookingResult = [];
        
        if(listBooking1 && listBooking1.result){
            listBookingTotal = listBookingTotal + listBooking1.result.length;
            listBookingResult = [...listBookingResult, ...listBooking1.result];
        }
        if(listBooking2 && listBooking2.result){
            listBookingTotal = listBookingTotal + listBooking2.result.length;
            listBookingResult = [...listBookingResult, ...listBooking2.result];
        }
        if(listBooking3 && listBooking3.result){
            listBookingTotal = listBookingTotal + listBooking3.result.length;
            listBookingResult = [...listBookingResult, ...listBooking3.result];
        }
        if(listBooking4 && listBooking4.result){
            listBookingTotal = listBookingTotal + listBooking4.result.length;
            listBookingResult = [...listBookingResult, ...listBooking4.result];
        }
        if(listBooking5 && listBooking5.result){
            listBookingTotal = listBookingTotal + listBooking5.result.length;
            listBookingResult = [...listBookingResult, ...listBooking5.result];
        }
        if(listBooking6 && listBooking6.result){
            listBookingTotal = listBookingTotal + listBooking6.result.length;
            listBookingResult = [...listBookingResult, ...listBooking6.result];
        }
        if(listBooking7 && listBooking7.result){
            listBookingTotal = listBookingTotal + listBooking7.result.length;
            listBookingResult = [...listBookingResult, ...listBooking7.result];
        }
        
        let listBooking = {
            total: listBookingTotal,
            result: listBookingResult
        }

        let listBookingAll = null;

        if(listBooking && listBooking.result && listBooking.result.length > 0 && dataUser.group !== 'user_ul' && dataUser.group !== 'user_view'){
            listBookingAll = yield call(getListBookingAllReguest, { idWarehouse, dateBookings });
        }
        
        if(listBooking && listBooking.result && listBooking.result.length > 0 && listBookingAll && listBookingAll.result && listBookingAll.result.length > 0){
            listBooking = {
                ...listBooking,
                result: [
                    ...listBooking.result,
                    ...listBookingAll.result
                ]
            }
        }

        let [blockedCells1, blockedCells2, blockedCells3, blockedCells4, blockedCells5, blockedCells6, blockedCells7] = yield all([
            call(getListBlockedReguest, { dateBookings, start: 0 }),
            call(getListBlockedReguest, { dateBookings, start: 50 }),
            call(getListBlockedReguest, { dateBookings, start: 100 }),
            call(getListBlockedReguest, { dateBookings, start: 150 }),
            call(getListBlockedReguest, { dateBookings, start: 200 }),
            call(getListBlockedReguest, { dateBookings, start: 250 }),
            call(getListBlockedReguest, { dateBookings, start: 300 }),
        ]);
        
        let blockedCellsTotal = 0;
        let blockedCellsResult = [];

        if(blockedCells1 && blockedCells1.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells1.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells1.result];
        }
        if(blockedCells2 && blockedCells2.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells2.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells2.result];
        }
        if(blockedCells3 && blockedCells3.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells3.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells3.result];
        }
        if(blockedCells4 && blockedCells4.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells4.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells4.result];
        }
        if(blockedCells5 && blockedCells5.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells5.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells5.result];
        }
        if(blockedCells6 && blockedCells6.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells6.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells6.result];
        }
        if(blockedCells7 && blockedCells7.result){
            blockedCellsTotal = blockedCellsTotal + blockedCells7.result.length;
            blockedCellsResult = [...blockedCellsResult, ...blockedCells7.result];
        }

        let blockedCells = {
            total: blockedCellsTotal,
            result: blockedCellsResult
        }

        // console.log(blockedCells);

        let blockedCellsData = {};

        if(blockedCells && blockedCells.result && blockedCells.result.length > 0){
            blockedCells.result.map(item => {

                if(item.PROPERTY_297 && item.PROPERTY_303){

                    let idDock = Object.values(item.PROPERTY_297)[0],
                        listTimes = Object.values(item.PROPERTY_303);

                    if(blockedCellsData[idDock]){
                        blockedCellsData[idDock].push(...listTimes) 
                    } else {
                        blockedCellsData[idDock] = [...listTimes];
                    }
                }
                
            })   
        }

        yield put(setBlockedCells(blockedCellsData));


        let reserveCellsData = {};

        let warehousesBooking = {};

        let activeWarehouses = [];

        if(dataUser){

            if(infoWarehouses){
                if(!idWarehouse || !idWarehouse.length){
                    Object.keys(infoWarehouses).map(key => {
                        warehousesBooking[key] = [];
                    })
                } else {
                    Object.values(idWarehouse).map(id => {
                        warehousesBooking[id] = [];
                    })
                }
            }

        }

        if(listBooking && listBooking.result && listBooking.result.length > 0){
            listBooking.result.map((item, index) => {

                if(item[fields["PROPERTY_291"].code]){

                    if(activeWarehouses.indexOf(Object.values(item[fields["PROPERTY_291"].code])[0]) < 0){
                        activeWarehouses.push(Object.values(item[fields["PROPERTY_291"].code])[0]);
                    }
                    
                }

                if(item[fields["PROPERTY_285"].code] && item[fields["PROPERTY_311"].code] && item[fields["PROPERTY_323"].code] && item[fields["PROPERTY_325"].code]){
                    let idDock = Object.values(item[fields["PROPERTY_285"].code])[0],
                        status = Object.values(item[fields["PROPERTY_311"].code])[0],
                        // listTimes = Object.values(item.PROPERTY_305),
                        startTime = Object.values(item[fields["PROPERTY_323"].code])[0],
                        endTime = Object.values(item[fields["PROPERTY_325"].code])[0],
                        rangeTime = [];

                    if(startTime !== endTime){
                        for (let index = 0; index <= timeList.indexOf(endTime) - timeList.indexOf(startTime); index++) {
                            
                            rangeTime.push(timeList[timeList.indexOf(startTime) + index]);
                            
                        }
                    } else {
                        rangeTime = [startTime];
                    }
                    
                    if(reserveCellsData[idDock]){
                        if(reserveCellsData[idDock].findIndex(el => el.ID == item.ID) < 0){
                            reserveCellsData[idDock].push({
                                ...item,
                                status,
                                rangeTime,
                                rangeTimeHour: rangeTime.filter((item, index) => index % 2 == 0),
                            }) 
                        }
                    } else {
                        reserveCellsData[idDock] = [{
                            ...item,
                            status,
                            rangeTime,
                            rangeTimeHour: rangeTime.filter((item, index) => index % 2 == 0),
                        }];
                    }
                }

                if(item[fields["PROPERTY_291"].code]){
                    let warehouseID = Object.values(item[fields["PROPERTY_291"].code])[0];
                    if(warehousesBooking[warehouseID]){
                        if(warehousesBooking[warehouseID].findIndex(el => el.ID == item.ID) < 0){
                            warehousesBooking[warehouseID].push({
                                ...item,
                            })
                        }
                    } else {
                        warehousesBooking[warehouseID] = [{
                            ...item,
                        }]
                    }
                }

                

            })  
        }

        if(dataUser){
            if(!idWarehouse || !idWarehouse.length){
                yield put(setActiveWarehouses(Object.keys(infoWarehouses)));
            }
        } else {
            if(!idWarehouse || !idWarehouse.length){
                yield put(setActiveWarehouses(activeWarehouses));
            }   
        }

        yield put(setReserveCells(reserveCellsData));

        yield put(setCheckedDirection(directionDock));

        yield put(getListBookingSuccess(warehousesBooking));

    } catch (error) {

        yield put(getListBookingFailure(error));

    }
}

function* addBlockedCellSaga(action) {
    try {

        const { payload } = action;

        const { docks, dateBookings } = payload;

        let findBlockedDocks = yield all(Object.keys(docks).map(dockId => {
            return call(getListBlockedReguest, { dateBookings, dockId })
        }));

        // console.log(findBlockedDocks);

        // if(findBlockedDocks){

            let response = yield all(Object.keys(docks).map((dockId, index) => {

                if(findBlockedDocks[index].result && findBlockedDocks[index].result.length > 0){

                    let item = findBlockedDocks[index].result[0];

                    let blockedCellsList = [
                        ...docks[dockId].FIELDS.PROPERTY_303
                    ];

                    if(item.PROPERTY_303 && Object.values(item.PROPERTY_303).length > 0){
                        blockedCellsList = [
                            ...blockedCellsList,
                            ...Object.values(item.PROPERTY_303)
                        ]
                    }

                    let objectItem = {
                        'ELEMENT_ID': item.ID,
                        'FIELDS': {
                            ...item,
                            PROPERTY_303: blockedCellsList,
                            PROPERTY_475: '1'
                        }
                    };

                    return call(updateBlockedRequest, objectItem);

                } else {

                    return call(addBlockedCellRequest, docks[dockId]);

                }
            }));
        // }

        // Object.keys(docks).map(dockId => {



        // });

        // const updateResponse = yield all(findBlockedDocks.map(data => {
        //     return all(data.result.map(item => {

        //         let updateTimes = [];
                
        //         Object.values(item.PROPERTY_303).map(timeId => {
        //             if(selectedDays[Object.values(item.PROPERTY_297)[0]].times.indexOf(timeId) < 0){
        //                 updateTimes.push(timeId);
        //             }
        //         })

        //         let objectItem = {
        //             'ELEMENT_ID': item.ID,
        //             'FIELDS': {
        //                 ...item,
        //                 PROPERTY_303: updateTimes
        //             }
        //         };
                                
        //         console.log(objectItem);

        //         return call(updateBlockedRequest, objectItem);

        //     }))
        // }))

        // console.log(findBlockedDocks);

        // const response = yield all(Object.keys(selectedDays).map(dockId => call(getListBlockedReguest, { dateBookings, dockId, timeList: selectedDays[dockId].times }) ))

        // let response = yield all(Object.values(payload).map(dock => call(addBlockedCellRequest, dock)));

        yield put(addBlockedCellSuccess());

    } catch (error) {

        yield put(addBlockedCellFailure(error));

    }
}

function* checkedBlockedCellsSaga(action) {
    try {

        const { payload } = action;

        let { dateBookings, dockId, timeList, timeListAll, bookingID, dragBooking } = payload;

        let [blockedCells, reservedCells, booking] = yield all([
            call(getListBlockedReguest, { dateBookings, dockId, timeList }),
            call(getListBookingAllReguest, { dateBookings, dockId }),
            call(getBookingShipmentRequest, { bookingId: bookingID })
        ]);

        let state = false;

        if(blockedCells.result && !blockedCells.result.length){
            state = true;
        }

        let reservedTimes = [];

        if(reservedCells.result && reservedCells.result.length > 0){

            let stateTime = false;

            if(timeListAll){
                timeListAll.map(timeId => {

                    reservedCells.result.map(reserverdBooking => {
                        if(reserverdBooking[fields["PROPERTY_323"].code] && Object.values(reserverdBooking[fields["PROPERTY_323"].code])[0] == timeId){
                            stateTime = true;
                        }
        
                        if(stateTime){
                            reservedTimes.push(timeId);
                        }
                        
                        if(reserverdBooking[fields["PROPERTY_325"].code] && Object.values(reserverdBooking[fields["PROPERTY_325"].code])[0] == timeId){
                            stateTime = false;
                        }
                    })

                })
            }
            
        }

        if(timeList){
            timeList.map(timeId => {
                if(reservedTimes.indexOf(timeId) >= 0){
                    state = false;
                }
            })
        }

        // if(
        //     booking &&
        //     booking.result && 
        //     booking.result.length > 0
        // ){
        //     booking.result.map((item) => {
        //         if(item.ID == bookingID){
        //             if(getValueProperty(item, 311) !== '' && getValueProperty(item, 323) !== ''){
        //                 state = false;
        //             }
        //             if(dragBooking && getValueProperty(dragBooking, 311) !== getValueProperty(item, 311)){
        //                 state = false;
        //             }
        //         }
        //     })
        // }

        yield put(checkedBlockedCellsSuccess(state));

    } catch (error) {

        yield put(checkedBlockedCellsFailure(error));

    }
}

function* checkedBlockedCellsDateSaga(action) {
    try {

        const { payload } = action;

        let { dateBookings, dockId, timeList, timeListAll, booking, dragBooking } = payload;

        let [blockedCells, reservedCells, bookingResponse] = yield all([
            call(getListBlockedReguest, { dateBookings, dockId, timeList }),
            call(getListBookingAllReguest, { dateBookings, dockId }),
            call(getBookingShipmentRequest, { bookingId: booking.ID })
        ]);

        let state = true;

        if(blockedCells.result && blockedCells.result.length > 0){
            state = false;
        }

        let reservedTimes = [];

        if(reservedCells.result && reservedCells.result.length > 0){

            let stateTime = false;

            if(timeListAll){
                timeListAll.map(timeId => {

                    reservedCells.result.map(reserverdBooking => {
                        if(reserverdBooking[fields["PROPERTY_323"].code] && Object.values(reserverdBooking[fields["PROPERTY_323"].code])[0] == timeId){
                            stateTime = true;
                        }
        
                        if(stateTime){
                            if(reserverdBooking.ID != booking.ID){
                                reservedTimes.push(timeId);
                            }
                        }
                        
                        if(reserverdBooking[fields["PROPERTY_325"].code] && Object.values(reserverdBooking[fields["PROPERTY_325"].code])[0] == timeId){
                            stateTime = false;
                        }
                    })

                })
            }
            
        }

        if(timeList){
            timeList.map(timeId => {
                if(reservedTimes.indexOf(timeId) >= 0){
                    state = false;
                }
            })
        }

        console.log(booking, dragBooking);

        // if(
        //     bookingResponse &&
        //     bookingResponse.result && 
        //     bookingResponse.result.length > 0
        // ){
        //     bookingResponse.result.map((item) => {
        //         if(item.ID == booking.ID){
        //             if(getValueProperty(item, 311) !== '' && getValueProperty(item, 323) !== ''){
        //                 state = false;
        //             }
        //             if(dragBooking && getValueProperty(dragBooking, 311) !== getValueProperty(item, 311)){
        //                 state = false;
        //             }
        //         }
        //     })
        // }

        yield put(checkedBlockedCellsDateSuccess(state));

    } catch (error) {

        yield put(checkedBlockedCellsDateFailure(error));

    }
}

function* updateBlockedSaga(action) {
    try {

        const { payload } = action;

        const { selectedDays, dateBookings } = payload;

        const response = yield all(Object.keys(selectedDays).map(dockId => call(getListBlockedReguest, { dateBookings, dockId, timeList: selectedDays[dockId].times }) ))

        const updateResponse = yield all(response.map(data => {
            return all(data.result.map(item => {

                let updateTimes = [];
                
                Object.values(item.PROPERTY_303).map(timeId => {
                    if(selectedDays[Object.values(item.PROPERTY_297)[0]].times.indexOf(timeId) < 0){
                        updateTimes.push(timeId);
                    }
                })

                let objectItem = {
                    'ELEMENT_ID': item.ID,
                    'FIELDS': {
                        ...item,
                        PROPERTY_303: updateTimes,
                        PROPERTY_475: '1'
                    }
                };
                                
                return call(updateBlockedRequest, objectItem);

            }))
        }))

        yield put(updateBlockedSuccess());

    } catch (error) {

        yield put(updateBlockedFailure(error));

    }
}

function* getFreeCellsSaga(action) {
    try {

        const { payload } = action;

        const { dateBookings, warehouse, duration, booking, method, timeList } = payload;

        let result = {};

        let response = {};


        let blockedDocks = yield all(warehouse.docks.map(dock => call(getListBlockedReguest, { dateBookings, dockId: dock.ID })));

        let reservedBookings = yield all(warehouse.docks.map(dock => call(getListBookingAllReguest, { idWarehouse: warehouse.ID, dateBookings, dockId: dock.ID })));

        // // if(blockedDocks){
        // warehouse.docks.map((common, index) => {

        //     let dataBlocked = blockedDocks[index];
        //     let dataReserved = reservedBookings[index];

        warehouse.docks.map((dock, index) => {

            warehouse.timeWorkList.map((timeId, i) => {

                let state = true;

                if(blockedDocks[index].result){
                    blockedDocks[index].result.map(data => {

                        if(data.PROPERTY_303){
                            if(Object.values(data.PROPERTY_303).indexOf(timeId) >= 0){
                                state = false;
                            }
                        }

                    });
                }

                if(reservedBookings[index].result){
                    reservedBookings[index].result.map(data => {

                        if(data[fields["PROPERTY_323"].code] && data[fields["PROPERTY_325"].code]){
                            let startTime = Object.values(data[fields["PROPERTY_323"].code])[0],
                                endTime = Object.values(data[fields["PROPERTY_325"].code])[0],
                                rangeTime = [];

                            if(startTime !== endTime){
                                for (let index = 0; index <= timeList.indexOf(endTime) - timeList.indexOf(startTime); index++) {
                                    
                                    rangeTime.push(timeList[timeList.indexOf(startTime) + index]);
                                    
                                }
                            } else {
                                rangeTime = [startTime];
                            }

                            if(method == 'update'){
                                if(booking.ID !== data.ID && rangeTime.indexOf(timeId) >= 0){
                                    state = false;
                                }
                            } else {
                                if(rangeTime.indexOf(timeId) >= 0){
                                    state = false;
                                }
                            }
                        }

                    });
                }

                if(state){
                    if(result[dock.ID]){
                        result = {
                            ...result,
                            [dock.ID]: [
                                ...result[dock.ID],
                                {
                                    index: i,
                                    timeId
                                }
                            ]
                        }
                    } else {
                        result = {
                            ...result,
                            [dock.ID]: [{
                                index: i,
                                timeId
                            }]
                        }
                    }
                }

            })

            if(!result[dock.ID] || result[dock.ID] && !result[dock.ID].length){
                result = {
                    ...result,
                    [dock.ID]: null
                }
            }

        })
        
            // blockedDocks.map(data => {

            //     if(data.result && data.result.length > 0){
            //         data.result.map(dock => {

            //             let blockedTimes = Object.values(dock.PROPERTY_303) ? Object.values(dock.PROPERTY_303) : [];
            //             let freeTimeId = [];
        
            //             warehouse.timeWorkList.map((timeId, index) => {
            //                 if(blockedTimes.indexOf(timeId) < 0){
            //                     freeTimeId.push({
            //                         index,
            //                         timeId
            //                     })
            //                 } else if(booking && booking.PROPERTY_305 && Object.values(booking.PROPERTY_305).indexOf(timeId) >= 0){
            //                     freeTimeId.push({
            //                         index,
            //                         timeId
            //                     })
            //                 }
            //             })  

            //             result = {
            //                 ...result,
            //                 [Object.values(dock.PROPERTY_297)[0]]: freeTimeId
            //             }

            //         })
            //     }

            // })

        // }

        //console.log(duration);

        warehouse.docks.map(dock => {

            let dockId = dock.ID;

            let freeCellsDuration = [];

            if(result[dockId]){

                for (let i = 0; i < result[dockId].length; i++) {

                    let state = false;

                    if(result[dockId][i].index + parseInt(duration) <= result[dockId][result[dockId].length - 1].index + 1){

                        for (let z = 0; z < parseInt(duration); z++) {
                            
                            if(result[dockId].findIndex(item => item.index == result[dockId][i].index + z) < 0){
                                state = true;
                            }

                        }

                        if(!state){

                            freeCellsDuration.push(result[dockId][i].timeId);

                        }

                    }
                    
                }    
            
            } else {
                // freeCellsDuration = warehouse.timeWorkList
            }

            response = {
                ...response,
                [dockId]: freeCellsDuration
            }

        })

        yield put(getFreeCellsSuccess(response));

    } catch (error) {

        yield put(getFreeCellsFailure(error));

    }
}

function* addBookingSaga(action) {
    try {

        const { payload } = action;

        // console.log(payload);

        let response = yield call(addBookingRequest, payload);

        // console.log(response);

        // console.log({
        //     NAME: payload.FIELDS.NAME,
        //     [fieldsShipment['PROPERTY_449'].code]: payload.FIELDS[fields["PROPERTY_369"].code] && payload.FIELDS[fields["PROPERTY_369"].code] || '0', // Обьем накладной
        //     [fieldsShipment['PROPERTY_451'].code]: payload.FIELDS[fields["PROPERTY_367"].code] && payload.FIELDS[fields["PROPERTY_367"].code] || '0', // Вес накладной
        //     [fieldsShipment['PROPERTY_453'].code]: payload.FIELDS[fields["PROPERTY_365"].code] && payload.FIELDS[fields["PROPERTY_365"].code] || '0', // Количество паллет по накладной
        //     [fieldsShipment['PROPERTY_455'].code]: payload.FIELDS[fields["PROPERTY_363"].code] && payload.FIELDS[fields["PROPERTY_363"].code] || '0', // Количество коробов по накладной
        //     [fieldsShipment['PROPERTY_461'].code]: payload.FIELDS[fields["PROPERTY_613"].code] && payload.FIELDS[fields["PROPERTY_613"].code] || '0', // Номер накладной
        //     [fieldsShipment['PROPERTY_617'].code]: payload.FIELDS[fields["PROPERTY_291"].code] && payload.FIELDS[fields["PROPERTY_291"].code] || '0' // Cклад
        // })

        let responseShipment = yield call(addBookingShipmentRequest, {
            'ELEMENT_CODE': payload.FIELDS.NAME + '_' + payload.FIELDS[fields["PROPERTY_613"].code],
            'FIELDS': {
                NAME: payload.FIELDS.NAME,
                [fieldsShipment['PROPERTY_449'].code]: payload.FIELDS[fields["PROPERTY_369"].code] && payload.FIELDS[fields["PROPERTY_369"].code] || '0', // Обьем накладной
                [fieldsShipment['PROPERTY_451'].code]: payload.FIELDS[fields["PROPERTY_367"].code] && payload.FIELDS[fields["PROPERTY_367"].code] || '0', // Вес накладной
                [fieldsShipment['PROPERTY_453'].code]: payload.FIELDS[fields["PROPERTY_365"].code] && payload.FIELDS[fields["PROPERTY_365"].code] || '0', // Количество паллет по накладной
                [fieldsShipment['PROPERTY_455'].code]: payload.FIELDS[fields["PROPERTY_363"].code] && payload.FIELDS[fields["PROPERTY_363"].code] || '0', // Количество коробов по накладной
                [fieldsShipment['PROPERTY_461'].code]: payload.FIELDS[fields["PROPERTY_613"].code] && payload.FIELDS[fields["PROPERTY_613"].code] || '0', // Номер накладной
                [fieldsShipment['PROPERTY_617'].code]: payload.FIELDS[fields["PROPERTY_291"].code] && payload.FIELDS[fields["PROPERTY_291"].code] || '0' // Cклад
            }
        });

        // console.log(response, response.result[0].ID);
        // console.log(responseShipment);

        let booking = yield call(getBookingShipmentRequest, { bookingId: response.result[0].ID });

        yield put(addBookingSuccess(booking));

    } catch (error) {

        yield put(addBookingFailure(error));

    }
}

function* updateBookingSaga(action) {
    try {

        const { payload } = action;

        if(payload.UPDATE_FIELDS){

            let numberBooking = Object.values(payload.FIELDS[fields["PROPERTY_341"].code])[0];

            let findAllBookingWithNumber = yield call(getBookingShipmentRequest, { shipment: numberBooking });
    
            if(findAllBookingWithNumber && findAllBookingWithNumber.result && findAllBookingWithNumber.result.length > 1){
                yield all(findAllBookingWithNumber.result.map(item => call(updateBookingRequest, {
                    'ELEMENT_ID': item.ID,
                    'FIELDS': {
                        ...item,
                        ...payload.UPDATE_FIELDS // Изменил поле "В работе" 403 - Y, 405 - N
                    },
                })))
            }
            
        }

        let response = yield call(updateBookingRequest, payload);

        if(payload[fields["PROPERTY_421"].code] && Object.values(payload[fields["PROPERTY_421"].code])[0] == fields['PROPERTY_421'].options['417'].code ){ // Обновляем в списке накладных только если это ручной рейс
            // console.log(payload);

            let payloadShipment = yield call(getShipmentRequest, {
                'ELEMENT_CODE': payload.FIELDS.CODE,
            })

            let responseShipment = yield call(updateBookingCShipmentRequest, {
                'ELEMENT_CODE': payload.FIELDS.CODE,
                'FIELDS': {
                    ...payloadShipment.FIELDS,
                    // NAME: payload.FIELDS.NAME,
                    [fieldsShipment['PROPERTY_449'].code]: payload.FIELDS[fields["PROPERTY_369"].code] && payload.FIELDS[fields["PROPERTY_369"].code] || '-', // Обьем накладной
                    [fieldsShipment['PROPERTY_451'].code]: payload.FIELDS[fields["PROPERTY_367"].code] && payload.FIELDS[fields["PROPERTY_367"].code] || '-', // Вес накладной
                    [fieldsShipment['PROPERTY_453'].code]: payload.FIELDS[fields["PROPERTY_365"].code] && payload.FIELDS[fields["PROPERTY_365"].code] || '-', // Количество паллет по накладной
                    [fieldsShipment['PROPERTY_455'].code]: payload.FIELDS[fields["PROPERTY_363"].code] && payload.FIELDS[fields["PROPERTY_363"].code] || '-', // Количество коробов по накладной
                    [fieldsShipment['PROPERTY_461'].code]: payload.FIELDS[fields["PROPERTY_613"].code] && payload.FIELDS[fields["PROPERTY_613"].code] || '-', // Номер накладной
                    [fieldsShipment['PROPERTY_617'].code]: payload.FIELDS[fields["PROPERTY_291"].code] && payload.FIELDS[fields["PROPERTY_291"].code] || '-' // Cклад

                    // PROPERTY_449: payload.FIELDS[fields["PROPERTY_369"].code], // Обьем накладной
                    // PROPERTY_451: payload.FIELDS[fields["PROPERTY_367"].code], // Вес накладной
                    // PROPERTY_453: payload.FIELDS[fields["PROPERTY_365"].code], // Количество паллет по накладной
                    // PROPERTY_455: payload.FIELDS[fields["PROPERTY_363"].code], // Количество коробов по накладной
                    // PROPERTY_461: payload.FIELDS[fields["PROPERTY_613"].code] // Номер накладной
                }
            })
        }

        yield put(updateBookingSuccess(response));

    } catch (error) {

        yield put(updateBookingFailure(error));

    }
}

function* deleteBookingSaga(action) {
    try {

        const { payload } = action;

        let response = yield call(deleteBookingRequest, payload);

        // console.log(payload);

        if(payload.FIELDS[fields["PROPERTY_341"].code]){

            let numberBooking = Object.values(payload.FIELDS[fields["PROPERTY_341"].code])[0];

            let findAllBookingShipmentsWithNumber = yield call(getBookingShipmentRequest, { shipment: numberBooking, listId: shipmentList });
    
            if(findAllBookingShipmentsWithNumber && findAllBookingShipmentsWithNumber.result && findAllBookingShipmentsWithNumber.result.length > 0){
                yield all(findAllBookingShipmentsWithNumber.result.map(item => call(deleteBookingShipmentRequest, {
                    'ELEMENT_CODE': item.CODE,
                })))   
            }


            let findAllBookingWithNumber = yield call(getBookingShipmentRequest, { shipment: numberBooking });
    
            if(findAllBookingWithNumber && findAllBookingWithNumber.result && findAllBookingWithNumber.result.length > 0){
                yield all(findAllBookingWithNumber.result.map(item => call(deleteBookingRequest, {
                    'ELEMENT_CODE': item.CODE
                })))
                
            }
            
        }

        // if(payload.FIELDS[fields["PROPERTY_421"].code] && Object.values(payload.FIELDS[fields["PROPERTY_421"].code])[0] == fields['PROPERTY_421'].options['417'].id){
        //     let responseShipment = yield call(deleteBookingShipmentRequest, {
        //         'ELEMENT_CODE': payload.CODE + "_" + Object.values(payload.FIELDS[fields["PROPERTY_613"].code])[0],
        //     })
        // }

        yield put(deleteBookingSuccess(response));

    } catch (error) {

        yield put(deleteBookingFailure(error));

    }
}

function* getBookingShipmentSaga(action) {
    try {

        const { payload } = action;

        const { shipment } = payload;

        let response = yield call(getBookingShipmentRequest, { shipment });

        yield put(getBookingShipmentSuccess(response));

    } catch (error) {

        yield put(getBookingShipmentFailure(error));

    }
}

function* searchBookingSaga(action) {
    try {

        const { payload } = action;

        let response = yield call(getBookingShipmentRequest, payload);

        yield put(searchBookingSuccess(response));

    } catch (error) {

        yield put(searchBookingFailure(error));

    }
}

function* searchTypeLocationSaga(action) {
    try {

        const { payload } = action;

        let response = yield call(searchTypeLocationRequest, payload);

        let selectOptions = [];

        let info = {};

        if(response && response.result && response.result.length > 0){
            response.result.map(item => {

                if(selectOptions.findIndex(el => el.value == Object.values(item.PROPERTY_431)[0]) < 0){
                    selectOptions.push({
                        value: item.PROPERTY_431 && Object.values(item.PROPERTY_431)[0] || '',
                        label: item.NAME
                    })
                }

                info = {
                    ...info,
                    [Object.values(item.PROPERTY_431)[0]]: item
                } 

            })
        }

        yield put(searchTypeLocationSuccess({
            info,
            selectOptions
        }));

    } catch (error) {

        yield put(searchTypeLocationFailure(error));

    }
}

function* searchUsersProviderSaga(action) {
    try {

        const { payload } = action;

        // let response = yield call(searchUsersProviderRequest, {
        //     code: payload.code
        // });

        let [responseCode, responseName] = yield all([
            call(searchUsersProviderRequest, {
                code: payload.code
            }),
            call(searchUsersProviderRequest, {
                name: payload.code
            })
        ]);

        let responseTotal = 0;
        let responseResult = [];

        if(responseCode && responseCode.result){
            responseTotal = responseTotal + responseCode.result.length;
            responseResult = [...responseResult, ...responseCode.result];
        }
        if(responseName && responseName.result){
            responseTotal = responseTotal + responseName.result.length;
            responseResult = [...responseResult, ...responseName.result];
        }

        let response = {
            total: responseTotal,
            result: responseResult
        }

        let selectOptions = [{
            value: '50438571',
            label: 'Самовывоз'
        }];

        let info = {
            50438571: {
                NAME: 'Самовывоз',
                PROPERTY_457: { 0: 50438571 }
            }
        };

        if(response && response.result && response.result.length > 0){
            response.result.map(item => {

                if(selectOptions.findIndex(el => el.value == Object.values(item.PROPERTY_457)[0]) < 0){
                    selectOptions.push({
                        value: item.PROPERTY_457 && Object.values(item.PROPERTY_457)[0] || '',
                        label: item.NAME
                    })
                }

                info = {
                    ...info,
                    [Object.values(item.PROPERTY_457)[0]]: item
                } 

            })
        }

        yield put(searchUsersProviderSuccess({
            info,
            selectOptions
        }));

    } catch (error) {

        yield put(searchUsersProviderFailure(error));

    }
}

function* changeCellsSaga(action) {
    try {

        const { payload } = action;

        let { dateBookings, dockId, timeList } = payload;

        let [blockedCells, reservedCells] = yield all([
            call(getListBlockedReguest, { dateBookings, dockId, timeList }),
            call(getListBookingAllReguest, { dateBookings, dockId }),
        ]);

        yield put(changeCellsSuccess());

    } catch (error) {

        yield put(changeCellsFailure(error));

    }
}

export function* bookingWatcher() {
    yield takeEvery(getServprovName.toString(), getServprovNameSaga);
    yield takeEvery(getListBooking.toString(), getListBookingSaga);
    yield takeEvery(addBlockedCell.toString(), addBlockedCellSaga);
    yield takeEvery(updateBooking.toString(), updateBookingSaga);
    yield takeEvery(addBooking.toString(), addBookingSaga);
    yield takeEvery(checkedBlockedCells.toString(), checkedBlockedCellsSaga);
    yield takeEvery(checkedBlockedCellsDate.toString(), checkedBlockedCellsDateSaga);
    yield takeEvery(updateBlocked.toString(), updateBlockedSaga);
    yield takeEvery(getFreeCells.toString(), getFreeCellsSaga);
    yield takeEvery(getBookingShipment.toString(), getBookingShipmentSaga);
    yield takeEvery(deleteBooking.toString(), deleteBookingSaga);
    yield takeLatest(searchBooking.toString(), searchBookingSaga);
    yield takeLatest(searchTypeLocation.toString(), searchTypeLocationSaga);
    yield takeLatest(searchUsersProvider.toString(), searchUsersProviderSaga);
    yield takeLatest(changeCells.toString(), changeCellsSaga);
}
