import {
    requestsList,
    domainMain,
    isLocalhost, 
    paramsLocalhostApi, 
  } from "../../constants/config";
  
  export const getListRequestsReguest = ({ start, filters, pageSize, initData }) => {
  
    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": requestsList,
      }

      if(filters){
        params['FILTER'] = filters;
      }
    
      return new Promise((resolve, reject) => {
    
          fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': isLocalhost && 'text/plain' || 'application/json'
              },
              body: JSON.stringify(params)
          })
              .then(response => response.json())
              .then(result => {
                  if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                      // redirectAuth();
                  } else {
                      resolve(result);
                      console.log(result);
                  }
              })
              .catch(error => {
                  console.log(error);
                  reject(error);
              });
    
      });
  };

  export const getItemRequestsReguest = ({ id }) => {
  
    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": requestsList,
      }

      if(id){
        params['FILTER'] = {
            'ID': id
        };
      }
    
      return new Promise((resolve, reject) => {
    
          fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': isLocalhost && 'text/plain' || 'application/json'
              },
              body: JSON.stringify(params)
          })
              .then(response => response.json())
              .then(result => {
                  if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                      // redirectAuth();
                  } else {
                      resolve(result);
                      console.log(result);
                  }
              })
              .catch(error => {
                  console.log(error);
                  reject(error);
              });
    
      });
  };
  

  export const updateItemRequestsReguest = ({ props }) => {
  
    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": requestsList,
      }

      if(props){
        params['ELEMENT_ID'] = props.ID;
        params['FIELDS'] = props;
      }
    
      return new Promise((resolve, reject) => {
    
          fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': isLocalhost && 'text/plain' || 'application/json'
              },
              body: JSON.stringify(params)
          })
              .then(response => response.json())
              .then(result => {
                  if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                      // redirectAuth();
                  } else {
                      resolve(result);
                      console.log(result);
                  }
              })
              .catch(error => {
                  console.log(error);
                  reject(error);
              });
    
      });
  };
  

  export const addItemRequestsReguest = (props) => {
  
    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": requestsList,
    }

    console.log(props);

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });
  };