import * as React from "react";
import BreadCrumbs from "../../../modules/breadCrumbs";
import Btns from "./modules/btns";
import Filter from "./modules/filter";
import ListRequests from "./modules/list";
import {
  notification
} from 'antd';
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getListRequests, getListRequestsClear, getListRequestsIdle, updateItemRequestsIdle } from "../../../modules/requests/actions";

const Requests = () => {

  const [breadcrumb] = React.useState([
    { name: 'Сервисы', link: `/` },
    { name: 'Запросы накладных и документов', link: `/requests` }
  ]);

  const dispatch = useDispatch();

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (type, description, message) => {
    if (message) {
      api[type]({
        message: message,
        description
      });
    } else {
      api[type]({
        message: type === 'error' ? 'Ошибка' : 'Успешно',
        description
      });
    }
  };

  const status = useSelector(state => state.requests.status);

  useEffect(() => {
    if(status == 'IDLE'){
      dispatch(getListRequestsClear());
      dispatch(getListRequests({}));
    }
  }, [])

  useEffect(() => {
    if(status == 'SUCCESS'){
      dispatch(getListRequestsIdle());
    }
  }, [status])

  return <>{contextHolder}<main className="main">
    <div className="main__content container">
      <div className="main__wrap">
        <BreadCrumbs breadcrumb={breadcrumb} />
        <Btns />
      </div>
      <h1 className="section-title main__section-title">Запросы накладных и документов</h1>
      <Filter openNotificationWithIcon={openNotificationWithIcon} />
      <ListRequests />
    </div>
  </main></>;
};
export default Requests;
