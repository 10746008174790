import * as React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import BtnsForm from '../btnsForm';
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";

const customStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: "60px",
  })
};

const OtherForm = ({ setSendData, requestItem, listFlightNumber, autocomplete }) => {

  const dispatch = useDispatch();
  // const defaultData: SelectedInitData[] | [] = useSelector(selectInitData);
  const defaultData = [];
  const warehouses = useSelector(warehousesOptionsSelector);

  const { control, handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: requestItem,
  });
  
  
  const onSubmit = data => {
    setSendData({...data, ...{TypeRequest: 'Иное'}});
  };

  React.useEffect(() => {
    if (defaultData.length === 0) {
        // dispatch(initData());
    }
  }, []);

  React.useEffect(() => {
    if (watch("FlightNumber") && watch("FlightNumber")['value']) {
      const flightNumber = watch("FlightNumber")['value'];
      const obj = autocomplete.find(el => el.FlightNumber === flightNumber);
        setValue("Warehouse", obj.Warehouse);
        setValue("TnNumber", obj.TnNumber);
        setValue("NameClient", obj.NameClient);
        setValue("DeliveryAddress", obj.DeliveryAddress);
        setValue("Driver", obj.Driver);
        setValue("CarNumber", obj.CarNumber);
    }
  }, [watch("FlightNumber")])

  return <div className="block-tabs__block active">
      <form onSubmit={handleSubmit(onSubmit)} className="form form--request form--request-simple block-tabs__block__form" action="#">
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
                <label className={errors.FlightNumber && "label__form__input error" || "label__form__input"}>Номер рейса {errors.FlightNumber && "*"}</label>
                {listFlightNumber.length > 0 && (
                  <Controller
                    name="FlightNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Select 
                      value={field.value || ""}
                      styles={customStyles}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Номер рейса"
                      options={listFlightNumber} 
                    />}
                  />
                ) || (
                  <input {...register('FlightNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер рейса" />
                )}
            </div>
            <div className="form__col">
            <label className={errors.Warehouse && "label__form__input error" || "label__form__input"}>Склад отгрузки {errors.Warehouse && "*"}</label>
            <Controller
                name="Warehouse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Select 
                value={field.value}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Склад отгрузки" 
                options={warehouses} 
                />}
            />
            </div>
            <div className="form__col">
            <label className={errors.TnNumber && "label__form__input error" || "label__form__input"}>Номер ТН {errors.TnNumber && "*"}</label>
            <input {...register('TnNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер ТН" />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
            <label className={errors.NameClient && "label__form__input error" || "label__form__input"}>Наименование клиента {errors.NameClient && "*"}</label>
            <input {...register('NameClient', { required: true })} className="input form__input"  type="text"   placeholder="Наименование клиента" />
            </div>
            <div className="form__col">
            <label className={errors.DeliveryAddress && "label__form__input error" || "label__form__input"}>Адрес доставки {errors.DeliveryAddress && "*"}</label>
            <input {...register('DeliveryAddress', { required: true })} className="input form__input"  type="text"   placeholder="Адрес доставки" />
            </div>
        </div>
      </div>
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <label className={errors.Driver && "label__form__input error" || "label__form__input"}>Водитель {errors.Driver && "*"}</label>
            <input {...register('Driver', { required: true })} className="input form__input"  type="text"   placeholder="Водитель" />
            </div>
            <div className="form__col">
            <label className={errors.PhoneDriver && "label__form__input error" || "label__form__input"}>Номер телефона водителя {errors.PhoneDriver && "*"}</label>
            <input {...register('PhoneDriver', { required: true })} className="input form__input"  type="text"   placeholder="Номер телефона водителя" />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
            <label className={errors.CarNumber && "label__form__input error" || "label__form__input"}>Номер авто {errors.CarNumber && "*"}</label>
            <input {...register('CarNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер авто" />
            </div>
            <div className="form__col">
            <label className={errors.CarBrand && "label__form__input error" || "label__form__input"}>Марка машины {errors.CarBrand && "*"}</label>
            <input {...register('CarBrand', { required: true })} className="input form__input"  type="text"   placeholder="Марка машины" />
            </div>
        </div>
        <div className="form__row">
            <div className="form__col">
              <label className={errors.Other && "label__form__input error" || "label__form__input"}>Подробно опишите запрос {errors.Other && "*"}</label>
              <textarea {...register('Other', { required: true })} className="input form__textarea" placeholder="Подробно опишите запрос"></textarea>
            </div>
        </div>
      </div>
      <div className="form__container">
        <BtnsForm id={requestItem && requestItem.ID} setValue={setValue} />
      </div>
      </form>
  </div>;
};
export default OtherForm;
