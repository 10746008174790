import { createSelector } from "reselect";

import dayjs from 'dayjs';


import { getValueProperty, removeDuplicates } from '../../constants/config';

const getState = (state) => state.po.data;
const initData = (state) => state.initData;

const status = {
  97: 'Новый',
  99: 'Счет выставлен'
}

export const poSelector = createSelector([getState, initData], (items, initData) => {
  let retData = [];
  if(items && items.length > 0 && initData){
    retData = items.map((item, index) => {

        let ShippingPoint = '';

        if(initData.warehouses[getValueProperty(item, 157)] && initData.warehouses[getValueProperty(item, 157)].NAME){
          ShippingPoint = initData.warehouses[getValueProperty(item, 157)].NAME;
        }

        let TruckType = '';

        if(initData && 
            initData.typeTrucks && 
            initData.typeTrucks.typeTrucksInfo && 
            initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)] && 
            initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)].NAME
          ){
          TruckType = initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)].NAME
        }

        return {
            ID: item.ID,
            View: getValueProperty(item, 187),
            Created: getValueProperty(item, 746),
            Status: status[getValueProperty(item, 185)],
            ShippingPoint: ShippingPoint,
            ShipmentNumber: getValueProperty(item, 163),
            ServiceProviderCode: getValueProperty(item, 159),
            ServiceProviderName: getValueProperty(item, 161),
            POnumber: getValueProperty(item, 165),
            POdate: getValueProperty(item, 167).split(' ')[0],
            PlannedCost: getValueProperty(item, 169),
            Currency: getValueProperty(item, 171),
            ShipTo: getValueProperty(item, 173),
            ShipToName: getValueProperty(item, 175),
            DeliveryNumber: getValueProperty(item, 177),
            LoadingDate: getValueProperty(item, 179),
            TruckType: TruckType,
            PalletQTy: getValueProperty(item, 183),
        };
    });
    }
  return retData;
});

const getStateItems = (state) => state.poItem.dataItem;

export const poItemSelector = createSelector([getStateItems, initData], (items, initData) => {
  let retData = {};
  if(items && items.length > 0 && initData){
    items.map((item, index) => {

        let ShippingPoint = '';

        if(initData.warehouses[getValueProperty(item, 157)] && initData.warehouses[getValueProperty(item, 157)].NAME){
          ShippingPoint = initData.warehouses[getValueProperty(item, 157)].NAME;
        }

        let TruckType = '';

        if(initData && 
            initData.typeTrucks && 
            initData.typeTrucks.typeTrucksInfo && 
            initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)] && 
            initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)].NAME
          ){
          TruckType = getValueProperty(initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)], 463);
        }

        retData = {
            ID: item.ID,
            View: getValueProperty(item, 187),
            Created: getValueProperty(item, 746),
            Status: status[getValueProperty(item, 185)],
            ShippingPoint: ShippingPoint,
            ShipmentNumber: getValueProperty(item, 163),
            ServiceProviderCode: getValueProperty(item, 159),
            ServiceProviderName: getValueProperty(item, 161),
            POnumber: getValueProperty(item, 165),
            POdate: getValueProperty(item, 167).split(' ')[0],
            PlannedCost: getValueProperty(item, 169),
            Currency: getValueProperty(item, 171),
            ShipTo: removeDuplicates(getValueProperty(item, 173) + '; '),
            ShipToName: removeDuplicates(getValueProperty(item, 175) + '; '),
            DeliveryNumber: removeDuplicates(getValueProperty(item, 177) + '; '),
            LoadingDate: getValueProperty(item, 179),
            TruckType: TruckType,
            PalletQTy: getValueProperty(item, 183),
        };
    });
  }
  return retData;
});