import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import {
    getListPoReguest,
    getItemPoReguest,
    updateItemPoReguest,
} from './api'

import {
    getListPo,
    getListPoSuccess,
    getListPoFailure,
    getListPoIdle,
    getItemPo,
    getItemPoSuccess,
    getItemPoFailure,
    getItemPoIdle,
    updateItemPo,
    updateItemPoSuccess,
    updateItemPoFailure,
    updateItemPoIdle,
    
} from './actions'

function* getListPoSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(getListPoReguest, payload);

        yield put(getListPoSuccess(response));

    } catch (error) {

        yield put(getListPoFailure(error));

    }
}

function* getItemPoSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(getItemPoReguest, payload);

        yield put(getItemPoSuccess(response));

    } catch (error) {

        yield put(getItemPoFailure(error));

    }
}

function* updateItemPoSaga(action) {
    try {

        const { payload } = action;

        let response;
        if(payload.updatesItems){
            response = yield all(payload.updatesItems.map(item => call(updateItemPoReguest, { props: item })))
        } else {
            response = yield call(updateItemPoReguest, payload);            
        }

        yield put(updateItemPoSuccess(response));

    } catch (error) {

        yield put(updateItemPoFailure(error));

    }
}

export function* poWatcher() {
    yield takeLatest(getListPo.toString(), getListPoSaga);
    yield takeLatest(getItemPo.toString(), getItemPoSaga);
    yield takeLatest(updateItemPo.toString(), updateItemPoSaga);
}