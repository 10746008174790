import {createActions} from "redux-actions";

const {
    exportFormSearchWarehouse,
    exportFormSearchWarehouseSuccess,
    exportFormSearchWarehouseFailure,
    exportFormSearchWarehouseIdle,
    exportFormSearchWarehouseFind,
    exportFormSearchBooking,
    exportFormSearchBookingSuccess,
    exportFormSearchBookingFailure,
    exportFormSearchBookingIdle,
    exportFormSearchBookingFind,
    exportFormSearchDestination,
    exportFormSearchDestinationSuccess,
    exportFormSearchDestinationFailure,
    exportFormSearchDestinationIdle,
    exportFormSearchDestinationFind,
    getExcel,
    getExcelSuccess,
    getExcelFailure,
    getExcelIdle
} = createActions(
    'EXPORT_FORM_SEARCH_WAREHOUSE',
    'EXPORT_FORM_SEARCH_WAREHOUSE_SUCCESS',
    'EXPORT_FORM_SEARCH_WAREHOUSE_FAILURE',
    'EXPORT_FORM_SEARCH_WAREHOUSE_IDLE',
    'EXPORT_FORM_SEARCH_WAREHOUSE_FIND',
    'EXPORT_FORM_SEARCH_BOOKING',
    'EXPORT_FORM_SEARCH_BOOKING_SUCCESS',
    'EXPORT_FORM_SEARCH_BOOKING_FAILURE',
    'EXPORT_FORM_SEARCH_BOOKING_IDLE',
    'EXPORT_FORM_SEARCH_BOOKING_FIND',
    'EXPORT_FORM_SEARCH_DESTINATION',
    'EXPORT_FORM_SEARCH_DESTINATION_SUCCESS',
    'EXPORT_FORM_SEARCH_DESTINATION_FAILURE',
    'EXPORT_FORM_SEARCH_DESTINATION_IDLE',
    'EXPORT_FORM_SEARCH_DESTINATION_FIND',
    'GET_EXCEL',
    'GET_EXCEL_SUCCESS',
    'GET_EXCEL_FAILURE',
    'GET_EXCEL_IDLE'
)

export {
    exportFormSearchWarehouse,
    exportFormSearchWarehouseSuccess,
    exportFormSearchWarehouseFailure,
    exportFormSearchWarehouseIdle,
    exportFormSearchWarehouseFind,
    exportFormSearchBooking,
    exportFormSearchBookingSuccess,
    exportFormSearchBookingFailure,
    exportFormSearchBookingIdle,
    exportFormSearchBookingFind,
    exportFormSearchDestination,
    exportFormSearchDestinationSuccess,
    exportFormSearchDestinationFailure,
    exportFormSearchDestinationIdle,
    exportFormSearchDestinationFind,
    getExcel,
    getExcelSuccess,
    getExcelFailure,
    getExcelIdle
}