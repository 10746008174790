import { 
    appID, 
    domain, 
    domainMain, 
    redirectAuth,
    isLocalhost, 
    paramsLocalhostApi
} from "../../constants/config";

let headers = {
    'Accept': 'application/json',
    'Content-Type': isLocalhost && 'text/plain' || 'application/json',
    'Set-Cookie': document.cookie,
}

export const getListWarehousesReguest = () => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 33,
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getFieldsReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/field.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListDocksReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 49,
                ...params
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListTimeReguest = () => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 55,
                'ELEMENT_ORDER': { 'SORT': 'ASC' }
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListEquipmentsGroupsReguest = () => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 59,
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListTypeTrucksReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 61,
                ...params
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListUsersReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/users.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const getListLocationsReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 65,
                ...params
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListProviderReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers,
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 69,
                ...params
            })
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}
