export const requestItem = {
    ID: 454,
    Created: "17.07.2023",
    FlightNumber: 453454,
    TypeRequest: "Запрос документов по проверке безопасности",
    Warehouse: "HG456",
    TnNumber: 4534,
    NameClient: "Test",
    DeliveryAddress: "Test",
    CarBrand: "Test",
    CarNumber: "Test",
    Driver: "Test",
    PhoneDriver: "Test",
    DateTimeDelivery: "17.07.2023",
    ActualDateTime: "17.07.2023",
    ReasonRestoringDocuments: "Test",
    DocumentTransferMethod: "Test",
    TransferDocuments: "Test",
    DocumentsNeedRestore: "Test",
    Article: "Test",
    QuantityBoxes: "Test",
    ScanAct: "Test",
    DriverAcceptance: "Test",
    Articles: "Test",
    AxleLoadSensor: "Test",
    SensorShowOverload: "Test",
    CarArriveOnTime: "Test",
    ReasonNonLoading: "Test",
    NumberInvoiceWhichLoadingRegistered: 453453434,
    TnNoEmailNotification: "Test",
    ReasonBeingLate: "Test",
    WhenLoadingUnloading: "Test",
    Other: "Test",
    DocumentsNeedProvide: "Test",
    TypeTrack: "Test",
    TKRole: "Экспедитор",
    View: false,
    Status: "Новый",
    Author: 777,
    Files: [],
    Partner: "Test"
};