import { 
    appID, 
    dockBookingListId, 
    domain, 
    domainMain, 
    fields, 
    shipmentList, 
    redirectAuth, 
    isLocalhost, 
    paramsLocalhostApi 
} from "../../constants/config";

export const getListBookingAllReguest = ({ idWarehouse, dateBookings, dockId, timeList, start }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(start){
        params = {
            ...params,
            start
        }
    }

    if(idWarehouse || dateBookings || dockId || timeList){
        params = {
            ...params,
            "FILTER": {}
        }

        if(idWarehouse && idWarehouse.length > 0 && idWarehouse[0] !== '*'){
            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_291"].code] : idWarehouse
            }
        }
        if(dateBookings){

            // console.log(dateBookings);

            // let dateBookingsArray = dateBookings.split('-'),
            //     dateBookingsFormat = dateBookingsArray[2]+'.'+dateBookingsArray[1]+'.'+dateBookingsArray[0];

            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_621"].code] : dateBookings
            }
        }
        if(dockId){

            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_285"].code] : dockId
            }

        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listdockbookingtimeslots.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const getListBookingReguest = ({ idWarehouse, dateBookings, dockId, timeList, start }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(start){
        params = {
            ...params,
            start
        }
    }

    if(idWarehouse || dateBookings || dockId || timeList){
        params = {
            ...params,
            "FILTER": {}
        }

        if(idWarehouse && idWarehouse.length > 0 && idWarehouse[0] !== '*'){
            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_291"].code] : idWarehouse
            }
        }
        if(dateBookings){

            // console.log(dateBookings);

            // let dateBookingsArray = dateBookings.split('-'),
            //     dateBookingsFormat = dateBookingsArray[2]+'.'+dateBookingsArray[1]+'.'+dateBookingsArray[0];

            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_621"].code] : dateBookings
            }
        }
        if(dockId){

            params["FILTER"] = {
                ...params["FILTER"],
                [fields["PROPERTY_285"].code] : dockId
            }

        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const getBookingShipmentRequest = ({ shipment, bookingId, warehouse, listId }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(listId){
        params = {
            ...params,
            "IBLOCK_ID": listId
        }
    }

    if(shipment || bookingId || warehouse){
        params = {
            ...params,
            "FILTER": {}
        }

        if(shipment){

            params["FILTER"] = {
                ...params["FILTER"],
                "^NAME" : shipment
            }

        }

        if(bookingId){
            params['FILTER'] = {
                ...params["FILTER"],
                "ID" : bookingId
            }
        }

        if(warehouse && warehouse[0] !== '*'){
            params['FILTER'] = {
                ...params['FILTER'],
                [fields["PROPERTY_291"].code]: warehouse
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const getListDocksWarehouseReguest = ({ idWarehouse }) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 49,
                "FILTER": {
                    "PROPERTY_271" : idWarehouse
                }
            })
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const getListBlockedReguest = ({ dateBookings, dockId, timeList, start }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 53,
    }

    if(start){
        params = {
            ...params,
            start
        }
    }

    if(dateBookings || dockId || timeList){

        params = {
            ...params,
            "LOGIC": "AND",
            "FILTER": {}
        }

        if(dateBookings){

            // let dateBookingsArray = dateBookings.split('-'),
            // dateBookingsFormat = dateBookingsArray[2]+'.'+dateBookingsArray[1]+'.'+dateBookingsArray[0];

            params["FILTER"] = {
                ...params["FILTER"],
                "PROPERTY_295" : dateBookings
            }

        }

        if(dockId){

            params["FILTER"] = {
                ...params["FILTER"],
                "PROPERTY_297" : dockId
            }

        }

        if(timeList){

            params["FILTER"] = {
                ...params["FILTER"],
                "PROPERTY_303" : timeList
            }

        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}

export const addBlockedCellRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 53,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

}

export const addBookingRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

}



export const getPerevozchikRequest = ({ code }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 69,
    }

    if(code){
        params = {
            ...params,
            "FILTER": {}
        }

        if(code){

            params["FILTER"] = {
                ...params["FILTER"],
                "PROPERTY_457" : code
            }

        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}



export const getShipmentRequest = ({ code }) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": shipmentList,
    }

    if(code){
        params = {
            ...params,
            "FILTER": {}
        }

        if(code){

            params["FILTER"] = {
                ...params["FILTER"],
                "ELEMENT_CODE" : code
            }

        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}

export const addBookingShipmentRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": shipmentList,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                resolve(result);
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

}

export const updateBlockedRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 53,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}

export const updateBookingRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}

export const updateBookingCShipmentRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": shipmentList,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listedit.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}

export const deleteBookingRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": dockBookingListId,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listdel.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}

export const deleteBookingShipmentRequest = (props) => {

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": shipmentList,
    }

    if(props){
        params = {
            ...params,
            ...props
        }
    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/listdel.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

}

export const getDefaulBlockedCellsReguest = (params) => {

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify({
                "IBLOCK_TYPE_ID": "lists",
                "IBLOCK_ID": 63,
                ...params
            })
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                reject(error);
            });
    
    });

    
}



export const searchTypeLocationRequest = ({ code }) => {

    // console.log(code);

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 65,
    }

    if(code){
        params = {
            ...params,
            "FILTER": {}
        }

        if(code){
            params['FILTER'] = {
                ...params['FILTER'],
                '^PROPERTY_431': code
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}



export const searchUsersProviderRequest = (payload) => {

    const { name, code } = payload;

    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": 69,
    }

    if(code || name){
        params = {
            ...params,
            // "LOGIC": "OR",
            "FILTER": {
                // 
            }
        }

        if(code){
            params['FILTER'] = {
                ...params['FILTER'],
                '^PROPERTY_457': code
            }
        }

        if(name){
            params['FILTER'] = {
                ...params['FILTER'],
                '^NAME': name,
            }
        }

    }

    return new Promise((resolve, reject) => {

        fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': isLocalhost && 'text/plain' || 'application/json'
            },
            body: JSON.stringify(params)
        })
            .then(response => response.json())
            .then(result => {
                if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                    // redirectAuth();
                } else {
                    resolve(result);
                }
            })
            .catch(error => {
                console.log(error);
                reject(error);
            });
    
    });

    
}