import * as React from "react";
import { Link } from "react-router-dom";


const Btns = ({ id }) => {
  return <div className="main__inner"> 
  <Link to={`/add/${id}`} className="btn main__btn">Редактировать</Link>
</div>;
};
export default Btns;
