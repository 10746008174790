import { combineReducers } from "redux";
import { authApp } from "./modules/authApp/reducer";
import { initData } from "./modules/initData/reducer";
import { booking } from "./modules/booking/reducer";
import { cod } from "./modules/cod/reducer";
import { po, poItem } from "./modules/po/reducer";
import { requests, requestsItem } from './modules/requests/reducer'
import { exportForm } from './modules/export/reducer';
import { chat } from "./modules/chat/reducer";

export default combineReducers({
  authApp,
  booking,
  initData,
  exportForm,
  cod,
  po,
  poItem,
  chat,
  requests, 
  requestsItem
});
