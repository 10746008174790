import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import { getListDateBookingAllReguest, updateBookingDateRequest } from "./api";

import {
    handleFixDate,
    handleFixDateSuccess,
    handleFixDateFailure,
    handleFixDateIdle,
    handleFixDateInit,
} from "./actions";

function* getListDateBookingSaga(action) {
    try {

        let [listBooking1, listBooking2, listBooking3, listBooking4, listBooking5, listBooking6] = yield all([
            call(getListDateBookingAllReguest, { start: 0 }),
            call(getListDateBookingAllReguest, { start: 50 }),
            call(getListDateBookingAllReguest, { start: 100 }),
            call(getListDateBookingAllReguest, { start: 150 }),
            call(getListDateBookingAllReguest, { start: 200 }),
            call(getListDateBookingAllReguest, { start: 250 }),
        ]);

        [...listBooking1.result, ...listBooking2.result, ...listBooking3.result, ...listBooking4.result, ...listBooking5.result, ...listBooking6.result].map(item => {
            if(item.PROPERTY_281 && item.PROPERTY_621 && Object.values(item.PROPERTY_281)[0] !== Object.values(item.PROPERTY_621)[0]){
                // console.log(item);
            }
        })

        // console.log(listBooking1, listBooking2, listBooking3, listBooking4, listBooking5, listBooking6);

        // yield all([...listBooking1.result, ...listBooking2.result, ...listBooking3.result, ...listBooking4.result, ...listBooking5.result, ...listBooking6.result].map(item => call( updateBookingDateRequest, {
        //     'ELEMENT_ID': item.ID,
        //     'FIELDS': {
        //         ...item,
        //         PROPERTY_621: item.PROPERTY_439 && Object.values(item.PROPERTY_439)[0] != Object.values(item.PROPERTY_621)[0] && item.PROPERTY_439 || item.PROPERTY_621
        //     },
        // } )));

        // PROPERTY_439

        yield put(handleFixDateSuccess());


    } catch (error) {

        yield put(handleFixDateFailure());

    }
}

export function* handleFixDateWatcher() {
    yield takeEvery(handleFixDate.toString(), getListDateBookingSaga);
}