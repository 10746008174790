export const dataInit = [
  // {
  //   "key": 1,
  //   "ShipmentId": "5060358509",
  //   "SourceLocation": "Z06H",
  //   "StopNumber": "3",
  //   "Location": "15622597",
  //   "LocationName": "ООО «ЮГПРОДТОРГ»",
  //   "LocationCity": "КРАСНОДАР",
  //   "LocationObl": "КРАСНОДАРСКИЙ КРАЙ",
  //   "LocationAddress": "УЛ. НОВОРОССИЙСКАЯ, Д. 55",
  //   "LddDateTime": "15.09.2023 09:00",
  //   "ActualArrivalDateTime": null,
  //   "ActualDepartureDateTime": null,
  //   "Comment": null,
  //   "CarrierCode": "50581908",
  //   "Email": null,
  //   "LastUpdateDateTime": null,
  //   "Cost": null
  // },
];