import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getMessages, addMessage } from "../../store/ducks/chat/actions";
// import { selectMessages } from "../../store/ducks/chat/selector";
import iconAvatar from '../../../assets/img/icons/Avatar.svg';
import dayjs from 'dayjs';

import cx from "classnames";
import { useForm, Controller } from "react-hook-form";
import FilesUpload from "../filesUpload";
import { addMessage, addMessageIdle, getMessages, getMessagesIdle } from "../../../modules/chat/actions";
import { messagesSelector } from "../../../modules/chat/selector";


const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}


const Chat = ({ id, list, request, openNotificationWithIcon }) => {

  const dispatch = useDispatch();
  const messages = useSelector(messagesSelector);
  const dataUser = useSelector(state => state.authApp.init);
  const typeUser = useSelector(state => state.authApp.typeUser);
  const { control, handleSubmit, register, reset } = useForm();
  // const links = useSelector((state: State) => state.files.links);
  const links = "";
  const [linksStr, setLinksStr] = React.useState("");
  const statusMessages = useSelector(state => state.chat.statusMessages)

  React.useEffect(() => {
    if(statusMessages == 'SUCCESS'){
      dispatch(getMessagesIdle());
    }
  }, [statusMessages])

  React.useEffect(() => {
    if(statusMessages == 'IDLE'){
      dispatch(getMessages({
        id
      }))
    }
  }, []);

  const statusAddMessage = useSelector(state => state.chat.statusAddMessage);

  React.useEffect(() => {
    if(statusAddMessage == 'SUCCESS'){
      dispatch(addMessageIdle());
    }
  }, [statusAddMessage])

  const onSubmit = data => {
    if (data['Message'] || linksStr) {
      data['NAME'] = `Сообщение Item ${id}`;
      data['PROPERTY_129'] = request ? String(id) : "";
      data['PROPERTY_747'] = !request ? String(id) : "";
      data['PROPERTY_749'] = dataUser && dataUser.group;
      data['PROPERTY_135'] = data['Message'];
      // data['Files'] = linksStr;
      reset();
      // console.log(data);
      if(statusAddMessage == 'IDLE'){
        dispatch(addMessage({
          'ELEMENT_CODE': id + '_' + dayjs().valueOf(),
          'FIELDS': data
        }));
      }
    } else {
      openNotificationWithIcon("error", 'Тескт сообщения обязателен или прикрепите файл');
    }
  };

  React.useEffect(() => {
    let str = "";
    links.length > 0 && links.map(link => {
      str += `${link};`;
    });
    setLinksStr(str.slice(0, -1));
  }, [links])

  return <div className="chat application-table__chat">
    {messages && Object.keys(messages).length > 0 && (
      <div className="chat__content">
        {Object.keys(messages).map((key) => (
          <>
            <span className="chat__date">{key}</span>
            <div className="chat__messages">
              {messages[key].map((message) => (
                <div key={message.ID} className={cx(
                  "message chat__message",
                  message.Author === dataUser.id && "message--answer"
                )}>
                  <div className="message__comment">
                    <div className="message__comment-top">
                      <span className="message__date">{message.CreatedTime}</span>
                      <span className="message__position"></span>
                      {/* <span className="message__title">{users[message.Author] && users[message.Author]['displayName']}</span> */}
                    </div>
                    <div className="message__comment-body">
                      <p className="message__text" dangerouslySetInnerHTML={{ __html: message.Message }} />
                      {message.Files && message.Files.length > 0 && (
                        <>
                          {message.Message && <br />}
                          <div className="message__files">
                            {message.Files.map((file, idx) => (
                              <a target="_blank" rel="noreferrer" key={idx} className="message_file" href={file.link}>{file.name}</a>
                            ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="message__avatar">
                    <img src={iconAvatar} className="message__icon" alt="avatar" />
                  </div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
    )}
    <div className="chat__bottom">
      <form onSubmit={handleSubmit(onSubmit)} className="form form--chat chat__form">
        <div className="form__row">
          <div className="form__col">
            <img src={iconAvatar} className="form__icon" alt="avatar" />
            <span className="form__name">{dataUser && dataUser.name + " " + dataUser.last_name}</span>
          </div>
          <div className="form__col">
            <textarea {...register('Message')} className="form__textarea" placeholder="Оставьте сообщение"></textarea>
            <FilesUpload multi={true} path={dataUser && dataUser.filePath} />
          </div>
          <div className="form__col">
            <button disabled={statusAddMessage == 'LOADING' && true || false} type="submit" className="btn btn--blue form__btn">Отправить</button>
          </div>
        </div>
      </form>
    </div>
  </div>;
};
export default Chat;
