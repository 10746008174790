import {createActions} from "redux-actions";

const {
    handleFixDate,
    handleFixDateSuccess,
    handleFixDateFailure,
    handleFixDateIdle,
    handleFixDateInit,
} = createActions(
    "HANDLE_FIX_DATE",
    "HANDLE_FIX_DATE_SUCCESS",
    "HANDLE_FIX_DATE_FAILURE",
    "HANDLE_FIX_DATE_IDLE",
    "HANDLE_FIX_DATE_INIT",
);


export {
    handleFixDate,
    handleFixDateSuccess,
    handleFixDateFailure,
    handleFixDateIdle,
    handleFixDateInit,
};
