import { createSelector } from "reselect";

import dayjs from 'dayjs';


import { getValueProperty } from '../../constants/config';
import { RequestsType, list } from "../../carriers/pages/requests/constants";
import { DocumentsSelectProvide } from "../../carriers/pages/add/constants";

const getState = (state) => state.requests.data;
const initData = (state) => state.initData;

const status = {
  97: 'Новый',
  99: 'Счет выставлен'
}

export const requestsSelector = createSelector([getState, initData], (items, initData) => {
  let retData = [];
  if(items && items.length > 0 && initData){
    retData = items.map((item, index) => {

        // let ShippingPoint = '';

        // if(initData.warehouses[getValueProperty(item, 157)] && initData.warehouses[getValueProperty(item, 157)].NAME){
        //   ShippingPoint = initData.warehouses[getValueProperty(item, 157)].NAME;
        // }

        // let TruckType = '';

        // if(initData && 
        //     initData.typeTrucks && 
        //     initData.typeTrucks.typeTrucksInfo && 
        //     initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)] && 
        //     initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)].NAME
        //   ){
        //   TruckType = initData.typeTrucks.typeTrucksInfo[getValueProperty(item, 745)].NAME
        // }

        let TypeRequest = '';
        if(getValueProperty(item, 197)){
          let filterList = RequestsType.filter(el => {
            return el.value == getValueProperty(item, 197)
          });
          if(filterList && filterList.length > 0){
            TypeRequest = filterList[0].label;
          }
        }

        let Warehouse = '';
        if(initData.warehouses[getValueProperty(item, 261)] && initData.warehouses[getValueProperty(item, 261)].NAME){
          Warehouse = initData.warehouses[getValueProperty(item, 261)].NAME;
        }

        let DocumentTransferMethod = '';
        if(getValueProperty(item, 215)){
          let filterList = list[215].filter(el => {
            return el.value == getValueProperty(item, 215)
          });
          if(filterList && filterList.length > 0){
            DocumentTransferMethod = filterList[0].label;
          }
        }

        let DocumentsNeedProvide = '';
        if(getValueProperty(item, 247)){
          let filterList = list[247].filter(el => {
            return el.value == getValueProperty(item, 247)
          });
          if(filterList && filterList.length > 0){
            DocumentsNeedProvide = filterList[0].label;
          }
        }

        let DocumentsNeedRestore = '';
        if(getValueProperty(item, 219)){
          let filterList = list[219].filter(el => {
            return el.value == getValueProperty(item, 219)
          });
          if(filterList && filterList.length > 0){
            DocumentsNeedRestore = filterList[0].label;
          }
        }

        let Status = '';
        if(getValueProperty(item, 255)){
          let filterList = list[255].filter(el => {
            return el.value == getValueProperty(item, 255)
          });
          if(filterList && filterList.length > 0){
            Status = filterList[0].label;
          }
        }

        let TKRole = '';
        if(getValueProperty(item, 251)){
          let filterList = list[251].filter(el => {
            return el.value == getValueProperty(item, 251)
          });
          if(filterList && filterList.length > 0){
            TKRole = filterList[0].label;
          }
        }

        return {
          ID: item.ID,
          Created: item.DATE_CREATE,
          FlightNumber: getValueProperty(item, 195),
          TypeRequest: TypeRequest,
          Warehouse: Warehouse,
          TnNumber: getValueProperty(item, 199),
          NameClient: getValueProperty(item, 201),
          DeliveryAddress: getValueProperty(item, 203),
          CarBrand: getValueProperty(item, 205),
          CarNumber: getValueProperty(item, 207),
          Driver: getValueProperty(item, 209),
          PhoneDriver: getValueProperty(item, 211),
          DateTimeDelivery: getValueProperty(item, 263),
          ActualDateTime: getValueProperty(item, 193),
          ReasonRestoringDocuments: getValueProperty(item, 213),
          DocumentTransferMethod: DocumentTransferMethod,
          TransferDocuments: getValueProperty(item, 217),
          DocumentsNeedRestore: DocumentsNeedRestore,
          Article: getValueProperty(item, 221),
          QuantityBoxes: getValueProperty(item, 223),
          ScanAct: getValueProperty(item, 225),
          DriverAcceptance: getValueProperty(item, 227),
          Articles: getValueProperty(item, 229),
          AxleLoadSensor: getValueProperty(item, 231),
          SensorShowOverload: getValueProperty(item, 233),
          CarArriveOnTime: getValueProperty(item, 235),
          ReasonNonLoading: getValueProperty(item, 237),
          NumberInvoiceWhichLoadingRegistered: getValueProperty(item, 239),
          TnNoEmailNotification: getValueProperty(item, 241),
          ReasonBeingLate: getValueProperty(item, 243),
          WhenLoadingUnloading: getValueProperty(item, 265),
          Other: getValueProperty(item, 245),
          DocumentsNeedProvide: DocumentsNeedProvide,
          TypeTrack: getValueProperty(item, 249),
          TKRole: TKRole,
          View: getValueProperty(item, 253),
          Status: Status,
          Author: parseInt(item.CREATED_BY),
          Files: [],
          Partner: getValueProperty(item, 259),
          PartnerName: getValueProperty(item, 757)
      };
    });
    }
  return retData;
});

const getStateItems = (state) => state.requestsItem.dataItem;

export const requestsItemSelector = createSelector([getStateItems, initData], (items, initData) => {
  let retData = {};
  if(items && items.length > 0 && initData){
    items.map((item, index) => {

      let TypeRequest = '';
      if(getValueProperty(item, 197)){
        let filterList = RequestsType.filter(el => {
          return el.value == getValueProperty(item, 197)
        });
        if(filterList && filterList.length > 0){
          TypeRequest = filterList[0].label;
        }
      }

      let Warehouse = '';
      if(initData.warehouses[getValueProperty(item, 261)] && initData.warehouses[getValueProperty(item, 261)].NAME){
        Warehouse = initData.warehouses[getValueProperty(item, 261)].NAME;
      }

      let DocumentTransferMethod = '';
      if(getValueProperty(item, 215)){
        let filterList = list[215].filter(el => {
          return el.value == getValueProperty(item, 215)
        });
        if(filterList && filterList.length > 0){
          DocumentTransferMethod = filterList[0].label;
        }
      }

      let DocumentsNeedProvide = [];
      // console.log(typeof getValueProperty(item, 247));
      if(getValueProperty(item, 247) && getValueProperty(item, 247).length > 0){
        if(typeof getValueProperty(item, 247) == 'object'){
          getValueProperty(item, 247).map(itemID => {
            let filterList = list[247].filter(el => {
              return el.value == itemID
            })
            if(filterList && filterList.length > 0){
              DocumentsNeedProvide.push(filterList[0].label);
            }
          })
        } else {
          let filterList = list[247].filter(el => {
            return el.value == getValueProperty(item, 247)
          })
          if(filterList && filterList.length > 0){
            DocumentsNeedProvide.push(filterList[0].label);
          }
        }
      }

      let DocumentsNeedRestore = '';
      if(getValueProperty(item, 219)){
        let filterList = list[219].filter(el => {
          return el.value == getValueProperty(item, 219)
        });
        if(filterList && filterList.length > 0){
          DocumentsNeedRestore = filterList[0].label;
        }
      }

      let Status = '';
      if(getValueProperty(item, 255)){
        let filterList = list[255].filter(el => {
          return el.value == getValueProperty(item, 255)
        });
        if(filterList && filterList.length > 0){
          Status = filterList[0].label;
        }
      }

      let TKRole = '';
      if(getValueProperty(item, 251)){
        let filterList = list[251].filter(el => {
          return el.value == getValueProperty(item, 251)
        });
        if(filterList && filterList.length > 0){
          TKRole = filterList[0].label;
        }
      }

      retData = {
        ID: item.ID,
        Created: item.DATE_CREATE,
        FlightNumber: getValueProperty(item, 195),
        TypeRequest: TypeRequest,
        Warehouse: Warehouse,
        TnNumber: getValueProperty(item, 199),
        NameClient: getValueProperty(item, 201),
        DeliveryAddress: getValueProperty(item, 203),
        CarBrand: getValueProperty(item, 205),
        CarNumber: getValueProperty(item, 207),
        Driver: getValueProperty(item, 209),
        PhoneDriver: getValueProperty(item, 211),
        DateTimeDelivery: getValueProperty(item, 263),
        ActualDateTime: getValueProperty(item, 193),
        ReasonRestoringDocuments: getValueProperty(item, 213),
        DocumentTransferMethod: DocumentTransferMethod,
        TransferDocuments: getValueProperty(item, 217),
        DocumentsNeedRestore: DocumentsNeedRestore,
        Article: getValueProperty(item, 221),
        QuantityBoxes: getValueProperty(item, 223),
        ScanAct: getValueProperty(item, 225),
        DriverAcceptance: getValueProperty(item, 227),
        Articles: getValueProperty(item, 229),
        AxleLoadSensor: getValueProperty(item, 231),
        SensorShowOverload: getValueProperty(item, 233),
        CarArriveOnTime: getValueProperty(item, 235),
        ReasonNonLoading: getValueProperty(item, 237),
        NumberInvoiceWhichLoadingRegistered: getValueProperty(item, 239),
        TnNoEmailNotification: getValueProperty(item, 241),
        ReasonBeingLate: getValueProperty(item, 243),
        WhenLoadingUnloading: getValueProperty(item, 265),
        Other: getValueProperty(item, 245),
        DocumentsNeedProvide: DocumentsNeedProvide.join(', '),
        TypeTrack: getValueProperty(item, 249),
        TKRole: TKRole,
        View: getValueProperty(item, 253),
        Status: Status,
        Author: parseInt(item.CREATED_BY),
        Files: [],
        Partner: getValueProperty(item, 259)
    };
    });
  }
  return retData;
});

export const selectRequestItemForm = createSelector([getStateItems, initData], (items, initData) => {
  let retData = {};
  if(items && items.length > 0 && initData){
    items.map((item, index) => {

      let TypeRequest = '';
      if(getValueProperty(item, 197)){
        let filterList = RequestsType.filter(el => {
          return el.value == getValueProperty(item, 197)
        });
        if(filterList && filterList.length > 0){
          TypeRequest = filterList[0].label;
        }
      }

      let Warehouse = {};
      if(initData.warehouses[getValueProperty(item, 261)] && initData.warehouses[getValueProperty(item, 261)].NAME){
        Warehouse = {
          value: getValueProperty(item, 261),
          label: initData.warehouses[getValueProperty(item, 261)].NAME
        };
      }

      let DocumentTransferMethod = [];
      if(getValueProperty(item, 215) && getValueProperty(item, 215).length > 0){
        if(typeof getValueProperty(item, 215) == 'object'){
          getValueProperty(item, 215).map(itemID => {
            let filterList = list[215].filter(el => {
              return el.value == itemID
            })
            if(filterList && filterList.length > 0){
              DocumentTransferMethod.push({
                value: filterList[0].value,
                label: filterList[0].label
              });
            }
          })
        } else {
          let filterList = list[215].filter(el => {
            return el.value == getValueProperty(item, 215)
          })
          if(filterList && filterList.length > 0){
            DocumentTransferMethod.push({
              value: filterList[0].value,
              label: filterList[0].label
            });
          }
        }
      }

      let DocumentsNeedProvide = [];
      if(getValueProperty(item, 247) && getValueProperty(item, 247).length > 0){
        if(typeof getValueProperty(item, 247) == 'object'){
          getValueProperty(item, 247).map(itemID => {
            let filterList = list[247].filter(el => {
              return el.value == itemID
            })
            if(filterList && filterList.length > 0){
              DocumentsNeedProvide.push({
                value: filterList[0].value,
                label: filterList[0].label
              });
            }
          })
        } else {
          let filterList = list[247].filter(el => {
            return el.value == getValueProperty(item, 247)
          })
          if(filterList && filterList.length > 0){
            DocumentsNeedProvide.push({
              value: filterList[0].value,
              label: filterList[0].label
            });
          }
        }
      }

      let DocumentsNeedRestore = [];
      if(getValueProperty(item, 219) && getValueProperty(item, 247).length > 0){
        if(typeof getValueProperty(item, 219) == 'object'){
          getValueProperty(item, 247).map(itemID => {
            let filterList = DocumentsSelectProvide.filter(el => {
              return el.value == itemID
            })
            if(filterList && filterList.length > 0){
              DocumentsNeedRestore.push({
                value: filterList[0].value,
                label: filterList[0].label
              });
            }
          })
        } else {
          let filterList = DocumentsSelectProvide.filter(el => {
            return el.value == getValueProperty(item, 247)
          })
          if(filterList && filterList.length > 0){
            DocumentsNeedRestore.push({
              value: filterList[0].value,
              label: filterList[0].label
            });
          }
        }
      }

      let Status = '';
      if(getValueProperty(item, 255)){
        let filterList = list[255].filter(el => {
          return el.value == getValueProperty(item, 255)
        });
        if(filterList && filterList.length > 0){
          Status = filterList[0].label;
        }
      }

      let TKRole = '';
      if(getValueProperty(item, 251)){
        let filterList = list[251].filter(el => {
          return el.value == getValueProperty(item, 251)
        });
        if(filterList && filterList.length > 0){
          TKRole = filterList[0].label;
        }
      }

      let DateTimeDelivery = '';
      if(getValueProperty(item, 263)){
        DateTimeDelivery = dayjs(getValueProperty(item, 263)).valueOf();
        // let date = getValueProperty(item, 263).split(' ')[0],
        //     time = getValueProperty(item, 263).split(' ')[1];
        // DateTimeDelivery = dayjs(date.split('.')[2] + '-' + date.split('.')[1] + '-' + date.split('.')[0] + ' ' + time).valueOf();
      }

      let ActualDateTime = '';
      if(getValueProperty(item, 193)){
        ActualDateTime = dayjs(getValueProperty(item, 193)).valueOf();
        // let date = getValueProperty(item, 193).split(' ')[0],
        //     time = getValueProperty(item, 193).split(' ')[1];
        //   ActualDateTime = dayjs(date.split('.')[2] + '-' + date.split('.')[1] + '-' + date.split('.')[0] + ' ' + time).valueOf();
      }

      let WhenLoadingUnloading = '';
      if(getValueProperty(item, 265)){
        ActualDateTime = dayjs(getValueProperty(item, 265)).valueOf();
        // let date = getValueProperty(item, 265).split(' ')[0],
        //     time = getValueProperty(item, 265).split(' ')[1];
        //   WhenLoadingUnloading = dayjs(date.split('.')[2] + '-' + date.split('.')[1] + '-' + date.split('.')[0] + ' ' + time).valueOf();
      }

      retData = {
        ID: item.ID,
        Created: item.DATE_CREATE,
        FlightNumber: getValueProperty(item, 195),
        TypeRequest: TypeRequest,
        Warehouse: Warehouse,
        TnNumber: getValueProperty(item, 199),
        NameClient: getValueProperty(item, 201),
        DeliveryAddress: getValueProperty(item, 203),
        CarBrand: getValueProperty(item, 205),
        CarNumber: getValueProperty(item, 207),
        Driver: getValueProperty(item, 209),
        PhoneDriver: getValueProperty(item, 211),
        DateTimeDelivery: DateTimeDelivery,
        ActualDateTime: ActualDateTime,
        ReasonRestoringDocuments: getValueProperty(item, 213),
        DocumentTransferMethod: DocumentTransferMethod,
        TransferDocuments: getValueProperty(item, 217),
        DocumentsNeedRestore: DocumentsNeedRestore,
        Article: getValueProperty(item, 221),
        QuantityBoxes: getValueProperty(item, 223),
        ScanAct: getValueProperty(item, 225),
        DriverAcceptance: getValueProperty(item, 227),
        Articles: getValueProperty(item, 229),
        AxleLoadSensor: getValueProperty(item, 231),
        SensorShowOverload: getValueProperty(item, 233),
        CarArriveOnTime: getValueProperty(item, 235),
        ReasonNonLoading: getValueProperty(item, 237),
        NumberInvoiceWhichLoadingRegistered: getValueProperty(item, 239),
        TnNoEmailNotification: getValueProperty(item, 241),
        ReasonBeingLate: getValueProperty(item, 243),
        WhenLoadingUnloading: WhenLoadingUnloading,
        Other: getValueProperty(item, 245),
        DocumentsNeedProvide: DocumentsNeedProvide,
        TypeTrack: getValueProperty(item, 249),
        TKRole: TKRole,
        View: getValueProperty(item, 253),
        Status: Status,
        Author: parseInt(item.CREATED_BY),
        Files: [],
        Partner: getValueProperty(item, 259)
    };
    });
  }
  return retData;
});