import { createSelector } from "reselect";

import dayjs from 'dayjs';


import { getValueProperty } from '../../constants/config';

const initData = (state) => state.initData;

export const warehousesOptionsSelector = createSelector([initData], (initData) => {
    let retData = [];
    if(initData && initData.warehouses && Object.values(initData.warehouses).length){
        retData = Object.values(initData.warehouses).map((item, index) => {
            return {
                label: item.NAME,
                value: item.ID,
            };
        });
    }
    return retData;
});