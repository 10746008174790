import { 
    domainMain, 
    isLocalhost, 
    paramsLocalhostApi 
} from "../../constants/config";

export const authAppRequest = ({ code }) => {

    return new Promise((resolve, reject) => {

        let url = "https://"+domainMain+"/api/auth.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' );
        // console.log(7);

        // console.log(code);

        if(!isLocalhost && code){
            url = url + '?code=' + code;
        }

        // console.log(url);

        fetch(url, {
            // method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'text/plain'
            },
            // redirect: 'follow'
        })
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                resolve(result);
            })
            .catch(error => {
                console.log(error)
                reject(error);
            });
    
    });

    
}