import * as React from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
// import { initData } from "../../../../store/ducks/initData/actions";
// import { selectInitData } from "../../../../store/ducks/initData/selector";
import BtnsForm from '../btnsForm';
import { warehousesOptionsSelector } from "../../../../../modules/initData/selector";

  const customStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "60px",
    })
  };

const SecurityForm = ({ setSendData, requestItem, listFlightNumber, autocomplete }) => {

  const dispatch = useDispatch();
  // const defaultData: SelectedInitData[] | [] = useSelector(selectInitData);
  const defaultData = [];
  const dataUser = useSelector(state => state.authApp.init);
  const [role, setRole] = React.useState('');
  // const [warehouses, setWarehouses] = React.useState([]);
  const warehouses = useSelector(warehousesOptionsSelector);
  const [error, setError] = React.useState(false);

  const { control, handleSubmit, register, reset, watch, setValue, formState: { errors } } = useForm({
    defaultValues: requestItem,
  });
  
  
  const onSubmit = data => {
    setSendData({...data, ...{TypeRequest: 'Запрос документов по проверке безопасности'}});
  };

  React.useEffect(() => {
    if (defaultData.length === 0) {
        // dispatch(initData());
    }
  }, []);

  React.useEffect(() => {
    setValue('TKRole', role);
  }, [role]);

  React.useEffect(() => {
    if (requestItem && requestItem.ID && requestItem.TKRole) {
      setValue('TKRole', requestItem.TKRole);
      setRole(requestItem.TKRole);
    }
  }, [requestItem]);

  // React.useEffect(() => {
  //   if (watch('Partner') && watch('Partner').meta && watch('Partner').meta.length > 0) {
  //     setWarehouses(watch('Partner').meta);
  //   }
  // }, [watch('Partner')]);

  React.useEffect(() => {
    if (watch('Warehouse')) {
      if (warehouses.length > 0 && !warehouses.some(elem => elem.value === watch('Warehouse')['value'])) {
        setError(true)
      } else {
        setError(false)
      }
    }
  }, [watch('Partner'), watch('Warehouse'), watch('FlightNumber'), warehouses]);

  React.useEffect(() => {
    if (watch("FlightNumber") && watch("FlightNumber")['value']) {
      const flightNumber = watch("FlightNumber")['value'];
      const obj = autocomplete.find(el => el.FlightNumber === flightNumber);
        setValue("Warehouse", obj.Warehouse);
        setValue("TnNumber", obj.TnNumber);
        setValue("NameClient", obj.NameClient);
        setValue("DeliveryAddress", obj.DeliveryAddress);
        setValue("Driver", obj.Driver);
        setValue("CarNumber", obj.CarNumber);
        setValue("CarBrand", obj.CarBrand);
        setValue("TypeTrack", obj.TypeTrack);
        let partnerObj = {
          label: "",
          value: [],
          meta: []
        };
        let partnerEmpty = true;
        const { partners } = dataUser;
        partners && partners.length > 0 && partners.map((partner) => {
          const { meta } = partner;
          meta.map((item) => {
            if (item.label === obj.Warehouse.label) {
              partnerObj = partner;
              partnerEmpty = false;
            }
          })
        });
        if (!partnerEmpty) {
          setValue("Partner", partnerObj);
        }
    }
  }, [watch("FlightNumber")])

  return <div className="block-tabs__block active">
      <form onSubmit={handleSubmit(onSubmit)} className="form form--request form--security block-tabs__block__form" action="#">
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
                <label className={errors.FlightNumber && "label__form__input error" || "label__form__input"}>Номер рейса {errors.FlightNumber && "*"}</label>
                {listFlightNumber.length > 0 && (
                  <Controller
                    name="FlightNumber"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => <Select 
                      value={field.value || ""}
                      styles={customStyles}
                      onChange={(value) => field.onChange(value)}
                      placeholder="Номер рейса"
                      options={listFlightNumber} 
                    />}
                  />
                ) || (
                  <input {...register('FlightNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер рейса" />
                )}
            </div>
            <div className="form__col">
            <label className={errors.Partner && "label__form__input error" || "label__form__input"}>Партнёр {errors.Partner && "*"}</label>
            {/* <Controller
              name="Partner"
              control={control}
              rules={{ required: true }}
              render={({ field }) => <Select 
                value={field.value || ""}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Партнёр"
                options={[]} 
              />}
            /> */}
            <input {...register('Partner', { 
                required: true 
              })} className="input form__input" type="text" placeholder="Партнер" />
            </div>
            <div className="form__col">
            <label className={(errors.Warehouse || error) && "label__form__input error" || "label__form__input"}>Склад отгрузки {(errors.Warehouse || error) && "*"}</label>
            <Controller
                name="Warehouse"
                control={control}
                rules={{ required: true }}
                render={({ field }) => <Select 
                value={field.value}
                styles={customStyles}
                onChange={(value) => field.onChange(value)}
                placeholder="Склад отгрузки" 
                options={warehouses} 
                />}
            />
            </div>
            <div className="form__col">
            <label className={errors.TnNumber && "label__form__input error" || "label__form__input"}>Номер ТН {errors.TnNumber && "*"}</label>
            <input {...register('TnNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер ТН" />
            </div>
        </div>
      </div>
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <label className={errors.NameClient && "label__form__input error" || "label__form__input"}>Наименование клиента {errors.NameClient && "*"}</label>
            <input {...register('NameClient', { required: true })} className="input form__input"  type="text"   placeholder="Наименование клиента" />
            </div>
            <div className="form__col">
            <label className={errors.DeliveryAddress && "label__form__input error" || "label__form__input"}>Адрес доставки {errors.DeliveryAddress && "*"}</label>
            <input {...register('DeliveryAddress', { required: true })} className="input form__input"  type="text"   placeholder="Адрес доставки" />
            </div>
        </div>
      </div>
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <label className={errors.Driver && "label__form__input error" || "label__form__input"}>Водитель {errors.Driver && "*"}</label>
            <input {...register('Driver', { required: true })} className="input form__input"  type="text"   placeholder="Водитель" />
            </div>
            <div className="form__col">
            <label className={errors.TypeTrack && "label__form__input error" || "label__form__input"}>Тип трака {errors.TypeTrack && "*"}</label>
            <input {...register('TypeTrack', { required: true })} className="input form__input"  type="text"   placeholder="Тип трака" />
            </div>
        </div>
      </div>
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <label className={errors.PhoneDriver && "label__form__input error" || "label__form__input"}>Номер телефона водителя {errors.PhoneDriver && "*"}</label>
            <input {...register('PhoneDriver', { required: true })} className="input form__input"  type="text"   placeholder="Номер телефона водителя" />
            </div>
            <div className="form__col">
            <label className={errors.CarNumber && "label__form__input error" || "label__form__input"}>Номер авто {errors.CarNumber && "*"}</label>
            <input {...register('CarNumber', { required: true })} className="input form__input"  type="text"   placeholder="Номер авто" />
            </div>
            <div className="form__col">
            <label className={errors.CarBrand && "label__form__input error" || "label__form__input"}>Марка машины {errors.CarBrand && "*"}</label>
            <input {...register('CarBrand', { required: true })} className="input form__input"  type="text"   placeholder="Марка машины" />
            </div>
        </div>
      </div>
      <div className="form__container"> 
        <div className="form__row">
            <div className="form__col">
            <p className="form__text">Кем является ТК в рейсе?</p>
            </div>
            <div className="form__col">
            <label className="form__label">
                <input onChange={(e) => setRole('Перевозчик')} name="TKRole" className="form__checkbox" type="radio" defaultChecked={requestItem && requestItem.TKRole === "Перевозчик" && true || false} /><span className="form__label-text">Перевозчик</span>
            </label>
            </div>
            <div className="form__col">
            <label className="form__label">
                <input onChange={(e) => setRole('Экспедитор')} name="TKRole" className="form__checkbox" type="radio" defaultChecked={requestItem && requestItem.TKRole === "Экспедитор" && true || false} /><span className="form__label-text">Экспедитор</span>
            </label>
            </div>
        </div>
        </div>
        {role && (
          <div className="form__container"> 
          <div className="form__row">
              <div className="form__col">
              <div className="form__title-list">Необходимый пакет документов:</div>
              {role === 'Перевозчик' && (
              <ul className="form__list"> 
                  <li className="form__item">ПТС/Договор аренды;</li>
                  <li className="form__item">Договор трудовой или подряда на водителя;</li>
                  <li className="form__item">Водительское удостоверение;</li>
                  <li className="form__item">Паспорт водителя;</li>
              </ul>
              )}
              {role === 'Экспедитор' && (
              <ul className="form__list"> 
                  <li className="form__item">Наименование третьей стороны;</li>
                  <li className="form__item">Двойное экспедирование отсутствует;</li>
                  <li className="form__item">Договор между Экспедитором и Перевозчиком;</li>
                  <li className="form__item">Доверенность на подписанта договора со стороны Перевозчика или Приказ о назначении Директора;</li>
                  <li className="form__item">Выписка из ЕГРЮЛ/ЕГРИП;</li>
                  <li className="form__item">Проверка на уголовное преследование;</li>
                  <li className="form__item">Проверка на банкротство;</li>
                  <li className="form__item">Проверка по базе налоговой;</li>
                  <li className="form__item">Наличие активов;</li>
                  <li className="form__item">Отсутствие неисполненных обязательств;</li>
                  <li className="form__item">Свидетельство о регистрации на тягач;</li>
                  <li className="form__item">Свидетельство о регистрации на прицеп;</li>
                  <li className="form__item">Договор аренды ТС;</li>
                  <li className="form__item">Договор трудовой или подряда на водителя;</li>
                  <li className="form__item">Водительское удостоверение;</li>
                  <li className="form__item">Паспорт водителя;</li>
              </ul>
              )}
              </div>
          </div>
          </div>
        )}
          <div className="form__container">
            <BtnsForm error={error} id={requestItem && requestItem.ID} setValue={setValue} />
          </div>
      </form>
  </div>;
};
export default SecurityForm;
