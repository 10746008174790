import {createActions} from "redux-actions";

const {
    getListCod,
    getListCodSuccess,
    getListCodFailure,
    getListCodIdle,
    getListCodClear,
    searchFilter,
    searchFilterSuccess,
    searchFilterFailure,
    searchFilterIdle,
    addCodInfo,
    addCodInfoSuccess,
    addCodInfoFailure,
    addCodInfoIdle,
    setFilter,
    addLog,
    addLogSuccess,
    addLogFailure,
    addLogIdle,
    addLogDataSuccess
} = createActions(
    "GET_LIST_COD",
    "GET_LIST_COD_SUCCESS",
    "GET_LIST_COD_FAILURE",
    "GET_LIST_COD_IDLE",
    "GET_LIST_COD_CLEAR",
    "SEARCH_FILTER",
    "SEARCH_FILTER_SUCCESS",
    "SEARCH_FILTER_FAILURE",
    "SEARCH_FILTER_IDLE",
    "ADD_COD_INFO",
    "ADD_COD_INFO_SUCCESS",
    "ADD_COD_INFO_FAILURE",
    "ADD_COD_INFO_IDLE",
    "SET_FILTER",
    "ADD_LOG",
    "ADD_LOG_SUCCESS",
    "ADD_LOG_FAILURE",
    "ADD_LOG_IDLE",
    "ADD_LOG_DATA_SUCCESS",
)

export {
    getListCod,
    getListCodSuccess,
    getListCodFailure,
    getListCodIdle,
    getListCodClear,
    searchFilter,
    searchFilterSuccess,
    searchFilterFailure,
    searchFilterIdle,
    addCodInfo,
    addCodInfoSuccess,
    addCodInfoFailure,
    addCodInfoIdle,
    setFilter,
    addLog,
    addLogSuccess,
    addLogFailure,
    addLogIdle,
    addLogDataSuccess
}