import { createSelector } from "reselect";

import dayjs from 'dayjs';


import { getValueProperty } from '../../constants/config';

const messages = (state) => state.chat.dataMessages;

export const messagesSelector = createSelector([messages], (items) => {
  let retData = {};
  if(items && items.length > 0){
    items.map((item, index) => {

        let key = dayjs(item.DATE_CREATE).format('DD.MM.YYYY');

        let newItem = {
            ID: item.ID,
            Message: getValueProperty(item, 135),
            Author: getValueProperty(item, 749),
            Files: null,
            CreatedTime: dayjs(item.DATE_CREATE).format('HH:mm')
        }

        if(retData[key]){
            retData[key] = [
                ...retData[key],
                newItem
            ];
        } else {
            retData[key] = [
                newItem
            ];
        }
    });
  }
  console.log(retData);
  return retData;
});