import {handleActions} from "redux-actions";
import {combineReducers} from "redux";
import {
    getListPo,
    getListPoSuccess,
    getListPoFailure,
    getListPoIdle,
    getListPoClear,
    getItemPo,
    getItemPoSuccess,
    getItemPoFailure,
    getItemPoIdle,
    getItemPoClear,
    updateItemPo,
    updateItemPoSuccess,
    updateItemPoFailure,
    updateItemPoIdle,
    setPoIds,
} from './actions'

const status = handleActions(
    {
        [getListPoSuccess]: () => "SUCCESS",
        [getListPo]: () => "LOADING",
        [getListPoFailure]: () => "FAILURE",
        [getListPoIdle]: () => "IDLE",
    },
    'IDLE'
);

const count = handleActions(
    {
        [getListPoSuccess]: (state, action) => action.payload.count,
        [getListPoFailure]: (state, action) => 0,
        [getListPoClear]: () => 0
    },
    0
);

const data = handleActions(
    {
        [getListPoSuccess]: (state, action) => {
            return [
                ...state,
                ...action.payload.result
            ]
        },
        [getListPoFailure]: (state, action) => {
            return []
        },
        [getListPoClear]: () => []
    },
    []
);

const ids = handleActions(
    {
        [setPoIds]: (state, action) => action.payload,
    },
    []
);

const po = combineReducers({
    status,
    data,
    count,
    ids
})

const statusItem = handleActions(
    {
        [getItemPoSuccess]: () => "SUCCESS",
        [getItemPo]: () => "LOADING",
        [getItemPoFailure]: () => "FAILURE",
        [getItemPoIdle]: () => "IDLE",
    },
    'IDLE'
);

const statusItemUpdate = handleActions(
    {
        [updateItemPoSuccess]: () => "SUCCESS",
        [updateItemPo]: () => "LOADING",
        [updateItemPoFailure]: () => "FAILURE",
        [updateItemPoIdle]: () => "IDLE",
    },
    'IDLE'
);

const dataItem = handleActions(
    {
        [getItemPoSuccess]: (state, action) => {
            return action.payload.result
        },
        [getItemPoFailure]: (state, action) => {
            return []
        },
        [getItemPoClear]: () => []
    },
    []
);

const poItem = combineReducers({
    statusItem,
    statusItemUpdate,
    dataItem
})

export { po, poItem };