import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import Tooltip from "rc-tooltip";
import iconMark from '../../../../../assets/img/icons/mark.svg';

const ItemConfirmation = ({ item }) => {

  const dataUser = useSelector(state => state.authApp.init);
  
  return (
    <div
      id="requests-doc-confirmation"
      className="application-table main__application-table"
    >
      <div className="application-table__wrap-tables">
        <div className="application-table__table">
          <div className="application-table__row">
            <div className="application-table__col">Направление</div>
            <div className="application-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <>
                Входящий
              </>
            )}
            {dataUser && dataUser.id === item.Author && (
              <>
                Исходящий
              </>
            )}
        </div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Тип запроса</div>
            <div className="application-table__col">{item.TypeRequest}</div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Партнёр</div>
            <div className="application-table__col">{item.Partner}</div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Склад</div>
            <div className="application-table__col">{item.Warehouse}</div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Дата создания</div>
            <div className="application-table__col">{item.Created}</div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Статус</div>
            <div className="application-table__col">{item.Status}</div>
          </div>
          <div className="application-table__row">
            <div className="application-table__col">Содержание запроса</div>
            <div className="application-table__col">{item.Other}</div>
          </div>
          {item.Files.length > 0 && (
            <div className="application-table__row">
              <div className="application-table__col">Файлы</div>
              <div className="application-table__col">
                {item.Files.map((file, idx) => (
                  <Tooltip
                    key={idx}
                    placement="top"
                    trigger={["hover"]}
                    overlay={
                      <a
                        download={true}
                        target="_blank"
                        rel="noreferrer"
                        href={file}
                      >
                        Нажмите на эту ссылку для скачивания файла
                      </a>
                    }
                  >
                    <a
                      style={{
                        background: `url("${iconMark}") top/cover no-repeat`,
                        display: "block",
                      }}
                      target="_blank"
                      rel="noreferrer"
                      href={file}
                      className="application-table__mark"
                    ></a>
                  </Tooltip>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ItemConfirmation;
