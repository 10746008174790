import {call, put, takeEvery, all, take, takeLatest} from "redux-saga/effects";

import {
    getMessagesReguest,
    addMessageReguest
} from './api'

import {
    getMessages,
    getMessagesSuccess,
    getMessagesFailure,
    getMessagesIdle,
    addMessage,
    addMessageSuccess,
    addMessageFailure,
    addMessageIdle
    
} from './actions'

function* getMessagesSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(getMessagesReguest, payload);

        console.log(response);

        yield put(getMessagesSuccess(response));

    } catch (error) {

        yield put(getMessagesFailure(error));

    }
}

function* addMessageSaga(action) {
    try {

        const { payload } = action;

        const response = yield call(addMessageReguest, payload);

        yield put(addMessageSuccess(response));

    } catch (error) {

        yield put(addMessageFailure(error));

    }
}

export function* chatWatcher() {
    yield takeLatest(getMessages.toString(), getMessagesSaga);
    yield takeLatest(addMessage.toString(), addMessageSaga);
}