import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BreadCrumbs from "../../../modules/breadCrumbs";
// import { getRequestItem, changeView } from "../../store/ducks/requestItem/actions";
// import { selectRequestItem } from "../../store/ducks/requestItem/selector";
import Chat from "../../components/chat";
import ItemDocuments from './components/itemDocuments';
import ItemMarriage from './components/itemMarriage';
import ItemCertificates from './components/itemCertificates';
import ItemOverloads from './components/itemOverloads';
import ItemUnloading from './components/itemUnloading';
import ItemNotification from './components/itemNotification';
import ItemLate from './components/itemLate';
import ItemOther from './components/itemOther';
import ItemTransportDocuments from './components/itemTransportDocuments';
import ItemConfirmation from './components/itemConfirmation';
import ItemSecurity from './components/itemSecurity';
import Btns from "./components/btns";
import BtnsActions from "./components/btnsActions";
import { requestItem } from "./constants";
import { requestsItemSelector } from "../../../modules/requests/selector";
import { useEffect } from "react";
import { getItemRequests, getItemRequestsIdle, updateItemRequests, updateItemRequestsIdle } from "../../../modules/requests/actions";

const RequestItem = () => {

  const dispatch = useDispatch();
  const params = useParams();
  const [id, setId] = React.useState(null);
  const [breadcrumb, setBreadcrumb] = React.useState([
    {name: 'Сервисы', link: `/`},
    {name: 'Запросы накладных и документов', link: `/requests`}
  ]);
  const [breadcrumbUpdate, setBreadcrumbUpdate] = React.useState(false);
  // const requestItem: SelectedRequestItem = useSelector(selectRequestItem);
  const [upload, setUpload] = React.useState(false);
  
  // const status = useSelector((state: State) => state.requestsItem.status);
  // const status = 'SUCCESS';
  const status = useSelector(state => state.requestsItem.statusItem);
  const statusUpdate = useSelector(state => state.requestsItem.statusItemUpdate);
  const requestsItemDefault = useSelector(state => state.requestsItem.dataItem);
  const requestsItem = useSelector(requestsItemSelector);

  React.useEffect(() => {
    if (params.id) {
      setId(Number(params.id));
    }
  }, [params]);



  useEffect(() => {
    if(statusUpdate == 'SUCCESS'){
      dispatch(updateItemRequestsIdle());
      dispatch(getItemRequests({
        id
      }))
    }
  }, [statusUpdate])

  useEffect(() => {
    if(status == "SUCCESS"){
      dispatch(getItemRequestsIdle());
    }
  }, [status])

  React.useEffect(() => {
    if (id) {
      if(status == 'IDLE'){
        dispatch(getItemRequests({
          id
        }))
      }
    }
  }, [id])

  React.useEffect(() => {
    // console.log('requestItem: ', requestItem);
    if (requestsItem.ID && !breadcrumbUpdate) {
      setBreadcrumb([...breadcrumb, {
        name: `# ${requestsItem.ID}`,
        link: `/requestItem/${requestsItem.ID}`
      }]);
      setBreadcrumbUpdate(true);
    }
    if (requestsItem.ID && !requestsItem.View) {
      // dispatch(changeView(id));
    }
  }, [requestItem]);

  return <main className="main">
  <div className="main__content container">
    <div className="main__wrap">
      <BreadCrumbs breadcrumb={breadcrumb} />
      {requestsItem.ID && <Btns id={requestsItem.ID} />}
    </div>
    {status == 'LOADING' && <h1 className="section-title main__section-title">Загружаем данные...</h1> || requestsItem && (
      <>
        <h1 className="section-title main__section-title">{requestsItem.ID && `Запрос # ${requestsItem.ID}`}</h1>
        <div className="application-table main__application-table">
          {requestsItem.TypeRequest === 'Запрос документов' && <ItemDocuments item={requestsItem} />}
          {requestsItem.TypeRequest === 'Недостача, брак, излишки' && <ItemMarriage item={requestsItem} />}
          {requestsItem.TypeRequest === 'Нет сертификатов/вет. свидетельства' && <ItemCertificates item={requestsItem} />}
          {requestsItem.TypeRequest === 'Перегрузы' && <ItemOverloads item={requestsItem} />}
          {requestsItem.TypeRequest === 'Простой на выгрузке' && <ItemUnloading item={requestsItem} />}
          {requestsItem.TypeRequest === 'Нет электронного уведомления' && <ItemNotification item={requestsItem} />}
          {requestsItem.TypeRequest === 'Опоздание на погрузку/выгрузку' && <ItemLate item={requestsItem} />}
          {requestsItem.TypeRequest === 'Иное' && <ItemOther item={requestsItem} />}
          {requestsItem.TypeRequest === 'Запрос документов транспортных' && <ItemTransportDocuments item={requestsItem} />}
          {requestsItem.TypeRequest === 'Запрос с подтверждением' && <ItemConfirmation item={requestsItem} />}
          {requestsItem.TypeRequest === 'Запрос документов по проверке безопасности' && <ItemSecurity setUpload={setUpload} item={requestsItem} />}
          <BtnsActions requestsItemDefault={requestsItemDefault} role={requestsItem.TKRole} statusUpdate={statusUpdate} upload={upload} type={requestsItem.TypeRequest} id={requestsItem.ID} status={requestsItem.Status} />
          {requestsItem.ID && <Chat request={true} list={'Chat'} id={requestsItem.ID} />}
        </div>
      </>
    ) || (
      <h1 className="section-title main__section-title">Загружаем данные...</h1>
    )}
  </div>
  </main>;
};
export default RequestItem;
