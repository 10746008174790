import {createActions} from "redux-actions";

const {
    getServprovName,
    getServprovNameSuccess,
    getServprovNameFailure,
    getServprovNameIdle,
    getListBooking,
    getListBookingSuccess,
    getListBookingFailure,
    getListBookingIdle,
    setListWarehousesBookings,
    setBlockedCells,
    setReserveCells,
    setAllBlockedTimeDock,
    addBlockedCell,
    addBlockedCellSuccess,
    addBlockedCellFailure,
    addBlockedCellIdle,
    updateBooking,
    updateBookingSuccess,
    updateBookingFailure,
    updateBookingIdle,
    setCheckedWarehouses,
    setCheckedDirection,
    setActiveWarehouses,
    checkedBlockedCells,
    checkedBlockedCellsSuccess,
    checkedBlockedCellsFailure,
    checkedBlockedCellsIdle,
    checkedBlockedCellsDate,
    checkedBlockedCellsDateSuccess,
    checkedBlockedCellsDateFailure,
    checkedBlockedCellsDateIdle,
    updateBlocked,
    updateBlockedSuccess,
    updateBlockedFailure,
    updateBlockedIdle,
    getFreeCells,
    getFreeCellsSuccess,
    getFreeCellsFailure,
    getFreeCellsIdle,
    getFreeCellsClear,
    addBooking,
    addBookingSuccess,
    addBookingFailure,
    addBookingIdle,
    addBookingClear,
    getBookingShipment,
    getBookingShipmentSuccess,
    getBookingShipmentFailure,
    getBookingShipmentIdle,
    getBookingShipmentClear,
    deleteBooking,
    deleteBookingSuccess,
    deleteBookingFailure,
    deleteBookingIdle,
    searchBooking,
    searchBookingSuccess,
    searchBookingFailure,
    searchBookingIdle,
    searchBookingReset,
    searchTypeLocation,
    searchTypeLocationSuccess,
    searchTypeLocationFailure,
    searchTypeLocationIdle,
    searchUsersProvider,
    searchUsersProviderSuccess,
    searchUsersProviderFailure,
    searchUsersProviderIdle,
    changeCells,
    changeCellsSuccess,
    changeCellsFailure,
    changeCellsIdle
} = createActions(
    "GET_SERVPROV_NAME",
    "GET_SERVPROV_NAME_SUCCESS",
    "GET_SERVPROV_NAME_FAILURE",
    "GET_SERVPROV_NAME_IDLE",
    "GET_LIST_BOOKING",
    "GET_LIST_BOOKING_SUCCESS",
    "GET_LIST_BOOKING_FAILURE",
    "GET_LIST_BOOKING_IDLE",
    "SET_LIST_WAREHOUSES_BOOKINGS",
    "SET_BLOCKED_CELLS",
    "SET_RESERVE_CELLS",
    "SET_ALL_BLOCKED_TIME_DOCK",
    "ADD_BLOCKED_CELL",
    "ADD_BLOCKED_CELL_SUCCESS",
    "ADD_BLOCKED_CELL_FAILURE",
    "ADD_BLOCKED_CELL_IDLE",
    "UPDATE_BOOKING",
    "UPDATE_BOOKING_SUCCESS",
    "UPDATE_BOOKING_FAILURE",
    "UPDATE_BOOKING_IDLE",
    "SET_CHECKED_WAREHOUSES",
    "SET_CHECKED_DIRECTION",
    "SET_ACTIVE_WAREHOUSES",
    'CHECKED_BLOCKED_CELLS',
    'CHECKED_BLOCKED_CELLS_SUCCESS',
    'CHECKED_BLOCKED_CELLS_FAILURE',
    'CHECKED_BLOCKED_CELLS_IDLE',
    'CHECKED_BLOCKED_CELLS_DATE',
    'CHECKED_BLOCKED_CELLS_DATE_SUCCESS',
    'CHECKED_BLOCKED_CELLS_DATE_FAILURE',
    'CHECKED_BLOCKED_CELLS_DATE_IDLE',
    'UPDATE_BLOCKED',
    'UPDATE_BLOCKED_SUCCESS',
    'UPDATE_BLOCKED_FAILURE',
    'UPDATE_BLOCKED_IDLE',
    'GET_FREE_CELLS',
    'GET_FREE_CELLS_SUCCESS',
    'GET_FREE_CELLS_FAILURE',
    'GET_FREE_CELLS_IDLE',
    'GET_FREE_CELLS_CLEAR',
    'ADD_BOOKING',
    'ADD_BOOKING_SUCCESS',
    'ADD_BOOKING_FAILURE',
    'ADD_BOOKING_IDLE',
    'ADD_BOOKING_CLEAR',
    'GET_BOOKING_SHIPMENT',
    'GET_BOOKING_SHIPMENT_SUCCESS',
    'GET_BOOKING_SHIPMENT_FAILURE',
    'GET_BOOKING_SHIPMENT_IDLE',
    'GET_BOOKING_SHIPMENT_CLEAR',
    'DELETE_BOOKING',
    'DELETE_BOOKING_SUCCESS',
    'DELETE_BOOKING_FAILURE',
    'DELETE_BOOKING_IDLE',
    'SEARCH_BOOKING',
    'SEARCH_BOOKING_SUCCESS',
    'SEARCH_BOOKING_FAILURE',
    'SEARCH_BOOKING_IDLE',
    'SEARCH_BOOKING_RESET',
    'SEARCH_TYPE_LOCATION',
    'SEARCH_TYPE_LOCATION_SUCCESS',
    'SEARCH_TYPE_LOCATION_FAILURE',
    'SEARCH_TYPE_LOCATION_IDLE',
    'SEARCH_USERS_PROVIDER',
    'SEARCH_USERS_PROVIDER_SUCCESS',
    'SEARCH_USERS_PROVIDER_FAILURE',
    'SEARCH_USERS_PROVIDER_IDLE',
    'CHANGE_CELLS',
    'CHANGE_CELLS_SUCCESS',
    'CHANGE_CELLS_FAILURE',
    'CHANGE_CELLS_IDLE'
);


export {
    getServprovName,
    getServprovNameSuccess,
    getServprovNameFailure,
    getServprovNameIdle,
    getListBooking,
    getListBookingSuccess,
    getListBookingFailure,
    getListBookingIdle,
    setListWarehousesBookings,
    setBlockedCells,
    setReserveCells,
    setAllBlockedTimeDock,
    addBlockedCell,
    addBlockedCellSuccess,
    addBlockedCellFailure,
    addBlockedCellIdle,
    updateBooking,
    updateBookingSuccess,
    updateBookingFailure,
    updateBookingIdle,
    setCheckedWarehouses,
    setCheckedDirection,
    setActiveWarehouses,
    checkedBlockedCells,
    checkedBlockedCellsSuccess,
    checkedBlockedCellsFailure,
    checkedBlockedCellsIdle,
    updateBlocked,
    updateBlockedSuccess,
    updateBlockedFailure,
    updateBlockedIdle,
    getFreeCells,
    getFreeCellsSuccess,
    getFreeCellsFailure,
    getFreeCellsIdle,
    getFreeCellsClear,
    checkedBlockedCellsDate,
    checkedBlockedCellsDateSuccess,
    checkedBlockedCellsDateFailure,
    checkedBlockedCellsDateIdle,
    addBooking,
    addBookingSuccess,
    addBookingFailure,
    addBookingIdle,
    addBookingClear,
    getBookingShipment,
    getBookingShipmentSuccess,
    getBookingShipmentFailure,
    getBookingShipmentIdle,
    getBookingShipmentClear,
    deleteBooking,
    deleteBookingSuccess,
    deleteBookingFailure,
    deleteBookingIdle,
    searchBooking,
    searchBookingSuccess,
    searchBookingFailure,
    searchBookingIdle,
    searchBookingReset,
    searchTypeLocation,
    searchTypeLocationSuccess,
    searchTypeLocationFailure,
    searchTypeLocationIdle,
    searchUsersProvider,
    searchUsersProviderSuccess,
    searchUsersProviderFailure,
    searchUsersProviderIdle,
    changeCells,
    changeCellsSuccess,
    changeCellsFailure,
    changeCellsIdle
};
