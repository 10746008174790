import * as React from "react";
import { useSelector, useDispatch } from "react-redux";
import ForwarderFiles from "./components/forwarderFiles";
import FirstMover from './components/firstMoverFiles';

const ItemSecurity = ({ item, setUpload }) => {

  const dataUser = useSelector(state => state.authApp.init);
  
  return <><div className="application-table main__application-table">
  <div className="application-table__wrap-tables">
    <div className="application-table__table">
      <div className="application-table__row"> 
        <div className="application-table__col">Направление</div>
        <div className="application-table__col">
            {dataUser && dataUser.id !== item.Author && (
              <>
                Входящий
              </>
            )}
            {dataUser && dataUser.id === item.Author && (
              <>
                Исходящий
              </>
            )}
        </div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Тип запроса</div>
        <div className="application-table__col">{item.TypeRequest}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер рейса</div>
        <div className="application-table__col">{item.FlightNumber}</div>
      </div>
      <div className="application-table__row">
        <div className="application-table__col">Партнёр</div>
        <div className="application-table__col">{item.Partner}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Склад отгрузки</div>
        <div className="application-table__col">{item.Warehouse}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер ТН</div>
        <div className="application-table__col">{item.TnNumber}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Наименование клиента</div>
        <div className="application-table__col">{item.NameClient}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Адрес доставки</div>
        <div className="application-table__col">{item.DeliveryAddress}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Марка машины</div>
        <div className="application-table__col">{item.CarBrand}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер авто</div>
        <div className="application-table__col">{item.CarNumber}</div>
      </div>
    </div>
    <div className="application-table__table">
      <div className="application-table__row"> 
        <div className="application-table__col">Водитель</div>
        <div className="application-table__col">{item.Driver}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Номер тел. водителя</div>
        <div className="application-table__col">{item.PhoneDriver}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Тип трака</div>
        <div className="application-table__col">{item.TypeTrack}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Кем является ТК в рейсе</div>
        <div className="application-table__col">{item.TKRole}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Дата создания</div>
        <div className="application-table__col">{item.Created}</div>
      </div>
      <div className="application-table__row"> 
        <div className="application-table__col">Статус</div>
        <div className="application-table__col">{item.Status}</div>
      </div>
    </div>
  </div>
</div>
{item.TKRole === "Экспедитор" && (
  <ForwarderFiles files={item.Files} requestId={item.ID} setUpload={setUpload} />
)}
{item.TKRole === "Перевозчик" && (
  <FirstMover files={item.Files} requestId={item.ID} setUpload={setUpload} />
)}
</>;
};
export default ItemSecurity;
