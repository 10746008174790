import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../../../modules/breadCrumbs";
import cx from "classnames";
import TransportDocumentsForm from "./components/transportDocumentsForm";
import ConfirmationForm from "./components/confirmationForm";
import SecurityForm from "./components/securityForm";
import DocumentsForm from "./components/documentsForm";
import UnloadingForm from "./components/unloadingForm";
import OtherForm from "./components/otherForm";
import dayjs from 'dayjs';
// import { addRequest, getAutocompleteData } from "../../store/ducks/addRequest/actions";
// import { selectAutocompleteData } from "../../store/ducks/addRequest/selector";
// import { getRequestItem } from "../../store/ducks/requestItem/actions";
// import { selectRequestItemForm } from "../../store/ducks/requestItem/selector";
import moment from "moment";
import history from "../../../history";
import { addItemRequests, addItemRequestsIdle, getItemRequests, updateItemRequests, updateItemRequestsIdle } from "../../../modules/requests/actions";
import { RequestsType, list } from "../requests/constants";
import { selectRequestItemForm } from "../../../modules/requests/selector";
import { DocumentsSelectRestore } from "./constants";

const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

const Add = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const [id, setId] = React.useState(0);
  const [role, setRole] = React.useState(null);
  const [type, setType] = React.useState(null);
  const [sendData, setSendData] = React.useState({});
  const [dataRequest, setDataRequest] = React.useState(null);
  const navigate = useNavigate();
  // const status = useSelector((state) => state.addItem.status);
  // const idRequest = useSelector((state) => state.addItem.id);
  const status = useSelector(state => state.requestsItem.statusItemAdd);
  const statusUpdate = useSelector(state => state.requestsItem.statusItemUpdate);
  const idRequest = useSelector(state => state.requestsItem.addIdRequest);
  const [breadcrumb, setBreadcrumb] = React.useState([
    { name: "Сервисы", link: `/` },
    { name: "Запрос документов", link: `/requests` },
  ]);
  const dataUser = useSelector((state) => state.authApp.init);
  const requestItem = useSelector(selectRequestItemForm);
  const requestItemDefault = useSelector(state => state.requestsItem.dataItem[0]);
  // const requestItem = null;
  // const autocomleteData = useSelector(selectAutocompleteData);
  const autocomleteData = { listFlightNumber: [], autocomplete: [] };
  const { listFlightNumber, autocomplete } = autocomleteData;

  React.useEffect(() => {
    if (params.id) {
      setId(Number(params.id));
    }
  }, [params]);

  React.useEffect(() => {
    // dispatch(getAutocompleteData());
  }, []);

  React.useEffect(() => {
    if (!id) {
      if (dataUser && dataUser.group === "user_ul") {
        setRole("employee");
        setType("transportDocuments");
      }
      if (dataUser && dataUser.group !== "user_ul") {
        setRole("partner");
        setType("documents");
      }
    }
  }, [dataUser]);

  const changeTypeForm = (e, type) => {
    e.preventDefault();
    setType(type);
  };

  React.useEffect(() => {
    if (status === "SUCCESS") {
      dispatch(addItemRequestsIdle());
      navigate(`/requestItem/${idRequest}`);
    }
  }, [status]);

  React.useEffect(() => {
    if (statusUpdate === "SUCCESS") {
      dispatch(updateItemRequestsIdle());
      navigate(`/requestItem/${id}`);
    }
  }, [statusUpdate]);


  React.useEffect(() => {
    const sendDataObj = {};
    let warehouse = null, warehouses = [], namePartner = [];
    Object.keys(sendData).map((key) => {
      // if (key !== "Partner") {
        // if (sendData[key] instanceof Date) {
        //   sendDataObj[key] = moment(sendData[key]).format("M/D/YYYY h:mm a");
        // } else if (typeof sendData[key] === "object" && sendData[key].value) {
        //   if (typeof sendData[key].value === "number") {
        //     sendDataObj[`${key}Id`] = sendData[key].value;
        //     warehouse = sendData[key].value;
        //   } else {
        //     sendDataObj[key] = sendData[key].value;
        //   }
        // } else if (Array.isArray(sendData[key])) {
        //   const obj = {
        //     __metadata: {
        //       type: "Collection(Edm.String)",
        //     },
        //     results: [],
        //   };
        //   sendData[key].map((item) => {
        //     if (typeof item.value === "number") {
        //       warehouses = [...warehouses, item.value];
        //     } else {
        //       obj["results"] = [...obj["results"], item.value];
        //     }
        //   });
        //   if (warehouses.length === 0) {
        //     sendDataObj[key] = { ...obj };
        //   }
        // } else if (typeof sendData[key] === "boolean") {
        //   sendDataObj[key] = sendData[key] && "true" || "";
        // } else {
          sendDataObj[key] = sendData[key];
        // }
      // }
      // if (key === "Partner") {
      //   if (Array.isArray(sendData[key])) {
      //     sendData[key].map((item) => {
      //       namePartner = [...namePartner, item["label"]];
      //     });
      //   } else {
      //     if(sendData[key]){
      //       namePartner = sendData[key]["label"];
      //     }
      //   }
      //   sendDataObj[key] = namePartner;
      // }
    });
    if (dataUser && dataUser.group !== "user_ul") {
      sendDataObj["Partner"] = dataUser && dataUser.section;
    }
    if (Object.keys(sendDataObj).length > 0) {
      if (!id) {
        sendDataObj["Title"] = `Запрос #${getRandomInt(1000, 10000)}`;
        sendDataObj["Employee"] = dataUser && dataUser.group === "user_ul";
      }
      console.log("send: ", {
        data: sendDataObj,
        id: id,
        employee: dataUser && dataUser.group === "user_ul",
        path: dataUser && dataUser.listPath,
        warehouse: warehouse,
        warehouses: warehouses,
        userId: dataUser && dataUser.id,
        namePartner: namePartner,
      });

      let TypeRequest = '';
      let filterList = RequestsType.filter(el => {
        return el.label == sendDataObj.TypeRequest
      });
      if(filterList && filterList.length > 0){
        TypeRequest = filterList[0].value;
      }

      let DocumentsNeedProvide = [];
      if(sendDataObj.DocumentsNeedProvide && sendDataObj.DocumentsNeedProvide.length > 0){
        sendDataObj.DocumentsNeedProvide.map(item => {
          DocumentsNeedProvide.push(item.value);
        })
      }

      let Status = '';
      if(sendDataObj.Status){
        let filterList = list[255].filter(el => {
          return el.label == sendDataObj.Status
        });
        if(filterList && filterList.length > 0){
          Status = filterList[0].value;
        }
      }

      let DocumentTransferMethod = '';
      if(sendDataObj.DocumentTransferMethod){
        let filterList = list[215].filter(el => {
          return el.label == sendDataObj.DocumentTransferMethod
        });
        if(filterList && filterList.length > 0){
          DocumentTransferMethod = filterList[0].value;
        }
      }

      let DocumentsNeedRestore = '';
      if(sendDataObj.DocumentsNeedRestore){
        let filterList = DocumentsSelectRestore.filter(el => {
          return el.label == sendDataObj.DocumentsNeedRestore
        });
        if(filterList && filterList.length > 0){
          DocumentsNeedRestore = filterList[0].value;
        }
      }

      

      let TKRole = '';
      if(sendDataObj.TKRole){
        let filterList = list[251].filter(el => {
          return el.label == sendDataObj.TKRole
        });
        if(filterList && filterList.length > 0){
          TKRole = filterList[0].value;
        }
      }

      console.log(sendDataObj);

      if(!id && status == 'IDLE'){
        dispatch(addItemRequests({
          'ELEMENT_CODE': 'Request_' + new Date().getTime(),
          'FIELDS': {
              'NAME': 'Request',
              'PROPERTY_193': sendDataObj.ActualDateTime,
              'PROPERTY_195': sendDataObj.FlightNumber,
              'PROPERTY_197': TypeRequest,
              'PROPERTY_199': sendDataObj.TnNumber,
              'PROPERTY_201': sendDataObj.NameClient,
              'PROPERTY_203': sendDataObj.DeliveryAddress,
              'PROPERTY_205': sendDataObj.CarBrand,
              'PROPERTY_207': sendDataObj.CarNumber,
              'PROPERTY_209': sendDataObj.Driver,
              'PROPERTY_211': sendDataObj.PhoneDriver,
              'PROPERTY_213': sendDataObj.ReasonRestoringDocuments,
              'PROPERTY_215': DocumentTransferMethod,
              'PROPERTY_217': sendDataObj.TransferDocuments,
              'PROPERTY_219': DocumentsNeedRestore,
              'PROPERTY_221': sendDataObj.Article,
              'PROPERTY_223': sendDataObj.QuantityBoxes,
              'PROPERTY_225': sendDataObj.ScanAct,
              'PROPERTY_227': sendDataObj.DriverAcceptance,
              'PROPERTY_229': sendDataObj.Articles,
              'PROPERTY_231': sendDataObj.AxleLoadSensor,
              'PROPERTY_233': sendDataObj.SensorShowOverload,              
              'PROPERTY_235': sendDataObj.CarArriveOnTime,
              'PROPERTY_237': sendDataObj.ReasonNonLoading,
              'PROPERTY_239': sendDataObj.NumberInvoiceWhichLoadingRegiste,
              'PROPERTY_241': sendDataObj.TnNoEmailNotification,
              'PROPERTY_243': sendDataObj.ReasonBeingLate,
              'PROPERTY_245': sendDataObj.Other,
              'PROPERTY_247': DocumentsNeedProvide,
              'PROPERTY_249': sendDataObj.TypeTrack,
              'PROPERTY_251': TKRole,
              'PROPERTY_253': sendDataObj.View,              
              'PROPERTY_255': Status,
              'PROPERTY_259': sendDataObj.Partner,
              'PROPERTY_261': sendDataObj.Warehouse.value,
              'PROPERTY_263': sendDataObj.DateTimeDelivery,
              'PROPERTY_265': sendDataObj.WhenLoadingUnloading,
              'PROPERTY_267': sendDataObj.Employee,
          }
        }))
      } else if(id && statusUpdate == 'IDLE') {
        let bodyUpdate = {}
        if(sendDataObj.ActualDateTime){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_193': dayjs(sendDataObj.ActualDateTime).format('DD/MM/YYYY HH:mm') } }
        if(sendDataObj.FlightNumber){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_195': sendDataObj.FlightNumber } }
        if(TypeRequest){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_197': TypeRequest } }
        if(sendDataObj.TnNumber){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_199': sendDataObj.TnNumber } }
        if(sendDataObj.NameClient){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_201': sendDataObj.NameClient } }
        if(sendDataObj.DeliveryAddress){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_203': sendDataObj.DeliveryAddress } }
        if(sendDataObj.CarBrand){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_205': sendDataObj.CarBrand } }
        if(sendDataObj.CarNumber){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_207': sendDataObj.CarNumber } }
        if(sendDataObj.Driver){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_209': sendDataObj.Driver } }
        if(sendDataObj.PhoneDriver){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_211': sendDataObj.PhoneDriver } }
        if(sendDataObj.ReasonRestoringDocuments){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_213': sendDataObj.ReasonRestoringDocuments } }
        if(DocumentTransferMethod){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_215': DocumentTransferMethod } }
        if(sendDataObj.TransferDocuments){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_217': sendDataObj.TransferDocuments } }
        if(DocumentsNeedRestore){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_219': DocumentsNeedRestore } }
        if(sendDataObj.Article){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_221': sendDataObj.Article } }
        if(sendDataObj.QuantityBoxes){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_223': sendDataObj.QuantityBoxes } }
        if(sendDataObj.ScanAct){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_225': sendDataObj.ScanAct } }
        if(sendDataObj.DriverAcceptance){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_227': sendDataObj.DriverAcceptance } }
        if(sendDataObj.Articles){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_229': sendDataObj.Articles } }
        if(sendDataObj.AxleLoadSensor){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_231': sendDataObj.AxleLoadSensor } }
        if(sendDataObj.SensorShowOverload){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_233': sendDataObj.SensorShowOverload } }
        if(sendDataObj.CarArriveOnTime){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_235': sendDataObj.CarArriveOnTime } }
        if(sendDataObj.ReasonNonLoading){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_237': sendDataObj.ReasonNonLoading } }
        if(sendDataObj.NumberInvoiceWhichLoadingRegiste){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_239': sendDataObj.NumberInvoiceWhichLoadingRegiste } }
        if(sendDataObj.TnNoEmailNotification){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_241': sendDataObj.TnNoEmailNotification } }
        if(sendDataObj.ReasonBeingLate){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_243': sendDataObj.ReasonBeingLate } }
        if(sendDataObj.Other){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_245': sendDataObj.Other } }
        if(DocumentsNeedProvide){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_247': DocumentsNeedProvide } }
        if(sendDataObj.TypeTrack){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_249': sendDataObj.TypeTrack } }
        if(TKRole){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_251': TKRole } }
        if(sendDataObj.View){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_253': sendDataObj.View } }
        if(Status){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_255': Status } }
        if(sendDataObj.Partner){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_259': sendDataObj.Partner } }
        if(sendDataObj.Warehouse.value){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_261': sendDataObj.Warehouse.value } }
        if(sendDataObj.DateTimeDelivery){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_263': dayjs(sendDataObj.DateTimeDelivery).format('DD/MM/YYYY HH:mm') } }
        if(sendDataObj.WhenLoadingUnloading){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_265': dayjs(sendDataObj.WhenLoadingUnloading).format('DD/MM/YYYY HH:mm') } }
        if(sendDataObj.Employee){ bodyUpdate = { ...bodyUpdate, 'PROPERTY_267': sendDataObj.Employee } }
        dispatch(updateItemRequests({
          props: {
            ...requestItemDefault,
            ...bodyUpdate,
          }
        }))
      }
      // dispatch(addRequest({ data: sendDataObj, id: id, employee: currentUser.employee, userId: currentUser.id, path: currentUser.listPath, warehouse: warehouse, warehouses: warehouses, namePartner: namePartner }));
    }
  }, [sendData]);

  React.useEffect(() => {
    if (id) {
      dispatch(getItemRequests({
        id
      }));
      setBreadcrumb([...breadcrumb, { name: `# ${id}`, link: `/add/${id}` }]);
    } else {
      setBreadcrumb([...breadcrumb, { name: `Создать запрос`, link: `/add` }]);
    }
  }, [id]);

  React.useEffect(() => {
    if (requestItem && requestItem.ID) {
      switch (requestItem.TypeRequest) {
        case "Запрос документов":
          setType("documents");
          break;
        case "Простой на выгрузке":
          setType("unloading");
          break;
        case "Иное":
          setType("other");
          break;
        case "Запрос документов транспортных":
          setType("transportDocuments");
          break;
        case "Запрос с подтверждением":
          setType("confirmation");
          break;
        case "Запрос документов по проверке безопасности":
          setType("security");
          break;
        default:
          if (dataUser && dataUser.group === "user_ul") {
            setType("transportDocuments");
          }
          if (dataUser && dataUser.group !== "user_ul") {
            setType("documents");
          }
      }
      console.log(requestItem);
      setDataRequest(requestItem);
    }
  }, [requestItem, dataUser]);

  console.log('type', type);

  return (
    <main className="main">
      <div className="main__content container">
        <div className="main__wrap">
          <BreadCrumbs breadcrumb={breadcrumb} />
        </div>
        {status === "LOADING" && (
          <h1 className="section-title main__section-title">
            {id && "Редактируем запрос..." || "Создаём запрос..."}
          </h1>
        )}
        {status === "SUCCESS" && (
          <h1 className="section-title main__section-title">
            {id && "Запрос отредактирован. Переход на страницу запроса..." ||
              "Запрос создан. Переход на страницу запроса..."}
          </h1>
        )}
        {status !== "SUCCESS" && status !== "LOADING" && (
          <>
            <h1 className="section-title main__section-title">
              {id && `Редактировать запрос # ${id}` || "Создать запрос"}
            </h1>
            <section className="block-tabs">
              <div className="block-tabs__content">
                {!id && (
                  <div className="block-tabs__tabs">
                    <div className="block-tabs__items">
                      {role && role !== "employee" && (
                        <>
                          <a
                            onClick={(e) =>
                              changeTypeForm(e, "transportDocuments")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "transportDocuments" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Запрос транспортных документов
                          </a>
                          <a
                            onClick={(e) => changeTypeForm(e, "confirmation")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "confirmation" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Запрос с подтверждением
                          </a>
                          <a
                            onClick={(e) => changeTypeForm(e, "security")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "security" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Запрос документов по проверке безопасности
                          </a>
                        </>
                      )}
                      {role && role !== "partner" && (
                        <>
                          <a
                            onClick={(e) => changeTypeForm(e, "documents")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "documents" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Запрос документов
                          </a>
                          <a
                            onClick={(e) => changeTypeForm(e, "unloading")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "unloading" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Простой на выгрузке
                          </a>
                          <a
                            onClick={(e) => changeTypeForm(e, "other")}
                            className={cx(
                              "block-tabs__item",
                              type && type === "other" && "active",
                            )}
                            style={{ width: 'auto' }}
                            href="#"
                          >
                            Иное
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className="block-tabs__body">
                  {type && type === "transportDocuments" && (
                    <TransportDocumentsForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                  {type && type === "confirmation" && (
                    <ConfirmationForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                  {type && type === "security" && (
                    <SecurityForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                  {type && type === "documents" && (
                    <DocumentsForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                  {type && type === "unloading" && (
                    <UnloadingForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                  {type && type === "other" && (
                    <OtherForm
                      autocomplete={autocomplete}
                      listFlightNumber={listFlightNumber}
                      requestItem={dataRequest}
                      setSendData={setSendData}
                    />
                  )}
                </div>
              </div>
            </section>
          </>
        )}
      </div>
    </main>
  );
};
export default Add;
