export const DocumentsSelectProvide = [
    {
        value: 135,
        label: 'Упаковочный лист'
    },
    {
        value: 137,
        label: 'Накладная на внутреннее перемещение'
    },
    {
        value: 139,
        label: 'Транспортная накладная'
    },
    {
        value: 141,
        label: 'Товарная накладная'
    },
    {
        value: 143,
        label: 'Полный комплект документов'
    },
    {
        value: 145,
        label: 'Акт'
    },
    {
        value: 147,
        label: 'Другое'
    },
];

export const DocumentsTransferSelect = [
    {
        value: 123,
        label: 'В электронном виде'
    },
    {
        value: 125,
        label: 'С водителем (бум. копии)'
    }
];


export const DocumentsSelectRestore = [
    {
        label: 'Упаковочный лист',
        value: 127
    },
    {
        label: 'Накладная на внутреннее перемещение',
        value: 479
    },
    {
        label: 'Транспортная накладная',
        value: 129
    },
    {
        label: 'Товарная накладная',
        value: 131
        
    },
    {
        label: 'Другое',
        value: 133
    }
];




