import {
    messagesList,
    domainMain,
    isLocalhost, 
    paramsLocalhostApi, 
  } from "../../constants/config";
  
  export const getMessagesReguest = ({ id }) => {
  
    let params = {
        "IBLOCK_TYPE_ID": "lists",
        "IBLOCK_ID": messagesList,
      }

      if(id){
        params['FILTER'] = {
            'PROPERTY_747': id
        };
      }
    
      return new Promise((resolve, reject) => {
    
          fetch("https://"+domainMain+"/api/list.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
              method: 'POST',
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': isLocalhost && 'text/plain' || 'application/json'
              },
              body: JSON.stringify(params)
          })
              .then(response => response.json())
              .then(result => {
                  if(result == 'Refreshed' || result == 'Unauthorized' || result.error == "expired_token"){
                      // redirectAuth();
                  } else {
                      resolve(result);
                  }
              })
              .catch(error => {
                  console.log(error);
                  reject(error);
              });
    
      });
  };

  export const addMessageReguest = (props) => {
    let params = {
      "IBLOCK_TYPE_ID": "lists",
      "IBLOCK_ID": messagesList,
    };
  
    if (props) {
      params = {
        ...params,
        ...props,
      };
    }
  
    return new Promise((resolve, reject) => {
      fetch("https://" + domainMain + "/api/listadd.php" + ( isLocalhost ? '?' + paramsLocalhostApi : '' ), {
        method: "POST",
        headers: {
          "Accept": "application/json",
          'Content-Type': isLocalhost && 'text/plain' || 'application/json'
        },
        body: JSON.stringify(params),
      })
        .then((response) => response.json())
        .then((result) => {
          if (
            result == "Refreshed" || result == "Unauthorized" ||
            result.error == "expired_token"
          ) {
            // redirectAuth();
          } else {
            resolve(result);
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  };