import * as React from "react";
import { Link } from "react-router-dom";

const BreadCrumbs = ({ breadcrumb }) => {
  return <ul className="breadcrumb main__breadcrumb">
    {breadcrumb.length > 0 && breadcrumb.map((item, idx) => (
      <li key={idx} className="breadcrumb__item"><Link className="breadcrumb__link" to={item.link}>{item.name}</Link></li>
    ))}
  </ul>;
};
export default BreadCrumbs;
