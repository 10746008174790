import React from 'react';

const DetailRow = (props) => {

    const { title, value, openNotificationWithIcon } = props;

    return (
        <div className="filters-info__row">
            <span className="filters-info__name ellipsis" title={title} >{title}</span>
            <span className="filters-info__text">
                <span className='ellipsis' title={value} onClick={e => {
                    navigator.clipboard.writeText(value);
                    openNotificationWithIcon('info', '', 'Скопировано');
                }}>{value}</span>
            </span>
        </div>
    )
}

export default DetailRow;